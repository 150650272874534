import React, { useState } from "react";
import { Container, Typography, Box, Paper } from "@mui/material";
import ZipCodeTable from "../../components/dashboard/newauction/ZipCodeTable";
import { useAuth } from "../../hooks/useAuth";

// Sample ZIP codes for initial development
const SAMPLE_ZIPS = ["60601", "60602", "60603", "60604", "60605"];

const NewAuctionPage = () => {
  const { userData } = useAuth();
  const [selectedZips, setSelectedZips] = useState(new Set(SAMPLE_ZIPS));

  return (
    <Container maxWidth="lg">
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          ZIP Code Auctions
        </Typography>
        <Typography variant="body1" paragraph>
          Select ZIP codes on the map to place promotions and bids. You must
          have an active promotion for a ZIP code before you can place a bid in
          its auction.
        </Typography>
      </Paper>

      <Box sx={{ mt: 4 }}>
        <ZipCodeTable selectedZips={selectedZips} />
      </Box>
    </Container>
  );
};

export default NewAuctionPage;
