import React, { useState, useEffect } from "react";
import firebaseApp, { db } from "services/firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AccountVerifyEmail from "./account/AccountVerifyEmail";
import AccountResetPassword from "./account/AccountResetPassword";
import { useAuth } from "hooks/useAuth";

const Wrapper = ({ children }) => (
  <Box sx={{ textAlign: "center", maxWidth: "sm", m: "auto" }}>{children}</Box>
);

export default () => {
  const { user } = useAuth();
  const [requestType, setRequestType] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [verified, setVerified] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [verificationMessage, setVerificationMessage] = useState("");

  let navigate = useNavigate();

  const getRequestType = () => {
    const type = getParameter("mode");
    setRequestType(type);

    return type;
  };

  // Get parameters in the URL
  const getParameter = (parameter) => {
    const matches = new RegExp(`${parameter}=([^&#=]*)`).exec(
      window.location.search
    );
    if (matches) return matches[1];

    return undefined;
  };

  const handleVerifyEmail = () => {
    const code = getParameter("oobCode");

    if (!code) {
      console.error("Missing oobCode parameter");
      setLoading(false);
      setError(true);
      setErrorMessage("Missing verification code in the URL");
      return;
    }

    console.log("Starting email verification with code:", code);

    firebaseApp
      .auth()
      .applyActionCode(code)
      .then(() => {
        console.log("Email verification successful");

        // Store the current email before we potentially get logged out
        try {
          if (user) {
            localStorage.setItem("lastVerifiedEmail", user.email || "");

            // Use the authenticated user's UID from useAuth
            const uid = user.uid;

            // Call our HTTP function to update the user document
            const functionUrl = `https://us-central1-${firebaseApp.options.projectId}.cloudfunctions.net/completeEmailUpdate?uid=${uid}&success=true`;
            console.log("Calling function URL:", functionUrl);

            // Use fetch instead of redirect
            fetch(functionUrl)
              .then((response) => response.json())
              .then((data) => {
                console.log("Function response:", data);

                if (data.success) {
                  setVerificationMessage(
                    `Your email has been successfully updated to ${data.email}. For security reasons, you have been signed out. Please sign in again with your new email address.`
                  );
                } else {
                  if (data.code === "user_not_found") {
                    setVerificationMessage(
                      "We couldn't find your user account. Please try logging in again."
                    );
                  } else if (data.code === "no_pending_email") {
                    setVerificationMessage(
                      "Your email has been verified, but no pending email change was found. Please sign in again."
                    );
                  } else {
                    setVerificationMessage(
                      `Verification completed with status: ${
                        data.error || "Unknown error"
                      }. Please sign in again.`
                    );
                  }
                }

                setLoading(false);
              })
              .catch((error) => {
                console.error("Error calling function:", error);
                setVerificationMessage(
                  "Your email has been verified, but we encountered an error updating your account. Please sign in again with your new email address."
                );
                setLoading(false);
              });
          } else {
            console.error("No authenticated user found");
            setLoading(false);
            setError(true);
            setErrorMessage(
              "Authentication error. Please try signing in again."
            );
          }
        } catch (err) {
          console.log("Error storing email:", err);
          setLoading(false);
          setError(true);
          setErrorMessage("An error occurred during verification.");
        }

        setVerified(true);
      })
      .catch((error) => {
        console.error("Action code error:", error);
        setLoading(false);
        setError(true);
        setErrorMessage(`Verification failed: ${error.message}`);
      });
  };

  const handlePasswordReset = () => {
    const code = getParameter("oobCode");

    if (!code) {
      setLoading(false);
      setError(true);
      return;
    }

    firebaseApp
      .auth()
      .verifyPasswordResetCode(code)
      .then(() => {
        // Show the password reset form
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const handleRecoverEmail = () => {
    const code = getParameter("oobCode");

    if (!code) {
      setLoading(false);
      setError(true);
      return;
    }

    firebaseApp
      .auth()
      .checkActionCode(code)
      .then((info) => {
        return firebaseApp
          .auth()
          .applyActionCode(code)
          .then(async () => {
            try {
              setLoading(false);
              setVerified(true);

              // Get current user
              if (!user) {
                // If no user, redirect to login after showing message for 3 seconds
                setTimeout(() => {
                  navigate("/login", {
                    state: {
                      message:
                        "Please sign in again to complete your email recovery.",
                    },
                  });
                }, 3000);
                return;
              }

              try {
                // Force reload user data
                await user.reload();

                // Update the user document with the recovered email
                await db.collection("users").doc(user.uid).update({
                  email: user.email, // Use the recovered email from auth
                  pendingEmail: firebase.firestore.FieldValue.delete(), // Remove any pending email
                  emailChangeRequested: firebase.firestore.FieldValue.delete(), // Remove the timestamp
                });

                console.log(
                  "User document email updated to recovered email:",
                  user.email
                );

                // Force token refresh
                await user.getIdToken(true);

                // Small delay to allow token propagation and message to be read
                await new Promise((resolve) => setTimeout(resolve, 3000));

                // Navigate to dashboard
                navigate("/login", {
                  state: {
                    message:
                      "Your email has been recovered. Please sign in with your restored email address.",
                  },
                });
              } catch (tokenError) {
                console.error("Auth refresh error:", tokenError);
                // If token refresh fails, show message for 3 seconds then redirect
                setTimeout(() => {
                  navigate("/login", {
                    state: {
                      message:
                        "Your session has expired. Please sign in again to continue.",
                    },
                  });
                }, 3000);
              }
            } catch (error) {
              console.error("Recovery error:", error);
              setError(true);
            }
          });
      })
      .catch((error) => {
        console.error("Action code error:", error);
        setLoading(false);
        setError(true);
      });
  };

  // New handler for passwordless sign-in
  const handleSignIn = async () => {
    try {
      console.log("Starting handleSignIn");

      // Check if this is a sign-in link
      if (!firebaseApp.auth().isSignInWithEmailLink(window.location.href)) {
        console.log("Not a valid sign-in link");
        setLoading(false);
        setError(true);
        setErrorMessage("Invalid sign-in link. Please try signing up again.");
        return;
      }

      // Parse the continueUrl parameter to check if this is a registration
      const params = new URLSearchParams(window.location.search);
      const continueUrlParam = params.get("continueUrl");

      let role = null;

      if (continueUrlParam) {
        try {
          // Parse the continueUrl to extract role info
          const continueUrl = new URL(continueUrlParam);
          const continueParams = new URLSearchParams(continueUrl.search);
          role = continueParams.get("role");

          console.log("Parsed from continueUrl - role:", role);
        } catch (error) {
          console.error("Error parsing continueUrl:", error);
        }
      }

      // Get email from localStorage
      const email = localStorage.getItem("emailForSignIn");
      console.log("Email from localStorage:", email);

      // If no email is found, redirect to onboarding to collect it
      if (!email) {
        console.log("No email found in localStorage");
        console.log(
          "Redirecting to onboarding with role:",
          role || "undefined"
        );

        // Redirect to onboarding with a flag indicating email needs to be collected
        navigate(`/onboarding?needEmail=true${role ? `&role=${role}` : ""}`);
        return;
      }

      // Sign in the user
      console.log("Signing in with email link");
      const result = await firebaseApp
        .auth()
        .signInWithEmailLink(email, window.location.href);
      const user = result.user;
      console.log("User signed in:", user.uid);

      // Check if the user document already exists in Firestore
      const userDoc = await db.collection("users").doc(user.uid).get();
      const isNewUser = !userDoc.exists;

      console.log("Is new user:", isNewUser);

      // If this is a new user, redirect to onboarding
      if (isNewUser) {
        console.log("This is a new user - redirecting to onboarding");

        // Determine role from continueUrl or use a default
        const userRole = role || null;

        // Clear email from localStorage
        localStorage.removeItem("emailForSignIn");

        // Redirect to onboarding
        console.log(
          `Redirecting to: /onboarding${userRole ? `?role=${userRole}` : ""}`
        );

        // Add a small delay to ensure console logs are visible
        setTimeout(() => {
          navigate(`/onboarding${userRole ? `?role=${userRole}` : ""}`);
        }, 500);

        return;
      }

      // Clear email from localStorage
      localStorage.removeItem("emailForSignIn");

      // For regular sign-ins, continue with the existing flow
      setLoading(false);

      // Navigate directly to dashboard without delay
      navigate("/dashboard");
    } catch (error) {
      console.error("Sign-in error:", error);
      setLoading(false);
      setError(true);
      setErrorMessage(error.message || "An error occurred during sign-in.");
    }
  };

  useEffect(() => {
    const requestType = getRequestType();

    if (
      requestType === "verifyEmail" ||
      requestType === "verifyAndChangeEmail"
    ) {
      handleVerifyEmail();
    } else if (requestType === "resetPassword") {
      handlePasswordReset();
    } else if (requestType === "recoverEmail") {
      handleRecoverEmail();
    } else if (requestType === "signIn") {
      // Handle the new sign-in action
      handleSignIn();
    } else {
      navigate("/dashboard");
    }
  }, []);

  if (loading)
    return (
      <Wrapper>
        <CircularProgress sx={{ mt: 8 }} />
      </Wrapper>
    );

  if (error) {
    let displayErrorMessage;

    if (requestType === "verifyEmail" || requestType === "verifyAndChangeEmail")
      displayErrorMessage =
        "Your request to verify your email has expired or the link has already been used. Please try verifying your email again.";

    if (requestType === "resetPassword")
      displayErrorMessage =
        "Your request to reset your password has expired or the link has already been used. Please try again.";

    if (requestType === "recoverEmail")
      displayErrorMessage =
        "Your request to recover your email has expired or the link has already been used. Please contact support if you need assistance.";

    if (requestType === "signIn")
      displayErrorMessage =
        errorMessage ||
        "Your sign-in link has expired or is invalid. Please request a new sign-in link.";

    return (
      <Wrapper>
        <Typography variant="h4" sx={{ mt: { xs: 4, sm: 10 }, mb: 2 }}>
          Your link has expired
        </Typography>

        <Typography variant="body1" sx={{ mb: 2 }}>
          {displayErrorMessage}
        </Typography>

        <Link to="/login">
          <Button>Return to Login</Button>
        </Link>
      </Wrapper>
    );
  }

  if (
    requestType === "verifyEmail" ||
    requestType === "verifyAndChangeEmail" ||
    requestType === "recoverEmail"
  )
    return (
      <Wrapper>
        <AccountVerifyEmail
          verified={verified}
          isEmailRecovery={requestType === "recoverEmail"}
        />
        {verified && verificationMessage && (
          <Box mt={3}>
            <Typography sx={{ mb: 2 }}>{verificationMessage}</Typography>
            <Button
              variant="contained"
              component={Link}
              to="/login"
              sx={{ mt: 2 }}
            >
              Go to Login
            </Button>
          </Box>
        )}
      </Wrapper>
    );

  if (requestType === "resetPassword")
    return (
      <Wrapper>
        <AccountResetPassword getParameter={getParameter} />
      </Wrapper>
    );

  // For signIn, we now return null as we're showing the success message or redirecting
  return null;
};
