import React, { useState, useRef, useEffect } from "react";
import { Lock, HourglassEmpty } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import promotionService from "./services/promotionService";
import auctionService from "./services/auctionService";
import {
  handleServiceError,
  showErrorNotification,
} from "./utils/errorHandling";
import { useAuth } from "hooks/useAuth";
import { db } from "services/firebase";

const FIRST_TIME_KEY = "hasSeenPromotionMessage";

const DeactivationBlockedPopup = ({ open, onClose, buttonRef }) => (
  <Dialog
    open={open}
    onClose={onClose}
    sx={{
      "& .MuiDialog-paper": {
        width: "400px",
        maxWidth: "90vw",
      },
    }}
  >
    <DialogContent>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Your promotion is locked in! Whether the auction is a win or miss, your
        promotional campaign for this zip code will run next month to capture
        audience attention.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        variant="contained"
        color="primary"
        sx={{ minWidth: "80px" }}
      >
        OK
      </Button>
    </DialogActions>
  </Dialog>
);

const PromotionConfirmationPopup = ({
  open,
  onClose,
  onConfirm,
  buttonRef,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogContent>
      <Typography variant="body1" sx={{ mb: 2 }}>
        By promoting in this zip code, you agree to a $100 monthly fee.
        Promotion is required before placing any bids in the auction.
      </Typography>

      <Typography variant="body1" sx={{ mb: 2 }}>
        Your facility's group will be searchable on the map and your facility's
        branding will be added to the carousel on the map.
      </Typography>

      <Typography variant="body1" sx={{ mb: 2 }}>
        The promotion runs for one month. You will be billed on the second
        Wednesday of each month and will receive an invoice by email. Your
        promotion will become active once payment is received.
      </Typography>

      <Typography variant="body1" sx={{ mb: 2 }}>
        Would you like to proceed?
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} sx={{ minWidth: "80px" }}>
        No
      </Button>
      <Button onClick={onConfirm} variant="contained" sx={{ minWidth: "80px" }}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

const PromotionButton = ({ zipCode, onChange }) => {
  const { userData, zipPromotions } = useAuth();
  const [showInitialConfirm, setShowInitialConfirm] = useState(false);
  const [showDeactivationBlocked, setShowDeactivationBlocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPromoting, setIsPromoting] = useState(false);
  const [promotionStatus, setPromotionStatus] = useState(null); // 'pending', 'invoiced', 'active', 'expired'
  const [isLoadingStatus, setIsLoadingStatus] = useState(true);
  const [hasUserBids, setHasUserBids] = useState(false);
  const buttonRef = useRef(null);

  const promotionData = zipPromotions[zipCode];

  useEffect(() => {
    let isMounted = true;

    if (!userData?.uid) {
      setIsLoadingStatus(false);
      return;
    }

    const initializeComponent = async () => {
      try {
        // Set promotion state based on data from context
        const isActive = promotionData?.status === "active";
        const isPending =
          promotionData?.status === "pending" ||
          promotionData?.status === "invoiced";

        if (isMounted) {
          setIsPromoting(isActive || isPending);
          setPromotionStatus(promotionData?.status || null);
        }

        // Check if user has bids
        const hasBid = await hasBids();
        if (isMounted) {
          setHasUserBids(hasBid);
        }
      } catch (error) {
        console.error("Error initializing promotion button:", error);
      } finally {
        if (isMounted) {
          setIsLoadingStatus(false);
        }
      }
    };

    initializeComponent();

    return () => {
      isMounted = false;
    };
  }, [zipCode, userData, promotionData]);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      const promotionData = await promotionService.getPromoterStatus(
        zipCode,
        userData
      );
      const currentStatus = promotionData?.status;

      // If trying to deactivate and there are bids, show popup
      if (currentStatus && hasUserBids) {
        setShowDeactivationBlocked(true);
        return;
      }

      // If not currently promoting, show confirmation
      if (!currentStatus) {
        const hasSeenMessage = sessionStorage.getItem(FIRST_TIME_KEY);
        if (!hasSeenMessage) {
          setShowInitialConfirm(true);
        } else {
          await promotionService.setPromotionStatus(zipCode, true, userData);
          setIsPromoting(true);
          setPromotionStatus("pending");
          if (onChange) await onChange(true);
        }
      } else {
        // Only allow cancellation if status is pending (not invoiced or active)
        if (currentStatus === "pending") {
          await promotionService.setPromotionStatus(zipCode, false, userData);
          setIsPromoting(false);
          setPromotionStatus(null);
          if (onChange) await onChange(false);
        } else {
          showErrorNotification(
            `Cannot cancel a promotion that is already ${currentStatus}`
          );
        }
      }
    } catch (error) {
      showErrorNotification(
        `Error updating promotion status: ${error.message}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmPromotion = async () => {
    try {
      setIsLoading(true);
      setShowInitialConfirm(false);
      sessionStorage.setItem(FIRST_TIME_KEY, "true");
      await promotionService.setPromotionStatus(zipCode, true, userData);
      setIsPromoting(true);
      setPromotionStatus("pending");
      if (onChange) await onChange(true);
    } catch (error) {
      showErrorNotification(`Error confirming promotion: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const hasBids = async () => {
    try {
      const auction = await auctionService.initializeAuction(zipCode);

      // Check if there's any bid history and if the current user has bid
      const hasBid =
        auction?.bidHistory?.some((bid) => bid.bidderId === userData?.uid) ||
        false;
      console.log("hasBids check result:", {
        hasBid,
        bidHistory: auction?.bidHistory,
      });
      return hasBid;
    } catch (error) {
      console.error("Error checking bid status:", error);
      return false;
    }
  };

  // Get button text and icon based on promotion status
  const getButtonContent = () => {
    if (isLoading || isLoadingStatus) {
      return <CircularProgress size={20} color="inherit" />;
    }

    if (isPromoting) {
      if (promotionStatus === "active") {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            Active
            {hasUserBids && <Lock sx={{ fontSize: 14 }} />}
          </Box>
        );
      } else if (promotionStatus === "invoiced") {
        return (
          <Tooltip title="Waiting for payment">
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              Invoiced
              {hasUserBids && <Lock sx={{ fontSize: 14 }} />}
            </Box>
          </Tooltip>
        );
      } else if (promotionStatus === "pending") {
        return (
          <Tooltip title="You'll be billed on the second Wednesday of each month. Your promotion will run next month.">
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              Pending
              <HourglassEmpty sx={{ fontSize: 14 }} />
              {hasUserBids && <Lock sx={{ fontSize: 14 }} />}
            </Box>
          </Tooltip>
        );
      } else {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            Yes
            {hasUserBids && <Lock sx={{ fontSize: 14 }} />}
          </Box>
        );
      }
    }

    return "Add";
  };

  // Get button color based on promotion status
  const getButtonColor = () => {
    if (!isPromoting) return "primary";

    switch (promotionStatus) {
      case "active":
        return "success";
      case "invoiced":
        return "warning";
      case "pending":
        return "info";
      default:
        return "success";
    }
  };

  return (
    <>
      <Button
        ref={buttonRef}
        data-zipcode={zipCode}
        onClick={handleClick}
        disabled={
          isLoading || isLoadingStatus || promotionStatus === "invoiced"
        }
        variant="contained"
        color={getButtonColor()}
        sx={{
          minWidth: "80px",
          opacity: isLoading || isLoadingStatus ? 0.7 : 1,
        }}
      >
        {getButtonContent()}
      </Button>

      <PromotionConfirmationPopup
        open={showInitialConfirm}
        onClose={() => setShowInitialConfirm(false)}
        onConfirm={handleConfirmPromotion}
        buttonRef={buttonRef}
      />

      <DeactivationBlockedPopup
        open={showDeactivationBlocked}
        onClose={() => setShowDeactivationBlocked(false)}
        buttonRef={buttonRef}
      />
    </>
  );
};

export default PromotionButton;
