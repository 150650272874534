import React, { useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { useForm, Controller } from "react-hook-form";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { formatCurrency } from "../auction/utils/formatUtils";
import { LoadingButton } from "@mui/lab";

const BidButton = ({
  zipCode,
  disabled,
  hasBid = false,
  isWinning = false,
  currentBid = 100000, // Default 1000 dollars in cents
  auctionData = {},
  onBidPlaced,
}) => {
  const { userData, zipPromotions } = useAuth();
  const [showBidDialog, setShowBidDialog] = useState(false);
  const [showPromotionRequiredDialog, setShowPromotionRequiredDialog] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // Convert currentBid from cents to dollars for display
  const currentBidDollars = currentBid / 100;

  // Calculate minimum bid based on whether there are existing bids
  const hasExistingBids = auctionData.numberOfBids > 0;
  const minBidAmount = hasExistingBids
    ? currentBidDollars + 250 // $250 higher than current bid if there are bids
    : 1000; // $1000 minimum if no bids yet

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      bidAmount: minBidAmount,
    },
    mode: "onChange",
  });

  const handleBidSubmit = async (formData) => {
    if (!userData?.uid) return;

    setError("");
    setIsLoading(true);

    try {
      // Convert dollars to cents for the backend
      const amountInCents = Math.round(Number(formData.bidAmount) * 100);

      // Call the Cloud Function with the simple name
      const placeBidFunction = firebase.functions().httpsCallable("placeBid");
      const result = await placeBidFunction({
        zipCode: String(zipCode),
        bidAmount: amountInCents,
      });

      // Close the dialog and reset the form
      setShowBidDialog(false);
      reset();

      // Call the callback to update parent component
      if (onBidPlaced && result.data.success) {
        onBidPlaced(amountInCents);
      }
    } catch (error) {
      console.error("Error placing bid:", error);
      setError(error.message || "Failed to place bid. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDialogClose = () => {
    if (!isLoading) {
      setShowBidDialog(false);
      reset();
      setError("");
    }
  };

  const handleButtonClick = () => {
    // Check if user has a promotion
    const hasPromotion =
      zipPromotions &&
      (zipPromotions[zipCode]?.status === "active" ||
        zipPromotions[zipCode]?.status === "pending");

    if (hasPromotion) {
      setShowBidDialog(true);
    } else {
      setShowPromotionRequiredDialog(true);
    }
  };

  // If user has already placed a bid, show winning status
  if (hasBid) {
    return (
      <Button
        variant="contained"
        color={isWinning ? "success" : "error"}
        size="large"
      >
        {isWinning ? "Winning Bid" : "Not Winning"}
      </Button>
    );
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={handleButtonClick}
        disabled={disabled}
      >
        Place Bid
      </Button>

      {/* Promotion Required Dialog */}
      <Dialog
        open={showPromotionRequiredDialog}
        onClose={() => setShowPromotionRequiredDialog(false)}
      >
        <DialogTitle>Promotion Required</DialogTitle>
        <DialogContent>
          <DialogContentText variant="body2" sx={{ pb: 3 }}>
            You need to place a promotion for ZIP code {zipCode} before you can
            bid. Promotions cost $100 per month and allow you to participate in
            auctions.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPromotionRequiredDialog(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setShowPromotionRequiredDialog(false);
              // You could add logic here to automatically open the promotion dialog
              // or navigate to the promotion section
            }}
          >
            Place Promotion
          </Button>
        </DialogActions>
      </Dialog>

      {/* Bid Dialog */}
      <Dialog open={showBidDialog} onClose={handleDialogClose}>
        <form onSubmit={handleSubmit(handleBidSubmit)}>
          <DialogTitle>Place Bid for ZIP Code {zipCode}</DialogTitle>
          <DialogContent>
            <DialogContentText variant="body2" sx={{ pb: 3 }}>
              Enter your bid amount. The minimum bid is{" "}
              {formatCurrency(minBidAmount)}.
              {currentBid > 0 &&
                hasExistingBids &&
                ` The current highest bid is ${formatCurrency(
                  currentBidDollars
                )}.`}
            </DialogContentText>

            <Controller
              name="bidAmount"
              control={control}
              rules={{
                required: "Bid amount is required",
                min: {
                  value: minBidAmount,
                  message: `Your bid must be at least ${formatCurrency(
                    minBidAmount
                  )}`,
                },
                validate: (value) =>
                  !isNaN(Number(value)) || "Please enter a valid number",
              }}
              render={({ field }) => (
                <TextField
                  autoFocus
                  margin="dense"
                  label="Bid Amount"
                  type="number"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  disabled={isLoading}
                  error={!!errors.bidAmount}
                  helperText={errors.bidAmount?.message}
                  InputProps={{
                    startAdornment: <Typography variant="body1">$</Typography>,
                  }}
                  {...field}
                />
              )}
            />

            {error && (
              <FormHelperText error sx={{ mt: 1 }}>
                {error}
              </FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} disabled={isLoading}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              loading={isLoading}
              disabled={isLoading || Object.keys(errors).length > 0}
              variant="contained"
              color="primary"
            >
              Place Bid
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default BidButton;
