import styles from './ColorGradient.css';
import { Alert, Box, Button, Paper, Skeleton, Typography } from "@mui/material";


import React from "react";

function generateColorFromPosition(x, startColor, endColor) {
    const startRGB = {
        r: parseInt(startColor.slice(1, 3), 16),
        g: parseInt(startColor.slice(3, 5), 16),
        b: parseInt(startColor.slice(5, 7), 16)
    };

    const endRGB = {
        r: parseInt(endColor.slice(1, 3), 16),
        g: parseInt(endColor.slice(3, 5), 16),
        b: parseInt(endColor.slice(5, 7), 16)
    };

    const r = Math.round(startRGB.r + (endRGB.r - startRGB.r) * x);
    const g = Math.round(startRGB.g + (endRGB.g - startRGB.g) * x);
    const b = Math.round(startRGB.b + (endRGB.b - startRGB.b) * x);

    return '#' +
        r.toString(16).padStart(2, '0') +
        g.toString(16).padStart(2, '0') +
        b.toString(16).padStart(2, '0');
}

function ColorGradient({
    startColor = '#841B45',
    endColor = '#45841B',
    width = 600,
    height = 200,
    onChange,
    style = {}
}) {
    const [selectedColor, setSelectedColor] = React.useState(null);
    const svgRef = React.useRef(null);
    const gradientId = `gradient-${React.useId()}`;

    const numWidth = typeof width === 'string' ? parseInt(width) : width;
    const numHeight = typeof height === 'string' ? parseInt(height) : height;

    const gradientHeight = numHeight * 0.4;
    const titleFontSize = numHeight * 0.2;
    const labelFontSize = numHeight * 0.2;
    const legendFontSize = numHeight * 0.14;
    const spacing = numHeight * 0.01;

    return (
        <div style={{ width: numWidth, height: numHeight, position: "relative", ...style }}>
            <svg
                ref={svgRef}
                width="100%"
                height="100%"
                viewBox={`0 0 ${numWidth} ${numHeight}`}
            >
                <defs>
                    <linearGradient id={gradientId}>
                        <stop offset="0%" stopColor={startColor} />
                        <stop offset="100%" stopColor={endColor} />
                    </linearGradient>
                </defs>

                <foreignObject x="0" y="0" width="100%" height={titleFontSize + spacing}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: titleFontSize,
                            lineHeight: `${titleFontSize}px`
                        }}
                    >
                        HLTHDSK SCORE
                    </Typography>
                </foreignObject>

                <foreignObject x="0" y={titleFontSize + spacing * 3} width="100%" height={labelFontSize}>
                    <Typography
                        variant="body2"
                        component="div"
                        style={{
                            fontSize: labelFontSize,
                            lineHeight: `${labelFontSize}px`
                        }}
                    >
                        Worst
                    </Typography>
                </foreignObject>

                <foreignObject x="90%" y={titleFontSize + spacing * 3} width="10%" height={labelFontSize}>
                    <Typography
                        variant="body2"
                        component="div"
                        style={{
                            fontSize: labelFontSize,
                            lineHeight: `${labelFontSize}px`,
                            textAlign: "right"
                        }}
                    >
                        Best
                    </Typography>
                </foreignObject>

                <rect
                    x="0"
                    y={titleFontSize + spacing * 4 + labelFontSize}
                    width="100%"
                    height={gradientHeight}
                    fill={`url(#${gradientId})`}
                    rx="4"
                    ry="4"
                />

                <foreignObject x="0" y={titleFontSize + spacing * 6 + gradientHeight + 20} width="100%" height={legendFontSize * 2}>
                    <Typography
                        variant="caption"
                        component="div"
                        style={{
                            fontSize: legendFontSize,
                            lineHeight: `${legendFontSize}px`,
                            textAlign: "center",
                            color: "#666"
                        }}
                    >
                        HLTHDSK SCORE combines Medicare quality data, when available, with
                        real-time Virtual Queue participation to reflect excellence in acute care patient accessibility.
                    </Typography>
                </foreignObject>
            </svg>

        </div>
    );
}

export default ColorGradient;