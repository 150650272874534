import { useContext, useState, useEffect } from "react";
import { KijabeChatContext } from "context/KijabeChat";
import { db } from "services/firebase";
import firebase from "firebase/compat/app";

export const useKijabeMessageHandler = ({
  setIsTyping,
  setMessageCount,
  selectedAssistant,
}) => {
  const {
    setMessages,
    threadReset,
    language, // Get language from context
  } = useContext(KijabeChatContext);
  const [currentThreadDoc, setCurrentThreadDoc] = useState(null);
  const [storedAssistantId, setStoredAssistantId] = useState(null);

  // Reset the thread document when threadReset changes
  useEffect(() => {
    if (threadReset) {
      setCurrentThreadDoc(null);
      setStoredAssistantId(null);
    }
  }, [threadReset]);

  const handleSendRequest = async (message, directAssistantId = null) => {
    const cleanMessage = message.replace(
      /<[^>]*>|&(nbsp|amp|quot|lt|gt);/g,
      ""
    );

    // Use the directly passed assistant ID if available, otherwise use the stored one or the state
    const assistantId = !storedAssistantId
      ? directAssistantId || selectedAssistant
      : storedAssistantId;

    // Store the assistant ID for future messages in this conversation
    if (assistantId && !storedAssistantId) {
      setStoredAssistantId(assistantId);
    }

    console.log("Using assistant ID in handleSendRequest:", assistantId);
    console.log("Message content:", cleanMessage);
    console.log("Language:", language);

    const userMessage = {
      message: cleanMessage,
      direction: "outgoing",
      sender: "user",
      created: Date.now(),
    };

    // Update local messages immediately to show user message
    setMessages((prev) => [...prev, userMessage]);
    setIsTyping(true);

    try {
      let docRef;
      if (!currentThreadDoc) {
        // Create a new thread document for this chat session
        docRef = await db.collection("kijabe_chat").add({
          messages: [userMessage],
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          isKijabe: true,
          language: language, // Include language at the thread level
          ...(assistantId && { assistant: assistantId }),
        });
        setCurrentThreadDoc(docRef);
      } else {
        // Use existing thread document
        docRef = currentThreadDoc;
        await docRef.update({
          messages: firebase.firestore.FieldValue.arrayUnion(userMessage),
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          language: language, // Update language at the thread level
          ...(assistantId && !storedAssistantId && { assistant: assistantId }),
        });
      }

      // Listen for the response
      const unsubscribe = docRef.onSnapshot((doc) => {
        const data = doc.data();
        const messages = data?.messages || [];
        const lastMessage = messages[messages.length - 1];

        if (
          lastMessage?.sender === "HLTHDSK" &&
          lastMessage?.direction === "incoming"
        ) {
          // Update messages with the full conversation history
          setMessages(messages);
          setIsTyping(false);
          setMessageCount((prev) => prev + 1);
          unsubscribe();
        }
      });

      // We still need a timeout to clean up the listener
      setTimeout(() => {
        unsubscribe();
        setIsTyping(false);
      }, 300000); // 5 minutes - just to ensure we don't leave listeners hanging
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prev) => [
        ...prev,
        {
          message: "Sorry, I encountered an error. Please try again later.",
          direction: "incoming",
          sender: "HLTHDSK",
          isError: true,
        },
      ]);
      setIsTyping(false);
    }
  };

  return { handleSendRequest };
};
