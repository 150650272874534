import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
  MobileStepper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import ForumIcon from "@mui/icons-material/Forum";
import PsychologyIcon from "@mui/icons-material/Psychology";
import SchoolIcon from "@mui/icons-material/School";
import { db } from "services/firebase";

const ProfessionalOnboardingDialog = ({ open, onClose, userId }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleClose = () => {
    // Close the dialog immediately
    onClose();

    // Save preference in the background if needed
    if (dontShowAgain && userId) {
      db.collection("users")
        .doc(userId)
        .update({
          hideOnboardingDialog: true,
        })
        .catch((error) => {
          console.error("Error saving onboarding preference:", error);
        });
    }
  };

  const handleDisclaimerAccept = () => {
    setDisclaimerAccepted(true);
    handleNext();
  };

  const steps = [
    {
      label: "Important Disclaimer",
      content: (
        <Box sx={{ textAlign: "center", py: 2 }}>
          <MedicalServicesIcon color="primary" sx={{ fontSize: 64, mb: 3 }} />
          <Typography variant="h5" sx={{ mb: 3 }}>
            Important Notice for Medical Professionals
          </Typography>
          <Typography variant="body1" paragraph sx={{ textAlign: "center" }}>
            The materials on this site are provided for general medical
            education purposes only and should not be applied rigidly or
            universally in any clinical scenario. Decisions regarding patient
            care must remain the professional responsibility of the individual
            medical practitioner, who must use clinical judgment based on the
            unique circumstances of each case.
          </Typography>
          <Typography variant="body1" paragraph sx={{ textAlign: "center" }}>
            HLTHDSK Pro is intended strictly for use by medical professionals.
            Neither HLTHDSK nor its representatives assume legal, financial, or
            medical liability for decisions made using, or based on, the
            information provided through its platform.
          </Typography>
          <Typography variant="body1" paragraph sx={{ textAlign: "center" }}>
            By accessing and using this site, you agree to these conditions as
            well as the full HLTHDSK Terms of Use.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDisclaimerAccept}
            sx={{ mt: 2, px: 4 }}
          >
            I Understand
          </Button>
        </Box>
      ),
      hideNavigation: true,
    },
    {
      label: "Welcome to HLTHDSK Pro",
      content: (
        <Box sx={{ textAlign: "center", py: 2 }}>
          <ForumIcon color="primary" sx={{ fontSize: 64, mb: 3 }} />
          <Typography variant="h5" sx={{ mb: 3 }}>
            Real-Time Clinical Support
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to HLTHDSK Pro. We're excited to enhance your clinical
            practice.
          </Typography>
          <Typography variant="body1" paragraph>
            HLTHDSK Medical SuperIntelligence™ requires just a brief clinical
            scenario input—taking seconds to complete—to deliver precise,
            relevant insights drawn from a physician-curated knowledge base.
          </Typography>
          <Typography variant="body1" paragraph>
            This fast, focused approach helps you access what's needed
            immediately, so you can stay focused on patient care.
          </Typography>
          <Typography variant="body1" paragraph>
            To get started, click one of the buttons to start a case review.
          </Typography>
        </Box>
      ),
    },
    {
      label: "Clinical Decision Support",
      content: (
        <Box sx={{ textAlign: "center", py: 2 }}>
          <PsychologyIcon color="primary" sx={{ fontSize: 64, mb: 3 }} />
          <Typography variant="h5" sx={{ mb: 3 }}>
            Enhance Diagnostic Accuracy
          </Typography>
          <Typography variant="body1" paragraph>
            HLTHDSK enhances clinical reasoning and reduces cognitive load,
            allowing you to deliver more accurate diagnoses with greater
            confidence.
          </Typography>
          <Typography variant="body1" paragraph>
            Our real-time insights help achieve the 22% improvement potential in
            diagnostic accuracy, enabling you to focus on what matters
            most—quality patient care.
          </Typography>
        </Box>
      ),
    },
    {
      label: "Expert Knowledge Base",
      content: (
        <Box sx={{ textAlign: "center", py: 2 }}>
          <SchoolIcon color="primary" sx={{ fontSize: 64, mb: 3 }} />
          <Typography variant="h5" sx={{ mb: 3 }}>
            Physician-Curated Knowledge
          </Typography>
          <Typography variant="body1" paragraph>
            HLTHDSK Medical SuperIntelligence™ is like having a consultant at
            your side, instantly accessible. By analyzing the information you
            provide, HLTHDSK suggests possible diagnoses and relevant care
            considerations with speed and precision.
          </Typography>
          <Typography variant="body1" paragraph>
            It's an intelligent, easy-to-use tool that enhances diagnostic
            accuracy, minimizes inefficiencies, and empowers your
            workflow—elevating your practice with streamlined, reliable support.
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={activeStep > 0 ? handleClose : null} // Prevent closing on first step (disclaimer)
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: { borderRadius: 2 },
      }}
    >
      {activeStep > 0 && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}

      <DialogContent sx={{ p: 4, pb: 1 }}>
        {steps[activeStep].content}

        {!steps[activeStep].hideNavigation && (
          <MobileStepper
            variant="dots"
            steps={steps.length - 1} // Don't count disclaimer as a step in the dots
            position="static"
            activeStep={activeStep - 1} // Adjust for disclaimer step
            sx={{
              background: "transparent",
              justifyContent: "center",
              mt: 2,
            }}
            nextButton={null}
            backButton={null}
          />
        )}
      </DialogContent>

      {!steps[activeStep].hideNavigation && (
        <DialogActions sx={{ justifyContent: "space-between", p: 3 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={dontShowAgain}
                onChange={(e) => setDontShowAgain(e.target.checked)}
              />
            }
            label="Don't show this again"
          />
          <Box>
            {activeStep > 1 && (
              <Button onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
            )}

            {activeStep === steps.length - 1 ? (
              <Button variant="contained" onClick={handleClose}>
                Get Started
              </Button>
            ) : (
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            )}
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ProfessionalOnboardingDialog;
