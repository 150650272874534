import React, { useEffect, useRef, useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MessageList,
  Message,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ChatWrapper from "components/chatbot/Chat/ChatWrapper";
import UnauthenticatedMessageInput from "components/chatbot/Chat/UnauthenticatedMessageInput";
import Disclaimer from "components/chatbot/Chat/Disclaimer";
import { db } from "services/firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";

const UnauthenticatedChatbot = ({
  visible = true,
  messages,
  setMessages,
  expanded,
  rateLimitHook,
}) => {
  const navigate = useNavigate();
  const chatBoxRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [currentThreadDoc, setCurrentThreadDoc] = useState(null);
  const [showRateLimitModal, setShowRateLimitModal] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  // Auto-scroll effect
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, isTyping]);

  // Hide disclaimer when messages exist
  useEffect(() => {
    if (messages?.length > 0) {
      setShowDisclaimer(false);
    }
  }, [messages]);

  // Add this useEffect to handle URL parameters
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const initialMessage = params.get("message");

    if (initialMessage && !messages?.length) {
      handleSendRequest(initialMessage);
    }
  }, []); // Empty dependency array since we only want this to run once on mount

  const handleSendRequest = async (message) => {
    if (rateLimitHook.rateLimited || !message.trim()) return;

    // Set typing indicator immediately
    setIsTyping(true);
    setInputValue("");

    try {
      const userMessage = {
        message,
        direction: "outgoing",
        sender: "user",
        created: Date.now(),
      };

      // Update local messages immediately to show user message
      setMessages((prev) => [...(prev || []), userMessage]);
      setShowDisclaimer(false);

      let docRef;
      if (!currentThreadDoc) {
        docRef = await db.collection("unauthenticated_chat").add({
          messages: [userMessage],
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
        setCurrentThreadDoc(docRef);
      } else {
        docRef = currentThreadDoc;
        await docRef.update({
          messages: firebase.firestore.FieldValue.arrayUnion(userMessage),
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }

      // Listen for the response
      const unsubscribe = docRef.onSnapshot((doc) => {
        const data = doc.data();
        const messages = data?.messages || [];
        const lastMessage = messages[messages.length - 1];

        if (lastMessage?.sender === "HLTHDSK") {
          if (rateLimitHook.messageCount + 1 >= rateLimitHook.limit) {
            rateLimitHook.incrementCount();
            setShowRateLimitModal(true);
          } else {
            setMessages(messages);
            rateLimitHook.incrementCount();
          }
          setIsTyping(false);
          unsubscribe();
        }
      });

      // Cleanup subscription after 30 seconds
      setTimeout(() => {
        unsubscribe();
        setIsTyping(false);
      }, 30000);
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prev) => [
        ...(prev || []),
        {
          message: "Sorry, I encountered an error. Please try again later.",
          direction: "incoming",
          sender: "HLTHDSK",
          isError: true,
        },
      ]);
      setIsTyping(false);
    }
  };

  if (!messages || messages.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "970px", mb: 2 }}>
          <Disclaimer />
        </Box>

        <Box
          sx={{
            display: visible ? "flex" : "none",
            flexDirection: "column",
            flex: 1,
            overflow: "hidden",
            position: "relative",
            width: "100%",
            maxWidth: "970px",
          }}
          ref={chatBoxRef}
        >
          <ChatWrapper expanded={expanded}>
            <MessageList>
              <Message
                model={{
                  direction: "incoming",
                  position: "normal",
                }}
              >
                <Message.CustomContent>
                  Hello! I'm HLTHDSK Medical SuperIntelligence. How can I help
                  you today?
                </Message.CustomContent>
              </Message>
            </MessageList>

            <div
              as="MessageInput"
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                margin: "0 auto",
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "#fff",
              }}
            >
              <UnauthenticatedMessageInput
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleSendRequest={handleSendRequest}
                rateLimited={rateLimitHook.rateLimited}
                limit={rateLimitHook.limit}
              />
            </div>
          </ChatWrapper>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          alignItems: "center",
        }}
      >
        {showDisclaimer && (
          <Box sx={{ width: "100%", maxWidth: "970px", mb: 2 }}>
            <Disclaimer />
          </Box>
        )}

        <Box
          sx={{
            display: visible ? "flex" : "none",
            flexDirection: "column",
            flex: 1,
            overflow: "hidden",
            position: "relative",
            width: "100%",
            maxWidth: "970px",
          }}
          ref={chatBoxRef}
        >
          <ChatWrapper expanded={expanded}>
            <MessageList
              typingIndicator={
                isTyping ? (
                  <TypingIndicator content="HLTHDSK is typing..." />
                ) : null
              }
            >
              {messages?.map((message, i) => (
                <Message
                  key={`message_${i}`}
                  model={{
                    direction: message.direction,
                    position: "normal",
                  }}
                >
                  <Message.CustomContent>
                    {message.message}
                  </Message.CustomContent>
                </Message>
              ))}
            </MessageList>

            <div
              as="MessageInput"
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                margin: "0 auto",
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "#fff",
              }}
            >
              <UnauthenticatedMessageInput
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleSendRequest={handleSendRequest}
                rateLimited={rateLimitHook.rateLimited}
                limit={rateLimitHook.limit}
              />
            </div>
          </ChatWrapper>
        </Box>
      </Box>
      <Dialog
        open={showRateLimitModal}
        onClose={() => setShowRateLimitModal(false)}
        aria-labelledby="rate-limit-dialog-title"
      >
        <DialogTitle id="rate-limit-dialog-title">
          Message Limit Reached
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You've reached your message limit. Sign up for an account to
            continue using Medical SuperIntelligence.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowRateLimitModal(false)}>Close</Button>
          <Button
            onClick={() => navigate("/register")}
            variant="contained"
            sx={{ backgroundColor: "#117aca" }}
          >
            Sign Up Now
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UnauthenticatedChatbot;
