import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { formatCurrency } from "../auction/utils/formatUtils";

// Component for displaying current bid
export const CurrentBid = ({ auctionData = {} }) => {
  // Check if there are any bids
  const hasBids = auctionData.numberOfBids > 0;

  // Display current bid if there are bids, otherwise show "No bids yet"
  const displayAmount = hasBids
    ? auctionData.currentBid
    : auctionData.startingPrice || 0;

  const amountInDollars = displayAmount / 100;

  return (
    <Box>
      <Typography variant="h6" fontWeight="bold">
        {formatCurrency(amountInDollars)}
      </Typography>
      {hasBids ? (
        <Typography variant="body2" color="text.secondary">
          {auctionData.numberOfBids}{" "}
          {auctionData.numberOfBids === 1 ? "bid" : "bids"} placed
        </Typography>
      ) : (
        <Typography variant="body2" color="text.secondary">
          Starting price
        </Typography>
      )}
    </Box>
  );
};

// Component for displaying time remaining
export const TimeRemaining = ({ auctionData = {} }) => {
  const [timeRemaining, setTimeRemaining] = useState("Loading...");

  // Update time remaining every second
  useEffect(() => {
    if (!auctionData?.endTime) {
      setTimeRemaining("No end time");
      return;
    }

    const updateTimeRemaining = () => {
      const now = new Date();
      const end =
        auctionData.endTime instanceof Date
          ? auctionData.endTime
          : auctionData.endTime.toDate
          ? auctionData.endTime.toDate()
          : new Date(auctionData.endTime);

      const diff = end - now;

      if (diff <= 0) {
        setTimeRemaining("Ended");
        return;
      }

      // Format time remaining
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

      if (days > 0) {
        setTimeRemaining(`${days}d ${hours}h`);
      } else if (hours > 0) {
        setTimeRemaining(`${hours}h ${minutes}m`);
      } else {
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        setTimeRemaining(`${minutes}m ${seconds}s`);
      }
    };

    // Initial update
    updateTimeRemaining();

    // Set up interval
    const interval = setInterval(updateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, [auctionData]);

  return (
    <Box>
      <Typography variant="body1" fontWeight="medium">
        {timeRemaining}
      </Typography>
    </Box>
  );
};

// Main component that combines both (for backward compatibility)
const AuctionStatus = ({ zipCode, auctionData = {} }) => {
  if (!auctionData) {
    return <Typography>No active auction</Typography>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <CurrentBid auctionData={auctionData} />
      <TimeRemaining auctionData={auctionData} />
    </Box>
  );
};

export default AuctionStatus;
