import React from "react";
import { Alert } from "@mui/material";

const FacilityAlerts = ({ data }) => {
  if (data.pending === true) {
    return (
      <Alert severity="info" sx={{ mt: 4, mb: "-10px" }}>
        Your facility is under manual review and will not appear on the map
        until it has been approved. This usually takes around 1-2 days.
      </Alert>
    );
  }

  if (data.pending === "rejected") {
    return (
      <Alert severity="error" sx={{ mt: 4, mb: "-10px" }}>
        Your facility has been rejected and will not appear on the map. If you
        believe this decision has been made in error, please contact us at{" "}
        <a href="mailto:support@hlthdsk.com">support@hlthdsk.com</a>.
      </Alert>
    );
  }

  return null;
};

export default FacilityAlerts;
