// src/components/CountdownCircle.js
import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { formatDistanceToNow, differenceInMilliseconds } from "date-fns";

const CountdownCircle = ({ timeLeft }) => {
  const getColor = (timeLeft) => {
    if (timeLeft <= 10000) return "error.main"; // Red for last 10 seconds
    if (timeLeft <= 30000) return "warning.main"; // Orange for last 30 seconds
    return "primary.main"; // Blue for normal state
  };

  const formatTime = (ms) => {
    // If more than 24 hours left, show relative time
    if (ms > 24 * 60 * 60 * 1000) {
      const futureDate = new Date(Date.now() + ms);
      return formatDistanceToNow(futureDate, { addSuffix: true });
    }

    // Otherwise show detailed countdown
    const totalSeconds = Math.ceil(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  // Calculate progress percentage (60 seconds = 100%)
  const SIXTY_SECONDS = 60 * 1000;
  const progress =
    timeLeft <= SIXTY_SECONDS
      ? ((SIXTY_SECONDS - timeLeft) / SIXTY_SECONDS) * 100
      : 100;

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "40px",
      }}
    >
      {/* Background circle */}
      <CircularProgress
        variant="determinate"
        value={100} // Changed from progress to 100
        size={40}
        thickness={4}
        sx={{
          color: (theme) => theme.palette.grey[200],
          position: "absolute",
        }}
      />
      {/* Foreground circle */}
      <CircularProgress
        variant="determinate"
        value={progress}
        size={40}
        thickness={4}
        sx={{
          color: (theme) => theme.palette[getColor(timeLeft)],
          animationDuration: "550ms",
          position: "absolute",
          transform: "rotate(-90deg)", // Added to make progress go clockwise
        }}
      />
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{
            fontSize: "0.75rem",
            fontWeight: "bold",
            color: (theme) => theme.palette[getColor(timeLeft)],
            lineHeight: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {formatTime(timeLeft)}
        </Typography>
      </Box>
    </Box>
  );
};

export default CountdownCircle;
