import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import AdminTimeForm from "components/dashboard/AdminTimeForm";
import VirtualRegistrationSettings from "components/vaccine/queue/VirtualRegistrationSettings";
import TextSequence from "components/vaccine/TextSequence";
import WaitingRoom from "components/queue/WaitingRoom";
import Scheduler from "components/dashboard/Scheduler";
import Account from "components/dashboard/Account";
import AuctionPage from "pages/dashboard/AuctionPage";
import Advertising from "components/dashboard/advertising/Advertising";
import UpgradePage from "pages/dashboard/UpgradePage";
import Approval from "components/dashboard/admin/Approval";
import { useFacility } from "hooks/useFacility";
import { useAuth } from "hooks/useAuth";
import QueueDisplay from "components/queue/splitflap/QueueDisplay";
import DashboardLayout from "components/dashboard/DashboardLayout";
import NewAuctionPage from "pages/dashboard/NewAuctionPage";
const DashboardRoutes = () => {
  const { data, setData } = useFacility();
  const { user, subscription } = useAuth();
  const location = useLocation();
  const isBoard = location.pathname === "/dashboard/queue/board";

  const routes = (
    <Routes>
      <Route exact path="/" element={<AdminTimeForm data={data} />} />
      <Route
        path="/queue/board"
        element={<QueueDisplay queue={data.queue} />}
      />
      <Route
        path="/sequence"
        element={
          <div className="inner">
            <VirtualRegistrationSettings data={data} setData={setData} />
            <TextSequence data={data} setData={setData} />
          </div>
        }
      />
      <Route
        path="/queue"
        element={<WaitingRoom data={data} setData={setData} />}
      />
      <Route
        path="/schedule"
        element={<Scheduler data={data} setData={setData} />}
      />
      <Route
        path="/account"
        element={<Account uid={user.uid} subscription={subscription} />}
      />
      <Route path="/upgrade" element={<UpgradePage />} />
      <Route path="/advertising/*" element={<AuctionPage />} />
      <Route path="/admin/approval" element={<Approval />} />
      <Route path="/newauction" element={<NewAuctionPage />} />
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );

  return isBoard ? routes : <DashboardLayout>{routes}</DashboardLayout>;
};

export default DashboardRoutes;
