import React from "react";
import DocumentViewer from "./DocumentViewer";

const TermsOfUse = () => (
  <DocumentViewer
    pdf={
      "https://img1.wsimg.com/blobby/go/9fc169e5-1029-481b-93db-dc019717f136/hlthdsk-terms-of-use.pdf"
    }
  />
);

export default TermsOfUse;
