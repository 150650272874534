import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  Button,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import CountdownCircle from "./CountdownCircle";
import { ZIP_CODES } from "./data/zipCodes";
import AuctionStatus from "./AuctionStatus";
import auctionService from "./services/auctionService";
import PromotionButton from "./PromotionButton";
import promotionService from "./services/promotionService";
import { useAuth } from "hooks/useAuth";
import ExpansionPromptPopup from "./ExpansionPromptPopup";
import AuctionReportModal from "./AuctionReportModal";
import {
  handleServiceError,
  showErrorNotification,
} from "./utils/errorHandling";
import { formatDistanceToNow } from "date-fns";
import { db } from "services/firebase";
import { PaymentRounded } from "@mui/icons-material";
import SubscriptionsTable from "./tables/SubscriptionsTable";
import AuctionsTable from "./tables/AuctionsTable";

const SelectedZipsTable = ({ selectedZips }) => {
  const { userData, zipSubscriptions } = useAuth();
  const currentUserId = userData?.uid;
  const [totalBid, setTotalBid] = useState(0);
  const [totalPromotions, setTotalPromotions] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [error, setError] = useState(null);
  const [auctionStatus, setAuctionStatus] = useState([]);
  const [showExpansionPrompt, setShowExpansionPrompt] = useState(false);
  const [allAuctionsEnded, setAllAuctionsEnded] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const [auctionTimes, setAuctionTimes] = useState({});
  const [currentBids, setCurrentBids] = useState({});
  const invoiceButtonRef = useRef(null);
  const [promotionStatuses, setPromotionStatuses] = useState({});
  const [pendingBids, setPendingBids] = useState(0);
  const [showBidPopup, setShowBidPopup] = useState(false);
  const [reportLoading, setReportLoading] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pendingAuctions, setPendingAuctions] = useState([]);
  const [pendingAuctionsTotal, setPendingAuctionsTotal] = useState(0);
  const [pendingInvoices, setPendingInvoices] = useState([]);
  const [activePromotions, setActivePromotions] = useState([]);

  // Debug useEffect to track when component mounts and receives props
  useEffect(() => {
    console.log("SelectedZipsTable mounted with props:", {
      selectedZips: Array.from(selectedZips),
      selectedZipsSize: selectedZips.size,
      zipSubscriptionsAvailable: !!zipSubscriptions,
      zipSubscriptionsKeys: Object.keys(zipSubscriptions || {}),
    });

    // Force a re-render after component mounts
    setTimeout(() => {
      setForceUpdate((prev) => prev + 1);
    }, 500);
  }, []);

  // Add more detailed logging to the existing useEffect
  useEffect(() => {
    console.log("zipSubscriptions or selectedZips changed:", {
      zipSubscriptions,
      selectedZips: Array.from(selectedZips),
      isLoading,
    });
    setIsLoading(false);
  }, [zipSubscriptions, selectedZips]);

  // Move totals calculation to component level
  const totals = React.useMemo(() => {
    console.log("Calculating totals with data:", {
      zipSubscriptions,
      selectedZips,
      isLoading,
    });

    const result = {
      activeAmount: 0,
      pendingAmount: 0,
      activeCount: 0,
      pendingCount: 0,
    };

    Object.entries(zipSubscriptions || {}).forEach(
      ([zipCode, subscription]) => {
        if (selectedZips.has(zipCode)) {
          const isActive = subscription.status === "active";

          // More detailed debugging
          console.log(`Processing subscription for ${zipCode}:`, {
            subscription,
            winningBid: subscription.winningBid,
            winningBid_type: typeof subscription.winningBid,
            amount_paid: subscription.amount_paid,
            amount_paid_type: typeof subscription.amount_paid,
            isActive,
            status: subscription.status,
            all_keys: Object.keys(subscription),
          });

          // Try to parse the winningBid value directly
          let amount = 0;
          if (subscription.winningBid !== undefined) {
            // Handle different types
            if (typeof subscription.winningBid === "number") {
              amount = subscription.winningBid;
            } else if (typeof subscription.winningBid === "string") {
              amount = parseInt(subscription.winningBid, 10) || 0;
            }
            console.log(`Parsed winningBid amount for ${zipCode}:`, amount);
          } else if (subscription.amount_paid !== undefined) {
            // Handle different types
            if (typeof subscription.amount_paid === "number") {
              amount = subscription.amount_paid;
            } else if (typeof subscription.amount_paid === "string") {
              amount = parseInt(subscription.amount_paid, 10) || 0;
            }
            console.log(`Parsed amount_paid amount for ${zipCode}:`, amount);
          }

          if (isActive) {
            result.activeAmount += amount;
            result.activeCount++;
          } else {
            result.pendingAmount += amount;
            result.pendingCount++;
          }
        }
      }
    );

    console.log("Calculated totals:", result);
    return result;
  }, [zipSubscriptions, selectedZips, isLoading]);

  // Monitor auction times and current bids
  useEffect(() => {
    let isMounted = true;
    let isUpdating = false;
    let updateCount = 0;
    const MAX_UPDATES_PER_MINUTE = 20;
    let lastResetTime = Date.now();

    const updateAuctionData = async () => {
      // Skip if component unmounted, popup is showing, or already updating
      if (!isMounted || showBidPopup || isUpdating) return;

      // Check if we're updating too frequently
      const now = Date.now();
      if (now - lastResetTime > 60000) {
        updateCount = 0;
        lastResetTime = now;
      }

      if (updateCount >= MAX_UPDATES_PER_MINUTE) {
        return;
      }

      updateCount++;
      isUpdating = true;

      try {
        const newTimes = { ...auctionTimes };
        const newBids = { ...currentBids };
        const newAuctionStatus = [];
        let allEnded = true;
        let currentPendingBids = 0;

        for (const zipCode of selectedZips) {
          try {
            const auction = await auctionService.initializeAuction(zipCode);

            // If no auction exists, initialize with default values
            if (!auction) {
              const nextEndTime = await auctionService.getTimeRemaining(
                zipCode
              );
              newTimes[zipCode] = nextEndTime;
              newBids[zipCode] = auctionService.STARTING_PRICE;

              if (nextEndTime > 0) {
                allEnded = false;
                newAuctionStatus.push({
                  zipCode,
                  finalBid: auctionService.STARTING_PRICE,
                  cityState: `${ZIP_CODES[zipCode]?.city}, ${ZIP_CODES[zipCode]?.state}`,
                  isPending: true,
                  isCurrentlyWinning: false,
                });
              }
              continue;
            }

            const remaining = await auctionService.getTimeRemaining(zipCode);
            newTimes[zipCode] = remaining;
            newBids[zipCode] = auction.currentBid || 100000;

            // Calculate pending bids (where user is currently winning)
            if (remaining > 0 && auction.lastBidder === currentUserId) {
              currentPendingBids += auction.currentBid;
            }

            if (remaining > 0) {
              allEnded = false;
            }

            // Only log if there's a meaningful change in auction state
            const currentBid = currentBids[zipCode];
            const currentTime = auctionTimes[zipCode];
            if (
              currentBid !== auction.currentBid ||
              currentTime !== remaining
            ) {
              // console.log(`Auction status for ${zipCode}:`, {
              //   remaining,
              //   lastBidder: auction.lastBidder,
              //   currentUser: currentUserId,
              //   currentBid: auction.currentBid,
              // });
            }

            // Add status for non-winning pending auctions
            if (remaining > 0 && auction.lastBidder !== currentUserId) {
              newAuctionStatus.push({
                zipCode,
                finalBid: auction.currentBid,
                cityState: `${ZIP_CODES[zipCode]?.city}, ${ZIP_CODES[zipCode]?.state}`,
                isPending: true,
                isCurrentlyWinning: false,
              });
            }
            // Check auction status
            if (remaining <= 0) {
              // Auction has ended
              const isWinner = auction.lastBidder === currentUserId;
              newAuctionStatus.push({
                zipCode,
                finalBid: auction.currentBid,
                cityState: `${ZIP_CODES[zipCode]?.city}, ${ZIP_CODES[zipCode]?.state}`,
                isPending: false,
                isWinner: isWinner,
                endTime: auction.endTime,
              });
            } else if (auction.lastBidder === currentUserId) {
              // Currently winning but auction still ongoing
              newAuctionStatus.push({
                zipCode,
                finalBid: auction.currentBid,
                cityState: `${ZIP_CODES[zipCode]?.city}, ${ZIP_CODES[zipCode]?.state}`,
                isPending: true,
                isCurrentlyWinning: true,
                isWinner: false, // Not marked as winner until auction ends
              });
            }
          } catch (err) {
            if (err.code === "permission-denied") {
              if (process.env.NODE_ENV === "development") {
                console.warn(
                  `Permission denied for zip ${zipCode} - auction may not be properly initialized`
                );
              }
            } else {
              if (process.env.NODE_ENV === "development") {
                console.error(`Error updating data for zip ${zipCode}:`, err);
              }
            }
          }
        }

        if (!isMounted) {
          isUpdating = false;
          return;
        }

        // Calculate pending auctions where user is currently winning
        const currentPendingAuctions = newAuctionStatus.filter(
          (auction) => auction.isPending && auction.isCurrentlyWinning
        );
        const currentPendingTotal = currentPendingAuctions.reduce(
          (sum, auction) => sum + (auction.finalBid || 0),
          0
        );

        setPendingAuctions(currentPendingAuctions);
        setPendingAuctionsTotal(currentPendingTotal);

        // Only update state if there are actual changes
        const timesChanged =
          JSON.stringify(newTimes) !== JSON.stringify(auctionTimes);
        const bidsChanged =
          JSON.stringify(newBids) !== JSON.stringify(currentBids);

        if (timesChanged) {
          setAuctionTimes(newTimes);
        }

        if (bidsChanged) {
          setCurrentBids(newBids);
        }

        setAuctionStatus(newAuctionStatus);
        setAllAuctionsEnded(allEnded);
        setPendingBids(currentPendingBids);
      } catch (error) {
        console.error("Error in updateAuctionData:", error);
      } finally {
        isUpdating = false;
      }
    };

    // Local timer update function - just decrements existing timers without API calls
    const updateLocalTimers = () => {
      if (!isMounted) return;

      setAuctionTimes((prevTimes) => {
        const newTimes = { ...prevTimes };
        Object.keys(newTimes).forEach((zipCode) => {
          if (newTimes[zipCode] > 0) {
            newTimes[zipCode] = Math.max(0, newTimes[zipCode] - 1000);
          }
        });
        return newTimes;
      });
    };

    // Initial update
    updateAuctionData();

    // Set up intervals:
    // 1. Fetch real data from API every 5 seconds
    // 2. Update UI countdown every second
    const dataFetchInterval = setInterval(updateAuctionData, 5000);
    const uiUpdateInterval = setInterval(updateLocalTimers, 1000);

    return () => {
      isMounted = false;
      clearInterval(dataFetchInterval);
      clearInterval(uiUpdateInterval);
    };
  }, [selectedZips, showBidPopup]);

  // Add effect to load promotion statuses
  useEffect(() => {
    const loadPromotionStatuses = async () => {
      try {
        // Get all active promotions for the user
        const promotionsSnapshot = await db
          .collection("users")
          .doc(currentUserId)
          .collection("zip_promotions")
          .where("status", "in", ["active", "invoiced", "pending"])
          .get();

        const promotions = promotionsSnapshot.docs.map((doc) => ({
          zipCode: doc.id,
          ...doc.data(),
          cityState: `${ZIP_CODES[doc.id]?.city}, ${ZIP_CODES[doc.id]?.state}`,
        }));

        setActivePromotions(promotions);

        // Also update the simple status map for backward compatibility
        const statuses = {};
        for (const promotion of promotions) {
          statuses[promotion.zipCode] = promotion.status === "active";
        }
        setPromotionStatuses(statuses);
      } catch (error) {
        console.error("Error loading promotion statuses:", error);
        setActivePromotions([]);
      }
    };

    if (currentUserId) {
      loadPromotionStatuses();
    }
  }, [selectedZips, forceUpdate, currentUserId]);

  // Add this effect to load pending invoices
  useEffect(() => {
    const loadPendingInvoices = async () => {
      try {
        const pendingInvoices = [];

        // Check zip_ads collection for auction invoices with pending status
        if (zipSubscriptions) {
          Object.entries(zipSubscriptions).forEach(
            ([zipCode, subscription]) => {
              if (
                subscription.status === "pending" &&
                subscription.invoice_url
              ) {
                pendingInvoices.push({
                  id: subscription.invoice_id,
                  hosted_invoice_url: subscription.invoice_url,
                  type: "auction",
                  zipCode,
                  ...subscription,
                });
              }
            }
          );
        }

        // Check zip_promotions for invoiced promotions
        if (activePromotions) {
          activePromotions.forEach((promotion) => {
            if (promotion.status === "invoiced" && promotion.invoiceUrl) {
              pendingInvoices.push({
                id: promotion.invoiceId,
                hosted_invoice_url: promotion.invoiceUrl,
                type: "promotion",
                zipCode: promotion.zipCode,
                ...promotion,
              });
            }
          });
        }

        setPendingInvoices(pendingInvoices);
      } catch (error) {
        console.error("Error loading pending invoices:", error);
        setPendingInvoices([]);
      }
    };

    if (currentUserId) {
      loadPendingInvoices();
    }
  }, [currentUserId, forceUpdate, zipSubscriptions, activePromotions]);

  const handlePromotionChange = async () => {
    try {
      await handleServiceError(async () => {
        setForceUpdate((prev) => prev + 1);
      });
    } catch (error) {
      showErrorNotification("Failed to update promotion status");
    }
  };

  // Add this helper function to organize subscriptions
  const getActiveSubscriptions = () => {
    return Object.keys(zipSubscriptions || {})
      .map((zipCode) => ({
        zipCode,
        status: zipSubscriptions[zipCode].status,
        amount: zipSubscriptions[zipCode].amount_paid,
        endDate: zipSubscriptions[zipCode].end_date,
        cityState: `${ZIP_CODES[zipCode]?.city}, ${ZIP_CODES[zipCode]?.state}`,
      }))
      .sort((a, b) => a.zipCode.localeCompare(b.zipCode));
  };

  // Add this function to combine ads and promotions by ZIP code
  const getCombinedSubscriptions = () => {
    const combined = {};

    // First, add all active subscriptions
    const activeSubscriptions = getActiveSubscriptions();
    activeSubscriptions.forEach((subscription) => {
      combined[subscription.zipCode] = {
        zipCode: subscription.zipCode,
        cityState: subscription.cityState,
        ad: subscription,
        promotion: null,
      };
    });

    // Then add promotions, either to existing entries or as new ones
    activePromotions
      .filter(
        (promo) => promo.status === "active" || promo.status === "invoiced"
      )
      .forEach((promotion) => {
        if (combined[promotion.zipCode]) {
          combined[promotion.zipCode].promotion = promotion;
        } else {
          combined[promotion.zipCode] = {
            zipCode: promotion.zipCode,
            cityState: promotion.cityState,
            ad: null,
            promotion: promotion,
          };
        }
      });

    // Convert to array and sort by ZIP code
    return Object.values(combined).sort((a, b) =>
      a.zipCode.localeCompare(b.zipCode)
    );
  };

  // Modify the renderSubscriptionsTable function to include the payment button and improve the layout
  const renderSubscriptionsTable = () => {
    const combinedSubscriptions = getCombinedSubscriptions();
    const hasPendingItems = combinedSubscriptions.some(
      (item) =>
        (item.ad && item.ad.status !== "active") ||
        (item.promotion && item.promotion.status === "invoiced")
    );

    // Find the most recent invoice URL if there are pending items
    const pendingInvoiceUrl =
      pendingInvoices.length > 0 ? pendingInvoices[0].hosted_invoice_url : null;

    if (isLoading) {
      return (
        <Paper sx={{ mb: 3, p: 2 }}>
          <Typography>Loading subscription data...</Typography>
        </Paper>
      );
    }

    if (combinedSubscriptions.length === 0) {
      return null;
    }

    return (
      <Paper sx={{ mt: 3, mb: 3 }}>
        <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Active Subscriptions & Promotions
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {totals.activeCount} active ads ($
                {(totals.activeAmount / 100).toLocaleString()})
                {totals.pendingCount > 0 && (
                  <Box component="span" sx={{ color: "warning.main", ml: 1 }}>
                    + {totals.pendingCount} pending ($
                    {totals.pendingAmount.toLocaleString()})
                  </Box>
                )}
                {activePromotions.filter((p) => p.status === "active").length >
                  0 && (
                  <Box component="span" sx={{ color: "info.main", ml: 1 }}>
                    •{" "}
                    {
                      activePromotions.filter((p) => p.status === "active")
                        .length
                    }{" "}
                    active promotions
                  </Box>
                )}
                {activePromotions.filter((p) => p.status === "invoiced")
                  .length > 0 && (
                  <Box component="span" sx={{ color: "warning.main", ml: 1 }}>
                    •{" "}
                    {
                      activePromotions.filter((p) => p.status === "invoiced")
                        .length
                    }{" "}
                    pending promotions ($
                    {(
                      activePromotions.filter((p) => p.status === "invoiced")
                        .length * 100
                    ).toLocaleString()}
                  </Box>
                )}
              </Typography>
            </Box>
            {hasPendingItems && pendingInvoiceUrl && (
              <Button
                variant="contained"
                color="warning"
                href={pendingInvoiceUrl}
                target="_blank"
                rel="noopener noreferrer"
                startIcon={<PaymentRounded />}
              >
                Pay Invoice
              </Button>
            )}
          </Box>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "background.paper" }}>
                <TableCell>Location</TableCell>
                <TableCell>Ad Status</TableCell>
                <TableCell>Promotion Status</TableCell>
                <TableCell>Expiration</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {combinedSubscriptions.map((item) => (
                <TableRow
                  key={item.zipCode}
                  sx={{
                    bgcolor:
                      item.ad?.status === "active" &&
                      item.promotion?.status === "active"
                        ? "success.lighter"
                        : item.ad?.status === "active" ||
                          item.promotion?.status === "active"
                        ? "info.lighter"
                        : "inherit",
                  }}
                >
                  <TableCell>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {item.zipCode}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.cityState}
                      </Typography>
                    </Box>
                  </TableCell>

                  {/* Ad Status Cell */}
                  <TableCell>
                    {item.ad ? (
                      <Chip
                        label={
                          item.ad.status === "active"
                            ? "Active"
                            : "Pending Payment"
                        }
                        color={
                          item.ad.status === "active" ? "success" : "warning"
                        }
                        size="small"
                        sx={{ minWidth: 100 }}
                      />
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        —
                      </Typography>
                    )}
                  </TableCell>

                  {/* Promotion Status Cell */}
                  <TableCell>
                    {item.promotion ? (
                      <Chip
                        label={
                          item.promotion.status === "active"
                            ? "Active"
                            : "Pending Payment"
                        }
                        color={
                          item.promotion.status === "active"
                            ? "success"
                            : "warning"
                        }
                        size="small"
                        sx={{ minWidth: 100 }}
                      />
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        —
                      </Typography>
                    )}
                  </TableCell>

                  {/* Expiration Cell */}
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                      }}
                    >
                      {item.ad && item.ad.expirationDate && (
                        <Typography variant="body2" color="text.secondary">
                          Ad:{" "}
                          {new Date(
                            item.ad.expirationDate.seconds * 1000
                          ).toLocaleDateString()}
                        </Typography>
                      )}

                      {item.promotion && item.promotion.expirationDate && (
                        <Typography variant="body2" color="text.secondary">
                          Promo:{" "}
                          {new Date(
                            item.promotion.expirationDate.seconds * 1000
                          ).toLocaleDateString()}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  if (error) {
    return (
      <Alert severity="error" sx={{ p: 2, borderRadius: 1 }}>
        Error loading table: {error}
      </Alert>
    );
  }

  // Helper function to format time remaining
  const formatTimeRemaining = (ms) => {
    if (ms <= 0) return "Ended";

    // If more than 24 hours left, show relative time
    if (ms > 24 * 60 * 60 * 1000) {
      const futureDate = new Date(Date.now() + ms);
      return formatDistanceToNow(futureDate, { addSuffix: true });
    }

    // Otherwise show detailed countdown
    const totalSeconds = Math.ceil(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  // Add this check before the return statement
  const hasEndedAuctions = Object.values(auctionTimes).some(
    (time) => time <= 0
  );

  return (
    <Box>
      <SubscriptionsTable
        totals={totals}
        activePromotions={activePromotions}
        zipSubscriptions={zipSubscriptions}
        selectedZips={selectedZips}
        pendingInvoices={pendingInvoices}
        isLoading={isLoading}
      />

      <AuctionsTable
        selectedZips={selectedZips}
        auctionTimes={auctionTimes}
        currentBids={currentBids}
        zipSubscriptions={zipSubscriptions}
        pendingAuctions={pendingAuctions}
        pendingAuctionsTotal={pendingAuctionsTotal}
        handlePromotionChange={handlePromotionChange}
        formatTimeRemaining={formatTimeRemaining}
        setAuctionTimes={setAuctionTimes}
      />

      {showExpansionPrompt && (
        <ExpansionPromptPopup onClose={() => setShowExpansionPrompt(false)} />
      )}

      {currentReport && (
        <AuctionReportModal
          report={currentReport}
          onClose={() => setCurrentReport(null)}
        />
      )}
    </Box>
  );
};

export default SelectedZipsTable;
