import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
  MobileStepper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ChatIcon from "@mui/icons-material/Chat";
import MedicationIcon from "@mui/icons-material/Medication";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { db } from "services/firebase";

const PatientOnboardingDialog = ({ open, onClose, userId }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleClose = () => {
    // Close the dialog immediately
    onClose();

    // Save preference in the background if needed
    if (dontShowAgain && userId) {
      db.collection("users")
        .doc(userId)
        .update({
          hideOnboardingDialog: true,
        })
        .catch((error) => {
          console.error("Error saving onboarding preference:", error);
        });
    }
  };

  const steps = [
    {
      label: "Welcome to HLTHDSK",
      content: (
        <Box sx={{ textAlign: "center", py: 2 }}>
          <ChatIcon color="primary" sx={{ fontSize: 64, mb: 3 }} />
          <Typography variant="h5" sx={{ mb: 3 }}>
            Meet Your Personal Medical Assistant
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to HLTHDSK. We're excited to help you understand your health
            better.
          </Typography>
          <Typography variant="body1" paragraph>
            Medical SuperIntelligence is like having a window into a doctor's
            thought process, providing clear, reliable insights when you need
            them most.
          </Typography>
          <Typography variant="body1" paragraph>
            To get started, click one of the buttons to start a chat.
          </Typography>
        </Box>
      ),
    },
    {
      label: "Symptom Reviews",
      content: (
        <Box sx={{ textAlign: "center", py: 2 }}>
          <CoronavirusIcon color="primary" sx={{ fontSize: 64, mb: 3 }} />
          <Typography variant="h5" sx={{ mb: 3 }}>
            Understand Your Symptoms
          </Typography>
          <Typography variant="body1" paragraph>
            Discuss your symptoms with our Medical SuperIntelligence to learn
            about possible causes.
          </Typography>
          <Typography variant="body1" paragraph>
            HLTHDSK empowers you to understand your symptoms and know when and
            where to seek appropriate care.
          </Typography>
        </Box>
      ),
    },
    {
      label: "Medication Help",
      content: (
        <Box sx={{ textAlign: "center", py: 2 }}>
          <MedicationIcon color="primary" sx={{ fontSize: 64, mb: 3 }} />
          <Typography variant="h5" sx={{ mb: 3 }}>
            Medication Information
          </Typography>
          <Typography variant="body1" paragraph>
            Get in-depth knowledge about your medications, potential side
            effects, and interactions.
          </Typography>
          <Typography variant="body1" paragraph>
            Our system helps you transform confusion into clarity about your
            medications, supporting your healthcare journey.
          </Typography>
        </Box>
      ),
    },
    {
      label: "Upgrade Options",
      content: (
        <Box sx={{ textAlign: "center", py: 2 }}>
          <UpgradeIcon color="primary" sx={{ fontSize: 64, mb: 3 }} />
          <Typography variant="h5" sx={{ mb: 3 }}>
            Unlimited Access
          </Typography>
          <Typography variant="body1" paragraph>
            For unlimited access and an ad-free experience, upgrade to become a
            HLTHDSK Member.
          </Typography>
          <Typography variant="body1" paragraph>
            Invest in yourself with Medical SuperIntelligence™ and experience
            healthcare with comfort, confidence, and coherence.
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: { borderRadius: 2 },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
          zIndex: 1,
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ p: 4, pb: 1 }}>
        {steps[activeStep].content}

        <MobileStepper
          variant="dots"
          steps={steps.length}
          position="static"
          activeStep={activeStep}
          sx={{
            background: "transparent",
            justifyContent: "center",
            mt: 2,
          }}
          nextButton={null}
          backButton={null}
        />
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between", p: 3 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={dontShowAgain}
              onChange={(e) => setDontShowAgain(e.target.checked)}
            />
          }
          label="Don't show this again"
        />
        <Box>
          {activeStep > 0 && (
            <Button onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
          )}

          {activeStep === steps.length - 1 ? (
            <Button variant="contained" onClick={handleClose}>
              Get Started
            </Button>
          ) : (
            <Button variant="contained" onClick={handleNext}>
              Next
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PatientOnboardingDialog;
