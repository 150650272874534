import { db } from "services/firebase";
import { PromotionError } from "../utils/errorHandling";

class PromotionService {
  /**
   * Set promotion status for a zip code
   * @param {string} zipCode - The zip code
   * @param {boolean} isPromoting - Whether to enable or disable promotion
   * @param {object} userData - The current user's data
   */
  async setPromotionStatus(zipCode, isPromoting, userData) {
    try {
      if (!zipCode) {
        throw new PromotionError("Invalid zip code provided");
      }

      if (typeof isPromoting !== "boolean") {
        throw new PromotionError("Promotion status must be a boolean value");
      }

      if (!userData?.uid) {
        throw new PromotionError("No authenticated user found");
      }

      const zipPromotionRef = db
        .collection("zips")
        .doc(String(zipCode))
        .collection("promotions")
        .doc(userData.uid);

      const userPromotionRef = db
        .collection("users")
        .doc(userData.uid)
        .collection("zip_promotions")
        .doc(String(zipCode));

      if (isPromoting) {
        const promotionData = {
          status: "pending",
          active: false,
          userId: userData.uid,
          zipCode: zipCode, // Add zipCode to make the user-side document self-contained
          createdAt: new Date().toISOString(),
          userInfo: {
            id: userData.uid,
            email: userData.email || null,
          },
        };

        const batch = db.batch();
        batch.set(zipPromotionRef, promotionData);
        batch.set(userPromotionRef, promotionData);
        await batch.commit();
      } else {
        // Only allow deletion if status is pending
        const doc = await zipPromotionRef.get();
        if (doc.exists && doc.data().status !== "pending") {
          throw new PromotionError(
            `Cannot cancel a promotion that is already ${doc.data().status}`
          );
        }

        // Delete from both locations
        const batch = db.batch();
        batch.delete(zipPromotionRef);
        batch.delete(userPromotionRef);
        await batch.commit();
      }
    } catch (error) {
      throw new PromotionError(
        `Failed to set promotion status: ${error.message}`
      );
    }
  }

  /**
   * Get detailed promotion status for a zip code
   * @param {string} zipCode - The zip code
   * @param {object} userData - The current user's data
   * @returns {Promise<object|null>} - Promotion data or null if not promoting
   */
  async getPromoterStatus(zipCode, userData) {
    try {
      if (!zipCode || !userData?.uid) {
        return null;
      }

      const promotionRef = db
        .collection("zips")
        .doc(String(zipCode))
        .collection("promotions")
        .doc(userData.uid);

      const doc = await promotionRef.get();

      if (doc.exists) {
        return doc.data();
      }

      return null;
    } catch (error) {
      console.error("Error checking promotion status:", error);
      return null;
    }
  }

  /**
   * Check if user is a promoter for a zip code (simplified version)
   * @param {string} zipCode - The zip code
   * @param {object} userData - The current user's data (optional)
   * @returns {Promise<boolean>} - Whether the user is promoting
   */
  async isPromoter(zipCode, userData) {
    try {
      const promotionData = await this.getPromoterStatus(zipCode, userData);
      return promotionData?.active === true;
    } catch (error) {
      console.error("Error checking if user is promoter:", error);
      return false;
    }
  }

  /**
   * Get promotion details for a zip code
   */
  async getPromotionDetails(zipCode) {
    try {
      if (!zipCode) {
        throw new PromotionError("Invalid zip code provided");
      }

      const promotionsSnapshot = await db
        .collection("zips")
        .doc(String(zipCode))
        .collection("promotions")
        .where("active", "==", true)
        .limit(1)
        .get();

      return promotionsSnapshot.empty
        ? null
        : promotionsSnapshot.docs[0].data();
    } catch (error) {
      throw new PromotionError(
        `Failed to get promotion details: ${error.message}`
      );
    }
  }

  /**
   * Get all active promotions
   */
  async getAllPromotions() {
    try {
      const promotionsSnapshot = await db
        .collectionGroup("promotions")
        .where("active", "==", true)
        .get();

      const promotions = [];
      promotionsSnapshot.forEach((doc) => {
        const data = doc.data();
        const zipCode = doc.ref.parent.parent.id;
        promotions.push({
          zipCode,
          ...data,
        });
      });
      return promotions;
    } catch (error) {
      throw new PromotionError(
        `Failed to get all promotions: ${error.message}`
      );
    }
  }

  /**
   * Clear all promotions (useful for testing)
   * Note: This should only be used in development/testing
   */
  async clearPromotions() {
    try {
      const batch = db.batch();
      const promotionsSnapshot = await db.collectionGroup("promotions").get();

      promotionsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
    } catch (error) {
      throw new PromotionError(`Failed to clear promotions: ${error.message}`);
    }
  }
}

const promotionService = new PromotionService();
export default promotionService;
