import React from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { StarRounded } from "@mui/icons-material";

const Filters = ({ setFilter, filter, ads = [], sx = {}, style = {} }) => {
  const filters = [
    // { value: "all", name: "All" }, // Removed for now according to KAN-674
    { value: "clinic", name: "Urgent" },
    { value: "emergency", name: "Emergency" },
  ];

  const ratings = [1, 5, 4, 3, 2];

  // Set default values if not already set
  React.useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      facility: prev.facility || "all",
      rating: prev.rating || 1,
    }));
  }, [setFilter]);

  // Determine if we should show the second select
  const showSecondSelect =
    filter.facility === "emergency" || (ads && ads.length > 0);

  // Determine which type of second select to show
  const showStarRating = filter.facility === "emergency";

  // Helper function for handling filter changes
  const handleFilterChange = (field) => (event) => {
    console.log("Field:", field);
    console.log("New value:", event.target.value);
    setFilter((prev) => {
      const newState = {
        ...prev,
        [field]: event.target.value,
        ...(field === "facility" &&
          event.target.value !== "emergency" && { rating: "" }),
        ...(field === "facility" &&
          event.target.value === "emergency" && { group: "", rating: 1 }),
      };
      console.log("New state:", newState);
      return newState;
    });
  };

  const commonSelectProps = {
    size: "small",
    sx: {
      minWidth: 150,
      width: "100%",
      "& .MuiSelect-select": {
        display: "flex",
        alignItems: "center",
      },
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: "0.75rem",
        maxWidth: "500px",
        margin: "auto",
        ...sx // This allows us to customize the style from the properties passed in!
      }}
      style={style} // Same as above
    >
      <FormControl {...commonSelectProps}>
        <InputLabel id="filter-facility-label">Level of Care</InputLabel>
        <Select
          labelId="filter-facility-label"
          id="filter-facility"
          value={filter.facility || "all"}
          label="Level of Care"
          onChange={handleFilterChange("facility")}
        >
          {filters.map(({ value, name }) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {showSecondSelect && (
        <FormControl {...commonSelectProps}>
          <InputLabel
            id={showStarRating ? "star-rating-label" : "facility-groups-label"}
          >
            {showStarRating ? "Star Rating" : "Facility Groups"}
          </InputLabel>
          <Select
            labelId={
              showStarRating ? "star-rating-label" : "facility-groups-label"
            }
            id={showStarRating ? "star-rating" : "facility-groups"}
            value={showStarRating ? filter.rating || 1 : filter.group || ""}
            label={showStarRating ? "Star Rating" : "Facility Groups"}
            onChange={handleFilterChange(showStarRating ? "rating" : "group")}
          >
            {showStarRating
              ? ratings.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value === 1 ? (
                      "All"
                    ) : (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {[...Array(value)].map((_, i) => (
                          <StarRounded
                            key={i}
                            sx={{
                              color: "#FFC404",
                              width: "1.25rem",
                              height: "1.25rem",
                            }}
                          />
                        ))}
                        {value !== 5 && (
                          <span style={{ paddingLeft: "3px" }}>+</span>
                        )}
                      </Box>
                    )}
                  </MenuItem>
                ))
              : ads.map(({ title }) => (
                  <MenuItem key={title} value={title}>
                    {title}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default Filters;
