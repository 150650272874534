import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { ZIP_CODES } from "../auction/data/zipCodes";
import PromotionButton from "./PromotionButton";
import BidButton from "./BidButton";
import AuctionStatus, { CurrentBid, TimeRemaining } from "./AuctionStatus";
import { db } from "../../../services/firebase";

const ZipCodeTable = ({ selectedZips }) => {
  const { userData, zipPromotions } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [auctionsData, setAuctionsData] = useState({});
  const [userBids, setUserBids] = useState({});

  useEffect(() => {
    // Load auction data for selected zip codes
    const loadAuctionData = async () => {
      if (!selectedZips || selectedZips.size === 0 || !userData?.uid) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        const auctionsMap = {};
        const userBidsMap = {};

        // Fetch auction data for each selected zip code
        const promises = Array.from(selectedZips).map(async (zipCode) => {
          // Get auction document
          const auctionDoc = await db.collection("auctions").doc(zipCode).get();

          if (auctionDoc.exists) {
            const auctionData = auctionDoc.data();
            auctionsMap[zipCode] = auctionData;

            // Check if user has placed a bid
            const hasBid =
              auctionData.bidHistory &&
              auctionData.bidHistory.some((bid) => bid.userId === userData.uid);

            // Check if user is winning
            const isWinning = auctionData.lastBidder === userData.uid;

            userBidsMap[zipCode] = {
              hasBid,
              isWinning,
              amount: hasBid
                ? auctionData.bidHistory.find(
                    (bid) => bid.userId === userData.uid
                  ).amount
                : null,
            };
          } else {
            // Create a default auction object if it doesn't exist
            auctionsMap[zipCode] = {
              currentBid: null,
              startingPrice: 100000, // $1000.00
              endTime: new Date(new Date().setDate(new Date().getDate() + 30)), // 30 days from now
              status: "active",
              numberOfBids: 0,
              bidHistory: [],
            };
            userBidsMap[zipCode] = {
              hasBid: false,
              isWinning: false,
              amount: null,
            };
          }
        });

        await Promise.all(promises);

        setAuctionsData(auctionsMap);
        setUserBids(userBidsMap);
      } catch (error) {
        console.error("Error loading auction data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadAuctionData();
  }, [selectedZips, userData]);

  if (!selectedZips || selectedZips.size === 0) {
    return (
      <Paper sx={{ p: 3 }}>
        <Typography>
          No ZIP codes selected. Please select ZIP codes on the map.
        </Typography>
      </Paper>
    );
  }

  return (
    <TableContainer component={Paper}>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 200,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ZIP Code Location</TableCell>
              <TableCell>Promotion Status</TableCell>
              <TableCell>Current Bid</TableCell>
              <TableCell>Time Remaining</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(selectedZips).map((zipCode) => {
              const zipData = ZIP_CODES[zipCode];
              const hasPromotion =
                zipPromotions && zipPromotions[zipCode]?.status === "active";
              const auctionData = auctionsData[zipCode] || {};
              const userBidData = userBids[zipCode] || {
                hasBid: false,
                isWinning: false,
              };

              if (!zipData) return null;

              return (
                <TableRow key={zipCode}>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      {zipCode}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {`${zipData.city}, ${zipData.state}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <PromotionButton
                      zipCode={zipCode}
                      hasBid={userBidData.hasBid}
                    />
                  </TableCell>
                  <TableCell>
                    <CurrentBid auctionData={auctionData} />
                  </TableCell>
                  <TableCell>
                    <TimeRemaining auctionData={auctionData} />
                  </TableCell>
                  <TableCell>
                    <BidButton
                      zipCode={zipCode}
                      hasBid={userBidData.hasBid}
                      isWinning={userBidData.isWinning}
                      currentBid={
                        auctionData.currentBid || auctionData.startingPrice
                      }
                      auctionData={auctionData}
                      onBidPlaced={(amount) => {
                        // Refresh auction data after bid is placed
                        setAuctionsData((prev) => ({
                          ...prev,
                          [zipCode]: {
                            ...prev[zipCode],
                            currentBid: amount,
                            lastBidder: userData.uid,
                            numberOfBids:
                              (prev[zipCode]?.numberOfBids || 0) + 1,
                          },
                        }));
                        setUserBids((prev) => ({
                          ...prev,
                          [zipCode]: {
                            hasBid: true,
                            isWinning: true,
                            amount: amount,
                          },
                        }));
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default ZipCodeTable;
