import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PatientBilling from "components/chatbot/PatientBilling";
import { useAuth } from "hooks/useAuth";
import { useFacility } from "hooks/useFacility";
import DiscussSymptomsPage from "pages/dashboard/individual/DiscussSymptomsPage";
import UpgradePage from "pages/dashboard/UpgradePage";
import Approval from "components/dashboard/admin/Approval";
import DashboardLayout from "components/dashboard/DashboardLayout";
import Account from "components/dashboard/Account";
import PatientOnboardingDialog from "components/dashboard/PatientOnboardingDialog";
import ProfessionalOnboardingDialog from "components/dashboard/ProfessionalOnboardingDialog";
import { db } from "services/firebase";

const PatientRoutes = () => {
  const { user, userData, subscription } = useAuth();
  const { data } = useFacility();
  const [showPatientOnboarding, setShowPatientOnboarding] = useState(false);
  const [showProfessionalOnboarding, setShowProfessionalOnboarding] =
    useState(false);

  useEffect(() => {
    // Check if we should show the onboarding dialog
    const checkOnboardingStatus = async () => {
      if (user?.uid) {
        try {
          const userDoc = await db.collection("users").doc(user.uid).get();
          const userData = userDoc.data();

          if (userData?.role === "patient" && !userData?.hideOnboardingDialog) {
            setShowPatientOnboarding(true);
          } else if (
            userData?.role === "professional" &&
            !userData?.hideOnboardingDialog
          ) {
            setShowProfessionalOnboarding(true);
          }
        } catch (error) {
          console.error("Error checking onboarding status:", error);
        }
      }
    };

    checkOnboardingStatus();
  }, [user]);

  const handleClosePatientOnboarding = () => {
    setShowPatientOnboarding(false);
  };

  const handleCloseProfessionalOnboarding = () => {
    setShowProfessionalOnboarding(false);
  };

  return (
    <DashboardLayout>
      {/* Show the appropriate onboarding dialog based on user role */}
      <PatientOnboardingDialog
        open={showPatientOnboarding}
        onClose={handleClosePatientOnboarding}
        userId={user?.uid}
      />
      <ProfessionalOnboardingDialog
        open={showProfessionalOnboarding}
        onClose={handleCloseProfessionalOnboarding}
        userId={user?.uid}
      />

      <Routes>
        {/* <Route
        exact
        path="/"
        element={
          <div>
            <PatientDashboard userData={userData} branding={data.branding} />
          </div>
        }
      /> */}
        <Route
          path="/"
          element={
            <div style={{ height: "100dvh" }}>
              <DiscussSymptomsPage />
            </div>
          }
        />
        <Route
          path="/account"
          element={<Account uid={user?.uid} subscription={subscription} />}
        />
        <Route path="/upgrade" element={<UpgradePage />} />
        <Route path="/admin/approval" element={<Approval />} />

        {/* Catch-all route to dashboard */}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </DashboardLayout>
  );
};

export default PatientRoutes;
