import React, { useState, useEffect, useContext, useRef } from "react";
import { Box, Button, IconButton, Tab, Tabs } from "@mui/material";
import { MainContainer } from "@chatscope/chat-ui-kit-react";
import { useTabSizes } from "components/chatbot/ContextBox/useTabSizes";
import { useKijabeContentAnalysis } from "components/chatbot/kijabe/useKijabeContentAnalysis";
import MiniMap from "components/chatbot/ContextBox/MiniMap";
import KijabeDiseasesBox from "components/chatbot/kijabe/KijabeDiseasesBox";
import KijabeMedicationBox from "components/chatbot/kijabe/KijabeMedicationBox";
import KijabeChatbot from "components/chatbot/kijabe/KijabeChatbot";
import { KijabeChatContext } from "context/KijabeChat";
import { ChatRounded } from "@mui/icons-material";

const KijabeContextBox = ({ branding }) => {
  const {
    messages,
    tabs,
    setTabs,
    expanded,
    setExpanded,
    currentTab,
    setCurrentTab,
    newThread,
  } = useContext(KijabeChatContext);
  const [previewTab, setPreviewTab] = useState(tabs[tabs.length - 1]);
  const boxRef = useRef(null);

  // For unauthenticated chat, we'll use a simpler content analysis
  // Keeping this for reference but we won't use the results
  const { medicationsList, diseasesList } = useKijabeContentAnalysis(
    null,
    tabs,
    setTabs
  );

  const openTab = (tab) => {
    // Always set to home since we only have one tab now
    setCurrentTab("home");
    setExpanded(true);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    setExpanded(newValue !== "home");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  // These functions are kept for reference but won't be used
  const getLabel = (tab) => {
    if (tab === "diseases") {
      return "Clinical Considerations";
    }
    if (tab === "map_urgent") return "Map";
    return tab;
  };

  const isHighlighted = (tab) =>
    previewTab === tab && !expanded ? { color: "#1B4584" } : null;

  const isVisible = (tab) =>
    currentTab === tab || (previewTab === tab && !expanded);

  // Force currentTab to be "home" if it's anything else
  useEffect(() => {
    if (currentTab !== "home") {
      setCurrentTab("home");
    }
  }, [currentTab, setCurrentTab]);

  // Comment out the effect that sets the preview tab
  /*
  useEffect(() => {
    if (tabs.length > 0) setPreviewTab(tabs[tabs.length - 1]);
  }, [tabs]);
  */

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        width: "100%",
        maxWidth: "1000px",
      }}
    >
      <Box
        sx={{
          display: messages.length > 0 ? "flex" : "none",
          gap: "0.5rem",
          width: "calc(100% + 50px)",
          paddingTop: "0.5rem",
          overflowX: "auto",
          marginLeft: "-25px",
          marginRight: "-50px",
          paddingLeft: "25px",
          paddingRight: "25px",
          height: "40px",
          alignItems: "flex-end",
          borderBottom: 1,
          borderColor: "divider",
          zIndex: 999,
        }}
      >
        <Box sx={{ position: "relative", width: "100%" }}>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              minHeight: "36px",
              "& .MuiTab-root": {
                minHeight: "36px",
                padding: "6px 12px",
                fontSize: 13,
              },
            }}
          >
            <Tab label="SuperIntelligence" value="home" />
            {/* Comment out the additional tabs
            {tabs.map((tab, i) => (
              <Tab
                key={i}
                label={getLabel(tab)}
                value={tab}
                sx={{
                  ...isHighlighted(tab),
                  minHeight: "36px",
                }}
              />
            ))}
            */}
          </Tabs>
          <Button
            startIcon={<ChatRounded sx={{ fontSize: 18 }} />}
            sx={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              bgcolor: "background.paper",
              color: "text.secondary",
              minWidth: "auto",
              padding: "4px 8px",
              fontSize: 13,
              "&:hover": {
                bgcolor: "action.hover",
                color: "text.primary",
              },
            }}
            onClick={() => {
              newThread();
              setTabs([]);
            }}
          >
            New Chat
          </Button>
        </Box>
      </Box>

      {/* Comment out the preview box for additional tabs
      <Box
        sx={{
          height: expanded ? "0" : "calc(30dvh - 55px)",
          display: messages?.length > 0 ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          display: tabs.length > 0 ? "block" : "none",
        }}
        ref={boxRef}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "calc(100% - 40px)",
          }}
        >
          {!expanded && (
            <>
              <MiniMap
                visible={isVisible("map") || isVisible("map_urgent")}
                urgent={tabs.includes("map_urgent")}
                expanded={expanded}
              />

              <KijabeMedicationBox
                medications={medicationsList}
                visible={isVisible("medications")}
                expanded={expanded}
                openTab={openTab}
              />

              <KijabeDiseasesBox
                diseases={diseasesList}
                visible={isVisible("diseases")}
                expanded={expanded}
                openTab={openTab}
              />
            </>
          )}
        </Box>
      </Box>
      */}

      <Box
        sx={{
          height: "calc(100dvh - 55px)", // Always use full height since we only have one tab
          marginTop: "15px",
          display: "block",
        }}
      >
        <MainContainer style={{ border: 0 }}>
          <KijabeChatbot
            visible={!expanded}
            branding={branding}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            expanded={expanded}
            openTab={openTab}
            tabs={tabs}
            boxRef={boxRef}
          />
          {/* Comment out the additional tab content components
          <MiniMap
            visible={currentTab === "map" || currentTab === "map_urgent"}
            urgent={tabs.includes("map_urgent")}
            expanded={expanded}
          />
          <KijabeMedicationBox
            medications={medicationsList}
            visible={currentTab === "medications"}
            expanded={expanded}
            openTab={openTab}
          />
          <KijabeDiseasesBox
            diseases={diseasesList}
            visible={currentTab === "diseases"}
            expanded={expanded}
            openTab={openTab}
          />
          */}
        </MainContainer>
      </Box>
    </Box>
  );
};

export default KijabeContextBox;
