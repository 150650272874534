import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { MAP_SETTINGS } from "./constants/mapSettings";
import { ZIP_CODES } from "./data/zipCodes";
import MapDrawTools from "./MapDrawTools";
import {
  handleServiceError,
  showErrorNotification,
} from "./utils/errorHandling";

const MapInstructions = ({ visible }) => (
  <div
    style={{
      position: "absolute",
      top: "20px",
      left: "50%",
      transform: `translateX(-50%) ${
        visible ? "translateY(0)" : "translateY(-100%)"
      }`,
      backgroundColor: "rgba(255, 255, 255, 0.95)",
      padding: "15px 20px",
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
      zIndex: 1000,
      maxWidth: "86%",
      textAlign: "center",
      transition: "transform 0.3s ease-in-out",
      opacity: visible ? 1 : 0,
    }}
  >
    <p style={{ margin: 0, fontSize: "16px", color: "#333" }}>
      Select your service areas by choosing specific zip codes.
    </p>
  </div>
);

const TooManyMarkersMessage = () => (
  <div
    style={{
      position: "absolute",
      top: "20px",
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      padding: "10px 15px",
      borderRadius: "5px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      zIndex: 1000,
      fontSize: "14px",
      maxWidth: "80%",
      textAlign: "center",
    }}
  >
    Zoom in to see zip code markers in a specific area
  </div>
);

const DynamicMap = ({ selectedZips, onZipToggle, onCircleSelect, icons }) => {
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  const [showTooManyMarkersMessage, setShowTooManyMarkersMessage] =
    useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [mapRef, setMapRef] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Show instructions after 24 seconds if user hasn't interacted
    const timer = setTimeout(() => {
      if (!hasInteracted) {
        setShowInstructions(true);
      }
    }, 24000);

    return () => clearTimeout(timer);
  }, [hasInteracted]);

  const handleMapInteraction = () => {
    setHasInteracted(true);
    setShowInstructions(false);
  };

  useEffect(() => {
    if (!mapRef) return;

    const updateVisibleMarkers = async () => {
      try {
        await handleServiceError(async () => {
          const bounds = mapRef.getBounds();
          const visibleZips = Object.values(ZIP_CODES).filter((zip) => {
            const latLng = [zip.lat, zip.lng];
            return bounds.contains(latLng);
          });

          if (visibleZips.length > 144) {
            setVisibleMarkers([]);
            setShowTooManyMarkersMessage(true);
          } else {
            setVisibleMarkers(visibleZips);
            setShowTooManyMarkersMessage(false);
          }
        });
      } catch (error) {
        setError("Failed to update map markers");
        showErrorNotification("Error updating map view: " + error.message);
      }
    };

    const handleMoveEnd = () => {
      handleMapInteraction();
      updateVisibleMarkers().catch((err) => {
        setError("Failed to update map after movement");
        showErrorNotification("Error updating map: " + err.message);
      });
    };

    mapRef.on("moveend", handleMoveEnd);
    mapRef.on("click", handleMapInteraction);
    mapRef.on("drag", handleMapInteraction);
    mapRef.on("zoom", handleMapInteraction);
    updateVisibleMarkers();

    return () => {
      mapRef.off("moveend", handleMoveEnd);
      mapRef.off("click", handleMapInteraction);
      mapRef.off("drag", handleMapInteraction);
      mapRef.off("zoom", handleMapInteraction);
    };
  }, [mapRef]);

  const handleMarkerClick = async (zipId) => {
    handleMapInteraction();
    try {
      await handleServiceError(async () => {
        onZipToggle(zipId);
      });
    } catch (err) {
      showErrorNotification("Error selecting zip code: " + err.message);
    }
  };

  if (error) {
    return (
      <div
        style={{
          padding: "20px",
          backgroundColor: "#fee2e2",
          border: "1px solid #ef4444",
          borderRadius: "4px",
          color: "#991b1b",
          margin: "10px 0",
        }}
      >
        {error}. Please try refreshing the page.
      </div>
    );
  }

  return (
    <div className="map-container">
      <div style={{ position: "relative" }}>
        <MapInstructions visible={showInstructions} />
        <MapContainer
          center={MAP_SETTINGS.defaultCenter}
          zoom={MAP_SETTINGS.defaultZoom}
          style={{ height: "500px", width: "100%" }}
          ref={setMapRef}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {visibleMarkers.map((zip) => (
            <Marker
              key={zip.id}
              position={[zip.lat, zip.lng]}
              icon={
                selectedZips.has(zip.id) ? icons.selected : icons.unselected
              }
              eventHandlers={{
                click: () => handleMarkerClick(zip.id),
              }}
            />
          ))}
          <MapDrawTools
            onZipSelect={async (zipCodes) => {
              handleMapInteraction();
              try {
                await handleServiceError(async () => {
                  onCircleSelect(zipCodes);
                });
              } catch (err) {
                showErrorNotification(
                  "Error selecting zip codes: " + err.message
                );
              }
            }}
            selectedZips={selectedZips}
          />
        </MapContainer>
        {showTooManyMarkersMessage && <TooManyMarkersMessage />}
      </div>
    </div>
  );
};

export default DynamicMap;
