import { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "context/Auth";
import firebaseApp from "services/firebase";

export const useAuth = () => {
  const context = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [dbError, setDbError] = useState(null);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  const {
    user,
    subscription,
    dailyPass,
    userData,
    userLoading,
    logout,
    zipSubscriptions,
    zipPromotions,
    invoices,
  } = context;

  const isAuthenticated = useMemo(() => {
    return user !== null && !userLoading;
  }, [user, userLoading]);

  const isVerified = useMemo(() => {
    return isAuthenticated && user?.emailVerified;
  }, [isAuthenticated, user]);

  const hasRole = useMemo(() => {
    return isVerified && userData?.role;
  }, [isVerified, userData]);

  const hasValidSubscription = useMemo(() => {
    return (
      hasRole &&
      (subscription ||
        userData?.role === "facility" ||
        (dailyPass &&
          dailyPass.status === "active" &&
          new Date(dailyPass.expiresAt) > new Date()))
    );
  }, [hasRole, subscription, userData, dailyPass]);

  const hasActiveDailyPass = useMemo(() => {
    return (
      dailyPass &&
      dailyPass.status === "active" &&
      new Date(dailyPass.expiresAt) > new Date()
    );
  }, [dailyPass]);

  const activeSubscriptionRole = useMemo(() => {
    if (!isVerified) return undefined;

    // Check for daily pass first
    if (hasActiveDailyPass) {
      return userData?.role;
    }

    // Then check for stripe subscription
    if (subscription) {
      return userData?.role;
    }

    // No valid subscription found
    return undefined;
  }, [isVerified, hasActiveDailyPass, subscription, userData?.role]);

  const canUseFreeTrial = useMemo(() => {
    return subscription === "facility" && !userData?.hasUsedFreeTrial;
  }, [subscription, userData]);

  const formattedZipSubscriptions = useMemo(() => {
    if (!zipSubscriptions) return {};

    const formatted = {};
    Object.entries(zipSubscriptions).forEach(([zipCode, subscription]) => {
      formatted[zipCode] = {
        ...subscription,
        // Ensure amount_paid is properly formatted as a number
        amount_paid: subscription.amount_paid
          ? typeof subscription.amount_paid === "string"
            ? parseInt(subscription.amount_paid, 10)
            : subscription.amount_paid
          : 0,
        // Ensure status is a string
        status: subscription.status || "pending",
      };
    });
    return formatted;
  }, [zipSubscriptions]);

  const formattedZipPromotions = useMemo(() => {
    if (!zipPromotions) return {};

    const formatted = {};
    Object.entries(zipPromotions).forEach(([zipCode, promotion]) => {
      formatted[zipCode] = {
        ...promotion,
        status: promotion.status || "pending",
        active: promotion.active || false,
      };
    });
    return formatted;
  }, [zipPromotions]);

  const resendEmail = async () => {
    setLoading(true);
    setDbError(null);

    try {
      await user.sendEmailVerification();
      setSubmitted(true);
    } catch (error) {
      setDbError(error.message);
      console.error("Email verification error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setDbError(null);
      setSubmitted(false);
    }
  }, [user]);

  const pendingInvoice = useMemo(() => {
    return invoices?.find((inv) => inv.status === "open");
  }, [invoices]);

  return {
    // User state
    user,
    userData,
    subscription,
    dailyPass,
    userLoading,
    zipSubscriptions: formattedZipSubscriptions,
    zipPromotions: formattedZipPromotions,

    // Verification state
    loading,
    submitted,
    dbError,

    // Computed state
    isAuthenticated,
    isVerified,
    hasRole,
    hasValidSubscription,
    hasActiveDailyPass,
    activeSubscriptionRole,
    canUseFreeTrial,

    // Methods
    resendEmail,
    logout,

    // Additional state
    invoices,
    pendingInvoice,
  };
};
