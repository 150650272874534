import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { useAuth } from "hooks/useAuth";

const DebugControls = () => {
  const { userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [zipCode, setZipCode] = useState("");

  // Only show in non-production environments
  const isNonProduction =
    process.env.NODE_ENV !== "production" ||
    process.env.REACT_APP_ENVIRONMENT !== "production";

  if (!isNonProduction) {
    return null;
  }

  const handleTriggerAuctionEnd = async (specificZipCode = null) => {
    setLoading(true);
    setError(null);
    setResult(null);

    try {
      // Using the older Firebase syntax for httpsCallable
      const triggerAuctionEnd = firebase
        .functions()
        .httpsCallable("auction.triggerAuctionEnd");
      const response = await triggerAuctionEnd({
        zipCode: specificZipCode,
        forceEnd: true,
      });

      setResult(response.data);
      console.log("Auction end triggered:", response.data);
    } catch (err) {
      setError(err.message || "An error occurred while triggering auction end");
      console.error("Error triggering auction end:", err);
    } finally {
      setLoading(false);
      setShowDialog(false);
    }
  };

  const openDialog = () => {
    setShowDialog(true);
    setZipCode("");
  };

  return (
    <Box
      sx={{
        mt: 4,
        p: 2,
        border: "1px dashed #ff9800",
        borderRadius: 2,
        bgcolor: "rgba(255, 152, 0, 0.1)",
      }}
    >
      <Typography variant="h6" sx={{ color: "#d32f2f", mb: 1 }}>
        Debug Controls (Non-Production Only)
      </Typography>

      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
        <Button
          variant="contained"
          color="warning"
          onClick={() => handleTriggerAuctionEnd()}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "End All Auctions Now"}
        </Button>

        <Button
          variant="outlined"
          color="warning"
          onClick={openDialog}
          disabled={loading}
        >
          End Specific ZIP Auction
        </Button>
      </Box>

      {result && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {result.message}
          {result.affectedAuctions && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Affected ZIP codes: {result.affectedAuctions.join(", ")}
            </Typography>
          )}
        </Alert>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>End Specific ZIP Auction</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="ZIP Code"
            type="text"
            fullWidth
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            placeholder="e.g. 90210"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)}>Cancel</Button>
          <Button
            onClick={() => handleTriggerAuctionEnd(zipCode)}
            color="warning"
            disabled={!zipCode.trim()}
          >
            Trigger End
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DebugControls;
