import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material";

const AuctionReportModal = ({ report, onClose }) => {
  if (!report) return null;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: "90vh",
        },
      }}
    >
      <DialogContent>
        <Typography variant="h5" color="primary.dark" sx={{ mb: 3 }}>
          Auction Report: ZIP {report.zipCode}
        </Typography>

        {/* Winner Section */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" color="success.main" sx={{ mb: 1 }}>
            Winner
          </Typography>
          {report.winner ? (
            <Paper
              sx={{
                p: 2,
                bgcolor: "success.light",
                borderRadius: 1,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="subtitle2" sx={{ minWidth: 100 }}>
                    Email:
                  </Typography>
                  <Typography>{report.winner.email}</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="subtitle2" sx={{ minWidth: 100 }}>
                    Winning Bid:
                  </Typography>
                  <Typography>
                    ${report.winner.winningBid.toLocaleString()}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          ) : (
            <Typography color="text.secondary">No winner determined</Typography>
          )}
        </Box>

        {/* Ranked Participants */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" color="primary.dark" sx={{ mb: 2 }}>
            Ranked Participants
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "grey.50" }}>
                  <TableCell>Rank</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {report.rankedParticipants.map((participant, index) => (
                  <TableRow key={participant.bidderId}>
                    <TableCell>#{index + 1}</TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontSize: "0.875rem" }}
                        >
                          {participant.email}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      ${participant.amount.toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={
                          participant.type === "auction"
                            ? "Auction"
                            : "Promotion Only"
                        }
                        color={
                          participant.type === "auction" ? "primary" : "success"
                        }
                        size="small"
                        variant="outlined"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Summary */}
        <Paper sx={{ p: 2, bgcolor: "grey.50", mb: 3 }}>
          <Typography variant="h6" color="primary.dark" sx={{ mb: 2 }}>
            Summary
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography variant="subtitle2" sx={{ minWidth: 160 }}>
                Total Participants:
              </Typography>
              <Typography>{report.totalParticipants}</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography variant="subtitle2" sx={{ minWidth: 160 }}>
                Total Bids:
              </Typography>
              <Typography>{report.totalBids}</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography variant="subtitle2" sx={{ minWidth: 160 }}>
                Promotion Status:
              </Typography>
              <Typography>
                {report.promotionEnabled ? "Active" : "Inactive"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography variant="subtitle2" sx={{ minWidth: 160 }}>
                Report Generated:
              </Typography>
              <Typography>
                {new Date(report.generatedAt).toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Paper>

        <DialogActions sx={{ px: 0, pb: 0 }}>
          <Button onClick={onClose} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AuctionReportModal;
