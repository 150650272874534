import React, { useState } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useAuth } from "../../../hooks/useAuth";
import { db } from "../../../services/firebase";
import { LoadingButton } from "@mui/lab";

const PromotionButton = ({ zipCode, hasBid = false }) => {
  const { userData, zipPromotions } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  // Get promotion status from context
  const promotionData = zipPromotions?.[zipCode];
  const promotionStatus = promotionData?.status || null;

  const handlePromotionRequest = async () => {
    if (!userData?.uid) return;

    setIsLoading(true);
    try {
      // Create a batch operation
      const batch = db.batch();

      // Reference to the promotion in the zips collection
      const zipPromotionRef = db
        .collection("zips")
        .doc(zipCode)
        .collection("promotions")
        .doc(userData.uid);

      // Reference to the promotion in the user's collection
      const userPromotionRef = db
        .collection("users")
        .doc(userData.uid)
        .collection("zip_promotions")
        .doc(zipCode);

      // Promotion data
      const promotionData = {
        status: "pending",
        active: false,
        userId: userData.uid,
        zipCode: zipCode,
        createdAt: new Date().toISOString(),
        userInfo: {
          id: userData.uid,
          email: userData.email || null,
        },
      };

      // Add both documents in a batch
      batch.set(zipPromotionRef, promotionData);
      batch.set(userPromotionRef, promotionData);

      // Commit the batch
      await batch.commit();

      // Close the dialog
      setShowConfirmDialog(false);
    } catch (error) {
      console.error("Error creating promotion:", error);
      alert("Failed to create promotion. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelPromotion = async () => {
    if (!userData?.uid || hasBid) return;

    setIsLoading(true);
    try {
      // Create a batch operation
      const batch = db.batch();

      // Reference to the promotion in the zips collection
      const zipPromotionRef = db
        .collection("zips")
        .doc(zipCode)
        .collection("promotions")
        .doc(userData.uid);

      // Reference to the promotion in the user's collection
      const userPromotionRef = db
        .collection("users")
        .doc(userData.uid)
        .collection("zip_promotions")
        .doc(zipCode);

      // Delete both documents in a batch
      batch.delete(zipPromotionRef);
      batch.delete(userPromotionRef);

      // Commit the batch
      await batch.commit();

      // Close the dialog
      setShowCancelDialog(false);
    } catch (error) {
      console.error("Error canceling promotion:", error);
      alert("Failed to cancel promotion. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Render different UI based on promotion status
  if (promotionStatus === "active") {
    return (
      <Button
        variant="contained"
        color="success"
        size="large"
        disabled={true}
        startIcon={hasBid ? <LockIcon /> : null}
      >
        Active
      </Button>
    );
  }

  if (promotionStatus === "invoiced" || promotionStatus === "payment_pending") {
    return (
      <Button
        variant="contained"
        color="warning"
        size="large"
        disabled={true}
        startIcon={hasBid ? <LockIcon /> : null}
      >
        Payment Pending
      </Button>
    );
  }

  if (promotionStatus === "pending") {
    return (
      <>
        <Button
          variant="contained"
          color="success"
          size="large"
          disabled={hasBid}
          onClick={() => !hasBid && setShowCancelDialog(true)}
          startIcon={hasBid ? <LockIcon /> : null}
        >
          Pending
        </Button>

        <Dialog
          open={showCancelDialog}
          onClose={() => !isLoading && setShowCancelDialog(false)}
        >
          <DialogTitle>Cancel Promotion</DialogTitle>
          <DialogContent>
            <DialogContentText variant="body2" sx={{ pb: 3 }}>
              Are you sure you want to cancel your promotion for ZIP code{" "}
              {zipCode}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowCancelDialog(false)}
              disabled={isLoading}
            >
              No, Keep It
            </Button>
            <LoadingButton
              onClick={handleCancelPromotion}
              color="error"
              variant="contained"
              loading={isLoading}
              disabled={isLoading}
            >
              Yes, Cancel
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  // Default: No promotion
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => setShowConfirmDialog(true)}
      >
        Place Promotion
      </Button>

      <Dialog
        open={showConfirmDialog}
        onClose={() => !isLoading && setShowConfirmDialog(false)}
      >
        <DialogTitle>Confirm Promotion</DialogTitle>
        <DialogContent>
          <DialogContentText variant="body2" sx={{ pb: 3 }}>
            Placing a promotion for ZIP code {zipCode} will cost $100 per month.
            You will receive an invoice after the auction ends. Do you want to
            continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowConfirmDialog(false)}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handlePromotionRequest}
            color="primary"
            variant="contained"
            loading={isLoading}
            disabled={isLoading}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PromotionButton;
