import React, { useEffect, useState } from "react";
import { Alert, Box, Link, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "hooks/useAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const EmailConfirmation = () => {
  const { userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [dbError, setDbError] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);

  useEffect(() => {
    // First, check if the user is already verified
    const currentUser = firebase.auth().currentUser;
    if (currentUser?.emailVerified) {
      window.location.reload();
      return;
    }

    // Set up an auth state observer
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // Force reload user data to ensure we have the latest emailVerified status
        await user.reload();

        if (user.emailVerified) {
          window.location.reload();
          return;
        }

        // Set up an ID token listener which will trigger on email verification
        const unsubscribeToken = firebase
          .auth()
          .onIdTokenChanged(async (user) => {
            if (user?.emailVerified) {
              window.location.reload();
            }
          });

        return () => unsubscribeToken();
      }
    });

    return () => unsubscribe();
  }, []);

  const resendEmail = async () => {
    setLoading(true);
    setSubmitted(false);
    setDbError(null);

    try {
      const user = firebase.auth().currentUser;
      if (!user) {
        throw new Error("No user is currently signed in");
      }

      await user.sendEmailVerification();
      setSubmitted(true);
      setStatusMessage("Your confirmation email has been resent.");
    } catch (error) {
      setDbError(error.message);
      setStatusMessage("Could not resend email. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const getWelcomeMessage = () => {
    if (!userData?.role || userData.role === "facility") {
      return "Your application has been submitted and we've just sent you a confirmation email.";
    }
    return "We've just sent you a confirmation email.";
  };

  return (
    <>
      <Typography variant="h4" sx={{ mt: { xs: 4, sm: 10 }, mb: 2 }}>
        Confirm your email
      </Typography>

      {submitted && !dbError && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {statusMessage}
        </Alert>
      )}

      {dbError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {statusMessage}
        </Alert>
      )}

      <Typography component="div">
        <p>
          {getWelcomeMessage()} Please verify your email address by clicking the
          link in the email.
        </p>

        <p>
          If you haven't received your email after a few minutes, and it's not
          in your spam folder, click to resend your email below.
        </p>
      </Typography>

      <LoadingButton
        loading={loading}
        variant="contained"
        disabled={loading}
        onClick={resendEmail}
      >
        Resend Confirmation Email
      </LoadingButton>

      <Box sx={{ mt: 2 }}>
        <Link
          underline="none"
          href="mailto:support@hlthdsk.com?subject=Confirmation email not being delivered"
        >
          Having problems registering? Please contact us.
        </Link>
      </Box>
    </>
  );
};

export default EmailConfirmation;
