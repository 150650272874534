import React, { useState, useEffect, useContext } from "react";
import DynamicMap from "components/dashboard/auction/DynamicMap";
import SelectedZipsTable from "components/dashboard/auction/SelectedZipsTable";
import SelectionInstructionModal from "components/dashboard/auction/SelectionInstructionModal";
import { createCustomIcon } from "components/dashboard/auction/utils/mapUtils";
import { MAP_SETTINGS } from "components/dashboard/auction/constants/mapSettings";
import { Box, Typography, CircularProgress, Alert, Link } from "@mui/material";
import { db } from "services/firebase";
import { useAuth } from "hooks/useAuth";
import { LocationContext } from "context/Location";
import InfoBox from "components/common/InfoBox";
import { CampaignRounded } from "@mui/icons-material";
import MarketingSettings from "components/dashboard/advertising/MarketingSettings";
import AddressSettings from "components/dashboard/AddressSettings";
import DebugControls from "components/dashboard/auction/DebugControls";

const AuctionPage = () => {
  const { userData, zipSubscriptions, zipPromotions } = useAuth();
  const { location } = useContext(LocationContext);
  const [selectedZips, setSelectedZips] = useState(new Set());
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const [hasShownModalThisSession, setHasShownModalThisSession] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [marketingOpen, setMarketingOpen] = useState(false);
  const [addressOpen, setAddressOpen] = useState(false);
  const [submitted, setSubmitted] = useState();

  const icons = {
    selected: createCustomIcon(MAP_SETTINGS.colors.selected),
    unselected: createCustomIcon(MAP_SETTINGS.colors.primary),
  };

  // Load user's bid zips on initial mount
  useEffect(() => {
    const loadUserBids = async () => {
      if (!userData?.uid) return;

      try {
        setIsLoading(true);
        // Query auctions collection for documents where the user has bid
        const auctionsSnapshot = await db
          .collection("auctions")
          .where("lastBidder", "==", userData.uid)
          .get();

        // Get historical bids where user participated but isn't current winner
        const historicalSnapshot = await db
          .collection("auctions")
          .where("bidHistory", "array-contains", {
            bidderId: userData.uid,
            amount: 0, // This will match any number since we're just checking existence
            timestamp: null, // This will match any timestamp
          })
          .get();

        // Combine results
        const userBidZips = new Set();
        auctionsSnapshot.forEach((doc) => userBidZips.add(doc.id));
        historicalSnapshot.forEach((doc) => userBidZips.add(doc.id));

        // Add zip codes from subscriptions and promotions
        Object.keys(zipSubscriptions).forEach((zipCode) =>
          userBidZips.add(zipCode)
        );
        Object.keys(zipPromotions).forEach((zipCode) =>
          userBidZips.add(zipCode)
        );

        setSelectedZips(userBidZips);
      } catch (error) {
        console.error("Error loading user bids:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadUserBids();
  }, [userData?.uid, zipSubscriptions, zipPromotions]);

  // Show modal when zips are first selected
  useEffect(() => {
    if (selectedZips.size > 0 && !hasShownModalThisSession) {
      setShowInstructionModal(true);
      setHasShownModalThisSession(true);
    }
  }, [selectedZips.size, hasShownModalThisSession]);

  // Add this debugging useEffect to track when selectedZips changes
  useEffect(() => {
    console.log("selectedZips changed:", {
      selectedZipsArray: Array.from(selectedZips),
      selectedZipsSize: selectedZips.size,
      stack: new Error().stack, // This will show us where the change is coming from
    });
  }, [selectedZips]);

  // Modify the existing useEffect for zipSubscriptions
  useEffect(() => {
    if (zipSubscriptions && Object.keys(zipSubscriptions).length > 0) {
      console.log("Adding zipSubscriptions to selectedZips:", {
        existingSelectedZips: Array.from(selectedZips),
        zipSubscriptionsKeys: Object.keys(zipSubscriptions),
      });

      // Create a new Set that includes both existing selectedZips and zipSubscriptions
      const combinedZips = new Set([
        ...Array.from(selectedZips),
        ...Object.keys(zipSubscriptions),
      ]);

      console.log(
        "Setting selectedZips directly with combined set:",
        Array.from(combinedZips)
      );

      // Only update if there's an actual change needed
      if (
        combinedZips.size !== selectedZips.size ||
        !Array.from(selectedZips).every((zip) => combinedZips.has(zip))
      ) {
        setSelectedZips(combinedZips);
      }
    }
  }, [zipSubscriptions, selectedZips]); // Add selectedZips as a dependency

  const handleModalClose = () => {
    setShowInstructionModal(false);
  };

  const handleZipToggle = (zipCode) => {
    console.log(`handleZipToggle called for zip code ${zipCode}`, {
      isInSelectedZips: selectedZips.has(zipCode),
      isSubscription: zipSubscriptions && zipSubscriptions[zipCode],
    });

    setSelectedZips((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(zipCode)) {
        // Check if this zip is from zipSubscriptions
        const isSubscription = zipSubscriptions && zipSubscriptions[zipCode];

        // Only allow removal if it's not a subscription zip
        if (!isSubscription) {
          console.log(`Removing non-subscription zip ${zipCode}`);
          newSelected.delete(zipCode);
        } else {
          console.log(
            `Zip code ${zipCode} is a subscription and cannot be removed`
          );
          // You could add a toast notification here if desired
        }
      } else {
        console.log(`Adding zip ${zipCode} to selected zips`);
        newSelected.add(zipCode);
      }

      console.log("New selected zips:", Array.from(newSelected));
      return newSelected;
    });
  };

  const handleCircleSelection = (zipCodes) => {
    setSelectedZips((prevSelected) => {
      const newSelected = new Set(prevSelected);
      zipCodes.forEach((zipCode) => {
        if (typeof zipCode === "string" || typeof zipCode === "number") {
          newSelected.add(String(zipCode));
        }
      });
      return newSelected;
    });
  };

  const handleRemoveZip = (zipCode) => {
    setSelectedZips((prevSelected) => {
      const newSelected = new Set(prevSelected);
      newSelected.delete(zipCode);
      return newSelected;
    });
  };

  const switchToAddress = () => {
    setAddressOpen(true);
  };

  const switchToBranding = () => {
    setMarketingOpen(true);
  };

  // Check if requirements are met
  const requirementsMet =
    location?.branding?.logo && location?.branding?.website && location?.group;

  return (
    <Box>
      <Box sx={{ mb: 5 }}>
        <Typography
          variant="h4"
          component="h2"
          sx={{ mt: { xs: 1, sm: 5 }, mb: 2 }}
        >
          Advertise
        </Typography>

        {submitted ? (
          <Alert severity="success" sx={{ mb: 3 }}>
            {submitted}
          </Alert>
        ) : null}

        <Typography
          variant="body1"
          sx={{ display: "block", maxWidth: "800px", mb: 3 }}
        >
          Enhance your facility's visibility to local patients seeking care.
          Select ZIP codes you want to advertise in to get started. Top bidders
          secure priority positions in search results and the promotional
          carousel, maximizing exposure to potential patients in your area.
        </Typography>
      </Box>

      {!requirementsMet ? (
        <Box>
          {!location?.group && (
            <Alert severity="error" sx={{ mb: 2 }}>
              To advertise your facilities, please{" "}
              <Link
                color="inherit"
                sx={{ cursor: "pointer" }}
                onClick={switchToAddress}
              >
                enter the name of your Facility Group
              </Link>
              .
            </Alert>
          )}

          {(!location?.branding?.logo || !location?.branding?.website) && (
            <Alert severity="error" sx={{ mb: 2 }}>
              Your advertising will not show until you add your branding. Please{" "}
              <Link
                color="inherit"
                sx={{ cursor: "pointer" }}
                onClick={switchToBranding}
              >
                add your branding
              </Link>
              .
            </Alert>
          )}
        </Box>
      ) : (
        <Box sx={{ position: "relative" }}>
          {isLoading && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <Box sx={{ opacity: isLoading ? 0.5 : 1 }}>
            <DynamicMap
              selectedZips={selectedZips}
              onZipToggle={handleZipToggle}
              onCircleSelect={handleCircleSelection}
              icons={icons}
            />
            {selectedZips.size > 0 && (
              <SelectedZipsTable
                selectedZips={selectedZips}
                onRemoveZip={handleRemoveZip}
              />
            )}
          </Box>

          {/* <SelectionInstructionModal
            isOpen={showInstructionModal}
            onClose={handleModalClose}
          /> */}
        </Box>
      )}

      {/* Branding InfoBox */}
      <Box sx={{ mt: 2 }}>
        <InfoBox
          icon={<CampaignRounded fontSize="large" sx={{ color: "#117aca" }} />}
          title="Branding"
          description="Edit the logo and website link that will be shown in your ads."
          links={[
            {
              title: "Edit Branding",
              onClick: switchToBranding,
            },
          ]}
        />
      </Box>

      {/* Settings Dialogs */}
      <MarketingSettings
        user={userData}
        data={userData}
        location={location}
        visible={marketingOpen}
        close={() => setMarketingOpen(false)}
        setSubmitted={setSubmitted}
      />

      <AddressSettings
        user={userData}
        data={userData}
        location={location}
        visible={addressOpen}
        close={() => setAddressOpen(false)}
        setSubmitted={setSubmitted}
      />

      {/* Debug Controls - only visible in non-production environments */}
      <DebugControls />
    </Box>
  );
};

export default AuctionPage;
