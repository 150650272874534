import React from "react";
import { Box } from "@mui/material";
import { MessageInput } from "@chatscope/chat-ui-kit-react";
import SendButton from "./SendButton";

const UnauthenticatedMessageInput = ({
  inputValue,
  setInputValue,
  handleSendRequest,
  rateLimited,
  limit,
}) => {
  const upgradeMessage = `You have reached your daily limit of ${limit} messages. Please sign up for increased access.`;

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 999,
        height: 50,
        maxWidth: 970,
        margin: "0 auto",
      }}
    >
      <MessageInput
        disabled={rateLimited}
        placeholder={rateLimited ? upgradeMessage : "Message HLTHDSK"}
        attachButton={false}
        sendButton={false}
        onSend={handleSendRequest}
        value={inputValue}
        onChange={setInputValue}
        style={{
          marginRight: "40px",
          width: "calc(100% - 50px)",
        }}
      />

      <Box>
        <SendButton
          inputValue={inputValue}
          handleSendRequest={handleSendRequest}
        />
      </Box>
    </Box>
  );
};

export default UnauthenticatedMessageInput;
