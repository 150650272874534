import React from "react";
import reactStringReplace from "react-string-replace";
import { diseases, medications } from "../data";
import { useContext } from "react";
import { KijabeChatContext } from "context/KijabeChat";

export const useKijabeMessageParser = (openTab) => {
  const { setKeyword, setDescOpen } = useContext(KijabeChatContext);
  const medicationsList = new RegExp(`(${medications.join("|")})`, "gi");
  const diseasesList = new RegExp(`(${diseases.join("|")})`, "gi");
  const bold = /\*\*(.*?)\*\*/gm;

  const openDesc = (tab, match) => {
    // Commented out to prevent opening tabs
    // openTab(tab);
    // setTimeout(() => {
    //   setKeyword(match);
    //   setDescOpen(tab);
    // }, 50);

    // Do nothing
    console.log("Tab opening disabled:", tab, match);
  };

  const reactStringReplaceRecursive = (input, pattern, fn, key = 0) => {
    const isEmpty = (item) => {
      if (!item) return true;
      if (item.hasOwnProperty("props")) {
        return false;
      } else {
        return item.length ? false : true;
      }
    };

    if (!input) {
      return null;
    } else if (typeof input === "string") {
      return reactStringReplace(input, pattern, fn);
    }

    var output = [];
    for (var i = 0; i < input.length; i++) {
      if (isEmpty(input[i])) {
        continue;
      }

      if (typeof input[i] === "string") {
        var replaced = reactStringReplace(input[i], pattern, fn);
        for (var j = 0; j < replaced.length; j++) {
          output.push(replaced[j]);
        }
      } else if (input[i].props && input[i].props.children) {
        var children = reactStringReplaceRecursive(
          input[i].props.children,
          pattern,
          fn,
          i
        );
        output.push(
          React.cloneElement(
            input[i],
            { ...input[i].props, key: `${key}_${i}` },
            children
          )
        );
      } else {
        output.push(input[i]);
      }
    }
    return output;
  };

  const parseMessage = (message) => {
    let parsedText = message.message;

    // Keep only basic formatting like headers and bold text
    parsedText = reactStringReplaceRecursive(
      parsedText,
      /#### (.*?)\n/gm,
      (match, k) => (
        <h4 style={{ margin: "10px 0 15px" }} key={`k_${k}`}>
          {match}
        </h4>
      )
    );

    parsedText = reactStringReplaceRecursive(
      parsedText,
      /### (.*?)\n/gm,
      (match, k) => (
        <h3 style={{ marginBottom: "10px" }} key={`k_${k}`}>
          {match}
        </h3>
      )
    );

    parsedText = reactStringReplaceRecursive(parsedText, bold, (match, i) => (
      <strong key={`i_${i}`}>{match}</strong>
    ));

    // Remove the disease and medication parsing completely

    return parsedText;
  };

  return { parseMessage };
};
