import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  Alert,
  Container,
  MobileStepper,
  FormHelperText,
  Autocomplete,
  Paper,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import firebaseApp, { db } from "services/firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { useForm, Controller } from "react-hook-form";
import {
  ChatRounded,
  CheckCircleOutlineRounded,
  LocalHospitalRounded,
  MedicalInformationRounded,
  MedicalServicesRounded,
  PersonAddRounded,
} from "@mui/icons-material";
import { formValidation } from "validation/formValidation";
import useSearch from "hooks/useSearch";
import { getAddress } from "utils/helpers/getAddress";
import EmergencySearch from "components/EmergencySearch";
import * as geohash from "ngeohash";
import { useAuth } from "hooks/useAuth";
import { getStateCode } from "utils/helpers/getStateCode";

const Onboarding = () => {
  const { user, userData, userLoading } = useAuth();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [needEmail, setNeedEmail] = useState(false);
  const [continueUrl, setContinueUrl] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const address = useSearch("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      facility: "",
      location: "",
      address: null,
    },
  });

  const watchFacility = watch("facility", "");

  // Parse URL parameters and determine initial state
  useEffect(() => {
    console.log("Onboarding component mounted");

    // Wait for auth to complete
    if (userLoading) {
      return;
    }

    const params = new URLSearchParams(location.search);
    const roleParam = params.get("role");

    // Check if we need email from location state or URL param
    const needsEmail =
      params.get("needEmail") === "true" || location.state?.needEmail === true;

    console.log("URL params:", { roleParam, needsEmail });
    console.log("Auth state:", { user: user?.uid, userLoading });

    // Set role from URL parameter if available
    if (roleParam) {
      console.log("Setting role from URL parameter:", roleParam);
      setRole(roleParam);
    }

    // Set need email flag
    if (needsEmail) {
      console.log("Email collection needed");
      setNeedEmail(true);
    }

    // If user is authenticated
    if (user) {
      console.log("User is authenticated:", user.uid);

      // Check if user document exists
      const checkUserDocument = async () => {
        try {
          // If user is already in onboarding flow, don't reset steps
          if (userData?.role && userData.onboarding) {
            console.log("User is already in onboarding flow");
            setLoading(false);
            return;
          }

          // If userData is already available from useAuth, use it
          if (userData) {
            if (userData.role && !userData.onboarding) {
              console.log(
                "User document exists with role, redirecting to dashboard"
              );
              navigate("/dashboard");
              return;
            }
          } else {
            // Otherwise check Firestore directly
            const userDoc = await db.collection("users").doc(user.uid).get();
            if (
              userDoc.exists &&
              userDoc.data().role &&
              !userDoc.data().onboarding
            ) {
              console.log(
                "User document exists with role, redirecting to dashboard"
              );
              navigate("/dashboard");
              return;
            }
          }

          // User exists but needs onboarding
          console.log("User exists but needs onboarding");
          setEmail(user.email);
          setValue("email", user.email);

          // If we have a user with email but no role, start at role selection
          if (!roleParam) {
            setActiveStep(0); // Start at role selection (step 0)
          } else {
            setActiveStep(1); // Skip to user info (step 1)
          }

          setLoading(false);
        } catch (error) {
          console.error("Error checking user document:", error);
          setError("Failed to check user account. Please try again.");
          setLoading(false);
        }
      };

      checkUserDocument();
    } else {
      // No authenticated user
      console.log("No authenticated user");

      // Check if this is a sign-in link
      if (firebaseApp.auth().isSignInWithEmailLink(window.location.href)) {
        console.log("This is a sign-in link");

        // Get email from localStorage
        const storedEmail = localStorage.getItem("emailForSignIn");
        if (storedEmail) {
          console.log("Found email in localStorage:", storedEmail);
          setEmail(storedEmail);
          setValue("email", storedEmail);
        } else {
          console.log("No email found in localStorage, need to collect it");
          setNeedEmail(true);
        }

        setLoading(false);
      } else {
        // Not a sign-in link and no authenticated user
        console.log("Not a sign-in link and no authenticated user");

        // If we have a role, show the form even without a user
        if (roleParam) {
          setActiveStep(1); // Start at user info if we have a role
          setLoading(false);
        } else {
          // No role and no user, redirect to login
          console.log("No role and no user, redirecting to login");
          navigate("/login", {
            state: {
              message: "Please sign in to continue with registration.",
            },
          });
        }
      }
    }
  }, [navigate, location, setValue, user, userData, userLoading]);

  // Handle email submission and sign-in
  const handleEmailSubmit = async (data) => {
    setSubmitLoading(true);
    try {
      console.log("Attempting to sign in with email:", data.email);

      // Store email in localStorage
      localStorage.setItem("emailForSignIn", data.email);

      // Use the stored continueUrl if it exists, otherwise use the current URL
      const signInUrl = continueUrl || window.location.href;
      console.log("Using sign-in URL:", signInUrl);

      // Complete the sign-in process
      const result = await firebaseApp
        .auth()
        .signInWithEmailLink(data.email, signInUrl);

      const user = result.user;
      console.log("User signed in:", user.uid);

      // Set user and email state
      setEmail(user.email);
      setValue("email", user.email);

      // Store registration flag
      localStorage.setItem("isNewRegistration", "true");

      // Continue with form submission (this also handles the case where we need to collect name)
      await handleUserInfoSubmit(data);
    } catch (error) {
      console.error("Error signing in with email:", error);
      setError(
        error.message || "Failed to sign in with this email. Please try again."
      );
      setSubmitLoading(false);
    }
  };

  // Handle user info submission
  const handleUserInfoSubmit = async (data) => {
    console.log("Form submitted with data:", data);
    setSubmitLoading(true);
    try {
      // If we need email and don't have a user yet, we need to sign in first
      if (needEmail && !user) {
        console.log("Need to sign in with email first");

        // Store email in localStorage
        localStorage.setItem("emailForSignIn", data.email);

        // Use the stored continueUrl if it exists, otherwise use the current URL
        const signInUrl = continueUrl || window.location.href;
        console.log("Using sign-in URL:", signInUrl);

        try {
          // Complete the sign-in process
          const result = await firebaseApp
            .auth()
            .signInWithEmailLink(data.email, signInUrl);

          // Set user and email state
          setEmail(result.user.email);
        } catch (error) {
          console.error("Error signing in with email:", error);
          setError(
            error.message ||
              "Failed to sign in with this email. Please try again."
          );
          setSubmitLoading(false);
          return;
        }
      }

      // Use the user from useAuth instead of currentUser
      if (!user) {
        throw new Error("Unable to authenticate user. Please try again.");
      }

      console.log("User info submitted for:", user.uid);

      // If this is a facility registration, move to facility type step
      if (role === "facility") {
        setActiveStep(2); // Move to facility type step
        setSubmitLoading(false);
        return;
      }

      // For non-facility users, create user document and finish
      await db.collection("users").doc(user.uid).set({
        uid: user.uid,
        email: user.email,
        name: data.firstName,
        lastName: data.lastName,
        role: role,
        registrationDate: firebase.firestore.FieldValue.serverTimestamp(),
        onboarding: true,
      });

      console.log("User document created successfully");

      // Clear the registration flag
      localStorage.removeItem("isNewRegistration");

      // Move to success step for non-facility users
      setActiveStep(4);
      setSubmitLoading(false);
    } catch (error) {
      console.error("Error creating user document:", error);
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  // Handle facility type selection
  const handleFacilityTypeSubmit = async (data) => {
    console.log("Facility type selected:", data.facility);
    setActiveStep(3); // Move to facility details step
  };

  // Handle facility info submission
  const handleFacilityInfoSubmit = async (data) => {
    console.log("Facility info submitted:", data);
    setSubmitLoading(true);
    try {
      // Use the user from useAuth instead of currentUser
      if (!user) {
        throw new Error("Unable to authenticate user. Please try again.");
      }

      const facilityType = data.facility;
      const locationId =
        facilityType === "hospital"
          ? String(data.emergency_location?.id)
          : db.collection("locations").doc().id;

      // Create batch for Firestore operations
      const batch = db.batch();

      // Set up user document with user.uid
      const userRef = db.collection("users").doc(user.uid);
      batch.set(userRef, {
        uid: user.uid,
        email: user.email,
        name: data.firstName,
        lastName: data.lastName,
        role: "facility",
        location: locationId,
        registrationDate: firebase.firestore.FieldValue.serverTimestamp(),
        onboarding: true,
      });

      // Handle location data
      const locationsRef = db.collection("locations").doc(locationId);

      if (facilityType === "hospital") {
        batch.update(locationsRef, {
          users: firebase.firestore.FieldValue.arrayUnion(user.uid),
        });
      } else if (facilityType === "urgent-care") {
        const addressData = data.address;
        const lookup = (name) =>
          addressData.context.find((a) => a?.id.startsWith(name))?.text;

        const formattedAddress = getAddress(addressData);
        const latitude = addressData.center[1];
        const longitude = addressData.center[0];
        const geopoint = new firebase.firestore.GeoPoint(latitude, longitude);
        const hash = geohash.encode(latitude, longitude);

        batch.set(locationsRef, {
          id: locationId,
          title: data.location,
          lat: latitude,
          lng: longitude,
          address: formattedAddress,
          city: lookup("place"),
          state: getStateCode(addressData),
          zip: lookup("postcode"),
          type: "Clinic",
          users: firebase.firestore.FieldValue.arrayUnion(user.uid),
          pending: true,
          coordinates: geopoint,
          g: {
            geohash: hash,
            geopoint,
          },
        });
      }

      // Commit all Firestore changes
      await batch.commit();

      console.log("Facility document created successfully");

      // Clear the registration flag
      localStorage.removeItem("isNewRegistration");
      localStorage.removeItem("emailForSignIn");
      localStorage.removeItem("pendingUserData");
      localStorage.removeItem("wasRegistration");

      // Move to success step
      setActiveStep(4); // Success step is always 4
    } catch (error) {
      console.error("Error creating facility document:", error);
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  // Handle role selection
  const handleRoleSelect = (data) => {
    console.log("Role selected:", data.role);
    // Set the role state
    setRole(data.role);

    // After selecting role, move to user info step
    setActiveStep(1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  // Facility type selection component
  const FacilityTypeSelector = ({ value, onChange }) => {
    return (
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            onClick={() => onChange("hospital")}
            sx={{
              p: 3,
              textAlign: "center",
              cursor: "pointer",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor:
                value === "hospital" ? "primary.main" : "transparent",
              backgroundColor:
                value === "hospital" ? "rgba(25, 118, 210, 0.08)" : "white",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                backgroundColor: "rgba(25, 118, 210, 0.08)",
              },
            }}
          >
            <LocalHospitalRounded
              sx={{ fontSize: 48, color: "primary.main", mb: 2 }}
            />
            <Typography variant="h6" component="div">
              Emergency Department
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Must be open 24 hours
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            onClick={() => onChange("urgent-care")}
            sx={{
              p: 3,
              textAlign: "center",
              cursor: "pointer",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor:
                value === "urgent-care" ? "primary.main" : "transparent",
              backgroundColor:
                value === "urgent-care" ? "rgba(25, 118, 210, 0.08)" : "white",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                backgroundColor: "rgba(25, 118, 210, 0.08)",
              },
            }}
          >
            <MedicalInformationRounded
              sx={{ fontSize: 48, color: "primary.main", mb: 2 }}
            />
            <Typography variant="h6" component="div">
              Clinic/Immediate Care
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Urgent care facilities
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  // Account type selection component
  const RoleSelector = ({ value, onChange }) => {
    return (
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            "@media (min-width: 960px)": {
              width: "33.333%",
              flexBasis: "33.333%",
              maxWidth: "33.333%",
            },
          }}
        >
          <Paper
            elevation={3}
            onClick={() => onChange("patient")}
            sx={{
              p: 3,
              textAlign: "center",
              cursor: "pointer",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: value === "patient" ? "primary.main" : "transparent",
              backgroundColor:
                value === "patient" ? "rgba(25, 118, 210, 0.08)" : "white",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                backgroundColor: "rgba(25, 118, 210, 0.08)",
              },
            }}
          >
            <ChatRounded sx={{ fontSize: 48, color: "primary.main", mb: 2 }} />
            <Typography variant="h6" component="div">
              Individual Account
            </Typography>
            <Typography variant="body2" color="text.secondary">
              From symptoms to solutions, get educated and find the best care
              available.
            </Typography>
          </Paper>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            "@media (min-width: 960px)": {
              width: "33.333%",
              flexBasis: "33.333%",
              maxWidth: "33.333%",
            },
          }}
        >
          <Paper
            elevation={3}
            onClick={() => onChange("professional")}
            sx={{
              p: 3,
              textAlign: "center",
              cursor: "pointer",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor:
                value === "professional" ? "primary.main" : "transparent",
              backgroundColor:
                value === "professional" ? "rgba(25, 118, 210, 0.08)" : "white",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                backgroundColor: "rgba(25, 118, 210, 0.08)",
              },
            }}
          >
            <MedicalServicesRounded
              sx={{ fontSize: 48, color: "primary.main", mb: 2 }}
            />
            <Typography variant="h6" component="div">
              Professional Account
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Get fast, dependable insights tailored to clinical details you
              provide.
            </Typography>
          </Paper>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            "@media (min-width: 960px)": {
              width: "33.333%",
              flexBasis: "33.333%",
              maxWidth: "33.333%",
            },
          }}
        >
          <Paper
            elevation={3}
            onClick={() => onChange("facility")}
            sx={{
              p: 3,
              textAlign: "center",
              cursor: "pointer",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor:
                value === "facility" ? "primary.main" : "transparent",
              backgroundColor:
                value === "facility" ? "rgba(25, 118, 210, 0.08)" : "white",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                backgroundColor: "rgba(25, 118, 210, 0.08)",
              },
            }}
          >
            <LocalHospitalRounded
              sx={{ fontSize: 48, color: "primary.main", mb: 2 }}
            />
            <Typography variant="h6" component="div">
              Facility Account
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Streamline patient flow and improve visibility in the community.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  // Helper function to render the correct title based on step
  const renderStepTitle = () => {
    if (activeStep === 0) {
      return "Choose Your Account Type";
    } else if (activeStep === 4) {
      return "You're All Set";
    } else {
      return "Welcome to HLTHDSK";
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: "100vh",
        py: 4,
        paddingTop: "10vh",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Different header for role selection step */}
        {activeStep === 0 ? (
          <>
            <PersonAddRounded
              sx={{
                fontSize: 64,
                color: "primary.main",
                mb: 2,
              }}
            />
            <Typography variant="h4" sx={{ mb: 4, textAlign: "center" }}>
              {renderStepTitle()}
            </Typography>
          </>
        ) : (
          <>
            {activeStep === 4 ? (
              <CheckCircleOutlineRounded
                sx={{
                  fontSize: 64,
                  color: "primary.main",
                  mb: 2,
                }}
              />
            ) : (
              <MedicalServicesRounded
                sx={{
                  fontSize: 64,
                  color: "primary.main",
                  mb: 2,
                }}
              />
            )}
            <Typography variant="h4" sx={{ mb: 4, textAlign: "center" }}>
              {renderStepTitle()}
            </Typography>
          </>
        )}

        {error && (
          <Alert severity="error" sx={{ mb: 3, width: "100%" }}>
            {error}
          </Alert>
        )}

        {/* Step 0: Role Selection */}
        {activeStep === 0 && (
          <>
            <Typography variant="body1" sx={{ mb: 3, textAlign: "center" }}>
              Select how you'll use HLTHDSK.
            </Typography>

            <form
              onSubmit={handleSubmit(handleRoleSelect)}
              style={{ width: "100%" }}
            >
              <Box
                sx={{
                  position: "relative",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "100%",
                  "@media (min-width: 960px)": {
                    width: "900px",
                  },
                  maxWidth: "100vw",
                }}
              >
                <Controller
                  name="role"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Please select an account type." }}
                  render={({ field: { onChange, value } }) => (
                    <RoleSelector value={value} onChange={onChange} />
                  )}
                />
              </Box>
              {errors?.role && (
                <FormHelperText
                  error={true}
                  sx={{ textAlign: "center", mt: 2 }}
                >
                  {errors.role.message}
                </FormHelperText>
              )}

              <LoadingButton
                type="submit"
                variant="contained"
                loading={submitLoading}
                size="large"
                sx={{
                  mt: 4,
                  minWidth: "120px",
                  width: "100%",
                }}
                disabled={!watch("role")}
              >
                Continue
              </LoadingButton>
            </form>
          </>
        )}

        {/* Step 1: User Info (and Email if needed) */}
        {activeStep === 1 && (
          <>
            <Typography variant="body1" sx={{ mb: 3, textAlign: "center" }}>
              {role === "facility"
                ? "Let's get started with your facility registration. First, please provide your name."
                : "You're almost done. Please provide your name to complete your registration."}
            </Typography>

            <form
              onSubmit={handleSubmit(
                needEmail ? handleEmailSubmit : handleUserInfoSubmit
              )}
              style={{ width: "100%" }}
            >
              {/* Only show email field if needEmail is true */}
              {needEmail && (
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email Address"
                      margin="normal"
                      variant="standard"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
              )}

              <Box sx={{ display: "flex", gap: 2 }}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: "First name is required." }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="First Name"
                      fullWidth
                      margin="normal"
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                    />
                  )}
                />

                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: "Last name is required." }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      fullWidth
                      margin="normal"
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                    />
                  )}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: !location.search.includes("role=")
                    ? "space-between"
                    : "flex-end",
                  mt: 4,
                }}
              >
                {!location.search.includes("role=") && (
                  <LoadingButton
                    variant="text"
                    onClick={handleBack}
                    size="large"
                  >
                    Back
                  </LoadingButton>
                )}
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    minWidth: "120px",
                  }}
                  loading={submitLoading}
                >
                  {role === "facility" ? "Next" : "Complete Registration"}
                </LoadingButton>
              </Box>
            </form>
          </>
        )}

        {/* Step 2: Facility Type (only for facility role) */}
        {activeStep === 2 && role === "facility" && (
          <>
            <Typography variant="body1" sx={{ mb: 3, textAlign: "center" }}>
              What type of facility are you registering?
            </Typography>

            <form
              onSubmit={handleSubmit(handleFacilityTypeSubmit)}
              style={{ width: "100%" }}
            >
              <Controller
                name="facility"
                control={control}
                defaultValue=""
                rules={formValidation.facility}
                render={({ field: { onChange, value } }) => (
                  <FacilityTypeSelector value={value} onChange={onChange} />
                )}
              />
              {errors?.facility && (
                <FormHelperText
                  error={true}
                  sx={{ textAlign: "center", mt: 2 }}
                >
                  {errors.facility.message}
                </FormHelperText>
              )}

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}
              >
                <LoadingButton
                  variant="text"
                  onClick={handleBack}
                  size="large"
                  sx={{ minWidth: "120px", pl: 0 }}
                >
                  Back
                </LoadingButton>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={submitLoading}
                  size="large"
                  sx={{ minWidth: "120px" }}
                  disabled={!watchFacility}
                >
                  Next
                </LoadingButton>
              </Box>
            </form>
          </>
        )}

        {/* Step 3: Facility Details (only for facility role) */}
        {activeStep === 3 && role === "facility" && (
          <>
            <Typography variant="body1" sx={{ mb: 3, textAlign: "center" }}>
              Please provide the name and address of your facility.
            </Typography>

            <form
              onSubmit={handleSubmit(handleFacilityInfoSubmit)}
              style={{ width: "100%" }}
            >
              <Box sx={{ mt: 2, mb: 1 }}>
                {watchFacility === "hospital" ? (
                  <EmergencySearch control={control} errors={errors} />
                ) : (
                  <>
                    <Controller
                      name="location"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Facility name is required." }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="location"
                          label="Facility Name"
                          type="text"
                          InputLabelProps={{ shrink: true }}
                          variant="standard"
                          fullWidth
                          error={!!errors?.location}
                          helperText={errors?.location?.message}
                          sx={{ pb: 2 }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="address"
                      rules={{
                        validate: {
                          required: (value) => {
                            if (!value) return "Facility address is required.";
                            if (!getAddress(value))
                              return "We weren't able to get an address from this location. Please choose a different address.";
                            return true;
                          },
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          id="address"
                          options={address.suggestions}
                          onChange={(event, item) => onChange(item)}
                          value={value || null}
                          loading={address.loading}
                          noOptionsText="No results found"
                          getOptionLabel={(option) => option.place_name || ""}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Address"
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {address.loading && (
                                      <CircularProgress
                                        color="primary"
                                        size={20}
                                      />
                                    )}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                              onChange={(v) => address.onChange(v)}
                              variant="standard"
                              error={!!errors.address}
                            />
                          )}
                        />
                      )}
                    />
                    {errors?.address && (
                      <FormHelperText error={true}>
                        {errors.address.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}
              >
                <LoadingButton
                  variant="text"
                  onClick={handleBack}
                  size="large"
                  sx={{ minWidth: "120px", pl: 0 }}
                >
                  Back
                </LoadingButton>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={submitLoading}
                  size="large"
                  sx={{ minWidth: "120px" }}
                >
                  Complete Registration
                </LoadingButton>
              </Box>
            </form>
          </>
        )}

        {/* Step 4: Success Step (for all roles) */}
        {activeStep === 4 && (
          <Box sx={{ textAlign: "center", width: "100%" }}>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Your registration is complete! You can now access the dashboard.
            </Typography>

            <LoadingButton
              variant="contained"
              size="large"
              loading={submitLoading}
              onClick={async () => {
                setSubmitLoading(true);
                try {
                  await db.collection("users").doc(user.uid).update({
                    onboarding: false,
                  });
                  navigate("/dashboard");
                } catch (error) {
                  console.error("Error completing onboarding:", error);
                  setError("Failed to complete onboarding. Please try again.");
                  setSubmitLoading(false);
                }
              }}
              sx={{ mt: 2 }}
            >
              Go to Dashboard
            </LoadingButton>
          </Box>
        )}

        {/* Stepper - only show for facility role and steps 1-3 */}
        {role === "facility" && activeStep >= 1 && activeStep <= 3 && (
          <MobileStepper
            variant="dots"
            steps={3} // 3 steps for facility (user info, facility type, facility details)
            position="static"
            activeStep={activeStep - 1} // Adjust for 0-based index
            sx={{
              background: "transparent",
              justifyContent: "center",
              mt: 4,
              width: "100%",
            }}
            nextButton={null}
            backButton={null}
          />
        )}
      </Box>
    </Container>
  );
};

export default Onboarding;
