import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import promotionService from "./services/promotionService";
import {
  handleServiceError,
  showErrorNotification,
} from "./utils/errorHandling";
import { useAuth } from "hooks/useAuth";

// Key for session storage
const PROMOTION_NOTICE_KEY = "hasSeenPromotionRequirement";

const PromotionRequirementPopup = ({
  onClose,
  buttonRef,
  onEnablePromotion,
  zipCode,
}) => {
  const { userData } = useAuth();

  useEffect(() => {
    try {
      // If user has seen the notice, automatically enable promotion
      if (sessionStorage.getItem(PROMOTION_NOTICE_KEY) && userData?.uid) {
        handleOK();
        return;
      }
    } catch (error) {
      console.error("Error in popup setup:", error);
    }
  }, [userData]);

  const handleOK = async () => {
    try {
      if (!userData?.uid) {
        showErrorNotification("You must be logged in to enable promotion");
        return;
      }

      await handleServiceError(async () => {
        // Set the session storage flag
        sessionStorage.setItem(PROMOTION_NOTICE_KEY, "true");
        // Enable promotion - FIXED: Pass userData parameter
        await promotionService.setPromotionStatus(zipCode, true, userData);
        onEnablePromotion(true);
        onClose();
      });
    } catch (error) {
      showErrorNotification(`Error enabling promotion: ${error.message}`);
    }
  };

  // If user has already seen the notice, don't render anything
  if (sessionStorage.getItem(PROMOTION_NOTICE_KEY)) {
    return null;
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "400px",
          maxWidth: "90vw",
        },
      }}
    >
      <DialogContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Promotion Required
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          To place a bid in this ZIP code, you must first enable promotion. This
          will make your business visible on the map for this area.
        </Typography>
        <Typography variant="body2" sx={{ mb: 1, fontStyle: "italic" }}>
          You'll be billed monthly for promotions. The first invoice will be
          generated on the second Wednesday of next month.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleOK}
          variant="contained"
          color="primary"
          sx={{ minWidth: "80px" }}
        >
          Enable Promotion
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromotionRequirementPopup;
