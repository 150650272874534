// src/utils/errorHandling/ErrorTypes.js

export class ServiceError extends Error {
  constructor(message, type = "SERVICE_ERROR") {
    super(message);
    this.name = type;
  }
}

export class AuctionError extends ServiceError {
  constructor(message) {
    super(message, "AUCTION_ERROR");
  }
}

export class PromotionError extends ServiceError {
  constructor(message) {
    super(message, "PROMOTION_ERROR");
  }
}

export class BidError extends ServiceError {
  constructor(message) {
    super(message, "BID_ERROR");
  }
}
