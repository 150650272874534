import { useState, useEffect } from "react";
import { db } from "services/firebase";
import usePosition from "hooks/usePosition";

const useZip = () => {
  const { latitude, longitude } = usePosition();
  const [zip, setZip] = useState();
  const [customLocation, setCustomLocation] = useState();
  const [ads, setAds] = useState([]);
  const [adsLoaded, setAdsLoaded] = useState(false);

  const getAds = async (zip) => {
    console.log("getAds", zip);

    try {
      const querySnapshot = await db
        .collection("zips")
        .doc(String(zip))
        .collection("ads")
        .where("status", "==", "active")
        .get();

      console.log("querySnapshot", querySnapshot);

      const locationIds = [];
      querySnapshot.forEach((doc) => {
        const { location } = doc.data();
        console.log("location", location);
        if (location) {
          locationIds.push(location);
        }
      });

      const locationSnapshots = await Promise.all(
        locationIds.map((locationId) =>
          db.collection("locations").doc(locationId).get()
        )
      );

      const combinedAds = locationSnapshots
        .filter((snapshot) => snapshot.exists)
        .map((snapshot) => {
          const data = snapshot.data();
          if (data.branding && data.group) {
            return {
              title: data.group,
              ...data.branding,
            };
          }
          return null;
        })
        .filter(Boolean);

      setAds(combinedAds);
      setAdsLoaded(true);
    } catch (error) {
      console.error("Error fetching branding:", error);
      setAdsLoaded(true);
    }
  };

  useEffect(() => {
    const lat = customLocation?.lat || latitude;
    const lng = customLocation?.lng || longitude;
    const clZip = customLocation?.zip;

    if (!clZip) {
      setAdsLoaded(true);
    } else {
      setZip(clZip);
      getAds(clZip);
    }
  }, [latitude, longitude, customLocation]);

  return {
    zip,
    setCustomLocation,
    adsLoaded,
    ads,
  };
};

export default useZip;
