import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import CountdownCircle from "../CountdownCircle";
import { ZIP_CODES } from "../data/zipCodes";
import AuctionStatus from "../AuctionStatus";
import PromotionButton from "../PromotionButton";

const AuctionsTable = ({
  selectedZips,
  auctionTimes,
  currentBids,
  zipSubscriptions,
  pendingAuctions,
  pendingAuctionsTotal,
  handlePromotionChange,
  formatTimeRemaining,
  setAuctionTimes,
}) => {
  return (
    <TableContainer component={Paper} sx={{ my: 3 }}>
      <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Current Auctions
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {pendingAuctions.length > 0 && (
                <>
                  {pendingAuctions.length} pending ($
                  {pendingAuctionsTotal.toLocaleString()})
                </>
              )}
              {pendingAuctions.length === 0 && <>No pending auctions</>}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "background.paper" }}>
            <TableCell>Location</TableCell>
            <TableCell>Promotion</TableCell>
            <TableCell>Current Bid</TableCell>
            <TableCell>Auction Status</TableCell>
            <TableCell>Time Left</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(selectedZips)
            .sort()
            .map((zipCode) => (
              <TableRow
                key={zipCode}
                sx={{
                  bgcolor:
                    zipSubscriptions[zipCode]?.status === "active"
                      ? "success.lighter"
                      : "inherit",
                }}
              >
                <TableCell>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {zipCode}
                      </Typography>
                      {zipSubscriptions[zipCode]?.status === "active" && (
                        <Chip
                          label="Active Until Next Month"
                          size="small"
                          color="success"
                          sx={{ height: 20 }}
                        />
                      )}
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {ZIP_CODES[zipCode]?.city}, {ZIP_CODES[zipCode]?.state}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <PromotionButton
                    zipCode={zipCode}
                    onChange={handlePromotionChange}
                  />
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="text.secondary">
                    ${((currentBids[zipCode] || 100000) / 100).toLocaleString()}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box sx={{ position: "relative" }}>
                    <AuctionStatus
                      zipCode={zipCode}
                      onBidPlaced={() => {
                        if (
                          auctionTimes[zipCode] &&
                          auctionTimes[zipCode] <= 60000
                        ) {
                          setAuctionTimes((prev) => ({
                            ...prev,
                            [zipCode]: 60000,
                          }));
                        }
                      }}
                      hideTimer={true}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ position: "relative" }}>
                    {auctionTimes[zipCode] <= 60000 &&
                    auctionTimes[zipCode] > 0 ? (
                      <CountdownCircle timeLeft={auctionTimes[zipCode]} />
                    ) : (
                      <Typography
                        variant="body2"
                        color={
                          auctionTimes[zipCode] <= 0
                            ? "error.main"
                            : "text.primary"
                        }
                      >
                        {auctionTimes[zipCode] > 60000
                          ? formatTimeRemaining(auctionTimes[zipCode])
                          : ""}
                      </Typography>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AuctionsTable;
