import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Search from "components/map/Search";
import Filters from "components/map/Filters";
import Columns from "components/styled/Columns";
import Logo from "components/styled/Logo";
import nm from "assets/images/branding/nm.jpg";
import wray from "assets/images/branding/wray.jpg";
import MainMenu from "components/common/layout/MainMenu";
import ColorLegend from "components/map/ColorLegend";
import ColorGradient from '../../components/dashboard/ColorGradient';
import { useNavigate } from "react-router-dom";

import { FaMinus, FaPlus, FaSearch } from "react-icons/fa";

import { useMap } from "react-leaflet";
import { Button } from "@mui/material";
import { AuthContext } from "context/Auth";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Popup = styled.p`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-weight: 400 !important;

  a {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const MarketingLogoWrapper = styled.div`
  text-align: center;
  padding: 0.25rem 0.5rem 0.5rem;
`;

const MarketingLogo = styled.img`
  margin: auto;
  max-height: 40px;
`;

const WaitRoomTimeWrapper = styled.div`
  /* position: absolute; */
  top: 100%;
  background-color: ${(props) => props.theme.colors.white};
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 720px;
  padding: 0.125em 0.275em;
`;

const SearchButtonWrapper = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  right: 5px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 0.5rem 0.6em 0.325rem;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 18px 0 rgba(0, 0, 0, 0.19);
`;

const ZoomWrapper = styled.div`
  position: absolute;
  top: calc(100% + 48px);
  right: 5px;
  background-color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 18px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;

  div:first-child {
    padding: 0.55rem 0.6em 0.225rem;
  }

  div:last-child {
    padding: 0.325rem 0.6em 0.3rem;
  }
`;

const SearchButton = ({ onClick }) => (
  <SearchButtonWrapper onClick={onClick}>
    <FaSearch size={18} color="#1B4584" />
  </SearchButtonWrapper>
);

const ZoomButton = () => {
  const map = useMap();

  return (
    <ZoomWrapper>
      <div onClick={() => map.zoomIn()}>
        <FaPlus size={12} color="#1B4584" />
      </div>
      <div onClick={() => map.zoomOut()}>
        <FaMinus size={12} color="#1B4584" />
      </div>
    </ZoomWrapper>
  );
};

const HeaderWrapper = styled.div`
  position: absolute;
  height: auto;
  padding: 5px;
  font-size: 20px;
  left: 0;
  right: 0;
  top: 0;
  background: #fff;
  font-size: 12px;
  z-index: 9999;
  p {
    text-align: center;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 12px;
    z-index: 99;
  }
  .center {
    text-align: center;
  }
  p {
    text-align: center;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 12px;
  }
  h2 {
    text-align: center;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
    margin-top: 2px;
    margin-bottom: 6px;
    font-size: 16px;
  }
`;

export default ({
  data,
  filter,
  setFilter,
  setCoords,
  updateMap,
  searchLoaded,
  currentRegion,
  setRegional,
  setCustomLocation,
  vaccine,
  ads,
  branding,
  expanded = true,
  full = true,
}) => {
  const [showSearch, setShowSearch] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSortLevelOfCare = () => {
    navigate('/dashboard', {
      state: {
        initiateChatWith: "Assess what level of care I need right now",
        assistantId: process.env.REACT_APP_ASSISTANT_INDIVIDUAL || ""
      }
    });
  };

  if (!full && !expanded) return;

  return (
    <HeaderWrapper>
      {full && (
        <Columns $main>
          <LogoWrapper>
            <MainMenu />
            <Logo />
          </LogoWrapper>
          {/* <ShareButtons /> */}
          <Link to="/login">
            <Button variant="contained" color="secondary" size="small">
              {currentRegion === "wray" || user
                ? "Dashboard"
                : "Subscribe/Log In"}
            </Button>
          </Link>
        </Columns>
      )}

      {/* These are temporary logos hardcoded for regional demos--when regional feature is built out these will be removed */}
      {currentRegion === "nm" && (
        <div className="center">
          <Logo src={nm} width="175px" />
        </div>
      )}
      {currentRegion === "wray" && (
        <div className="center">
          <img src={wray} width="175px" />
        </div>
      )}
      {currentRegion === "nm" && (
        <div className="center">
          <label>
            <input
              type="checkbox"
              onChange={(e) => setRegional(e.target.checked)}
            />
            Include other medical groups
          </label>
        </div>
      )}

      {ads?.length > 0 && ads[0]?.website && ads[0]?.logo ? (
        <MarketingLogoWrapper>
          <a href={ads[0].website} target="_blank" rel="noopener">
            <MarketingLogo src={ads[0].logo} />
          </a>
        </MarketingLogoWrapper>
      ) : (
        <h2 style={{ color: "grey" }}>Same Day Healthcare Availability</h2>
      )}

      {/* {branding ? (
        <MarketingLogoWrapper>
          <a href={branding.website} target="_blank" rel="noopener">
            <MarketingLogo src={branding.logo} />
          </a>
        </MarketingLogoWrapper>
      )} */}

      {currentRegion !== "wray" && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

          {/* Left box - 50% width */}
          <div style={{ width: '50%' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
              <Filters
                data={data}
                filter={filter}
                setFilter={setFilter}
                vaccine={vaccine}
                ads={ads}
                sx={{ margin: '0 !important' }}
              />

              {
                filter.facility !== "emergency" && (

                  <Button
                    // onClick={() => sendMessage("Assess what level of care I need right now")}
                    // onClick={() => { alert("You are now in the chat with the prompt: Assess the level of care the user needs") }}
                    onClick={handleSortLevelOfCare}
                    variant="outlined"
                    style={{ fontSize: "13px", maxWidth: "350px" }}
                  >
                    {/* Let medical SuperIntelligence help you sort out what level of care you need. */}
                    Sort level of care with AI
                  </Button>
                )
              }
            </div>

            {filter.facility === "emergency" ? null : (
              <Popup style={{ lineHeight: 1.2, paddingTop: "0.25rem" }}>
                Tip: Facilities with a Virtual Queue (Q) can reduce your time in the
                waiting room dramatically. Tap on the marker to Save Your Spot in the
                Queue.
              </Popup>
            )}
          </div>

          {/* Right box - 50% width */}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
            <ColorGradient height="100px" style={{ width: "calc(100% - 20px)" }} />
          </div>
        </div>
      )}

      {!user ? (
        <Link to="/register">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            sx={{ position: "absolute", top: "calc(100% + 5px)", left: 5 }}
          >
            Get on the map
          </Button>
        </Link>
      ) : null}

      <SearchButton onClick={() => setShowSearch(!showSearch)} />
      <ZoomButton onClick={() => setShowSearch(!showSearch)} />
      <Search
        searchLoaded={searchLoaded}
        open={showSearch}
        setCoords={setCoords}
        setCustomLocation={setCustomLocation}
      />
    </HeaderWrapper>
  );
};
