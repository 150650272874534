import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Navigate } from "react-router-dom";
import Logo from "components/styled/Logo";
import { useAuth } from "hooks/useAuth";
import {
  OnboardingDrawer,
  PatientDrawer,
  FacilityDrawer,
  SettingsDrawer,
} from "./DrawerComponents";

const drawerWidth = 300;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 2),
  ...theme.mixins.toolbar,
}));

const DashboardNav = ({ children }) => {
  const { user, subscription, userData, activeSubscriptionRole, logout } =
    useAuth();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleLogout = async () => {
    await logout();
    return <Navigate to="/login" />;
  };

  const drawer = (
    <div>
      <DrawerHeader sx={{ alignItems: "center" }}>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Logo />
        </Box>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <div onClick={handleDrawerToggle}>
        {userData?.role === "facility" && user.emailVerified && (
          <FacilityDrawer />
        )}

        {(userData?.role === "patient" || userData?.role === "professional") &&
          user.emailVerified && (
            <PatientDrawer subscription={subscription} userData={userData} />
          )}

        {user.emailVerified ? (
          <SettingsDrawer
            activeSubscriptionRole={activeSubscriptionRole}
            userData={userData}
          />
        ) : null}

        <OnboardingDrawer logout={handleLogout} />
      </div>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#fff",
          boxShadow: "none",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ display: { xs: "flex", sm: "none" } }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { sm: "none" } }}>
            <Logo />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          margin: "auto",
          maxWidth: "1024px",
          p: 3,
          paddingTop: { xs: "65px", sm: 0 },
          paddingBottom: 0,
          width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
          height: "100vh",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DashboardNav;
