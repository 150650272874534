import React, { useRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  handleServiceError,
  showErrorNotification,
} from "./utils/errorHandling";
import { useAuth } from "hooks/useAuth";
import firebase from "firebase/compat/app";

const BidPopup = ({ minBid, onSubmit, onCancel, buttonRef }) => {
  const { userData } = useAuth();
  // Convert minBid from cents to dollars for display and input
  const minBidDollars = minBid / 100;
  const [inputValue, setInputValue] = useState(minBidDollars);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    // Focus input when popup opens
    inputRef.current?.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await handleServiceError(async () => {
        // Convert to numbers and ensure proper precision
        const currentBidDollars = Math.round(Number(inputValue));
        const minimumBidDollars = Math.round(Number(minBidDollars));

        if (isNaN(currentBidDollars) || currentBidDollars < minimumBidDollars) {
          throw new Error(
            `Bid must be at least $${minimumBidDollars.toLocaleString()}`
          );
        }

        if (!userData?.uid) {
          throw new Error("User must be logged in to place bid");
        }

        // Convert dollar amounts to cents for storage and processing
        const currentBidCents = currentBidDollars * 100;

        const bidData = {
          currentBid: currentBidCents, // Store in cents
          lastBidder: userData.uid,
          lastBidderEmail: userData.email,
          numberOfBids: firebase.firestore.FieldValue.increment(1),
          bidHistory: firebase.firestore.FieldValue.arrayUnion({
            amount: currentBidCents, // Store in cents
            bidderId: userData.uid,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          }),
        };

        // Pass the bid amount in cents to the onSubmit handler
        await onSubmit(currentBidCents);
      });
    } catch (error) {
      showErrorNotification(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          position: "relative",
          width: "300px",
        },
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>Place Your Bid</DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ mb: 1 }}
            >
              Enter bid amount:
            </Typography>
            <TextField
              inputRef={inputRef}
              type="number"
              value={inputValue}
              onChange={(e) => setInputValue(Number(e.target.value))}
              disabled={isSubmitting}
              fullWidth
              size="small"
              inputProps={{
                min: minBidDollars,
                step: "250",
              }}
              sx={{
                mb: 1,
                "& input": {
                  opacity: isSubmitting ? 0.7 : 1,
                },
              }}
            />
            <Typography variant="caption" color="text.secondary">
              Minimum bid: ${minBidDollars.toLocaleString()}
            </Typography>
          </Box>

          <DialogActions sx={{ px: 0, pb: 0 }}>
            <Button
              type="button"
              onClick={onCancel}
              disabled={isSubmitting}
              sx={{
                opacity: isSubmitting ? 0.7 : 1,
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={isSubmitting}
              sx={{
                minWidth: "100px",
              }}
            >
              Place Bid
            </LoadingButton>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default BidPopup;
