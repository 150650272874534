// src/utils/errorHandling/notifications.js

let activeNotifications = [];

const removeNotification = (notification) => {
  notification.style.animation = "slideOut 0.3s ease-in";
  setTimeout(() => {
    notification.remove();
    activeNotifications = activeNotifications.filter((n) => n !== notification);
  }, 300);
};

export const showErrorNotification = (message, duration = 5000) => {
  // Remove oldest notification if we have too many
  if (activeNotifications.length >= 3) {
    removeNotification(activeNotifications[0]);
  }

  const notification = document.createElement("div");
  notification.style.cssText = `
    position: fixed;
    top: ${20 + activeNotifications.length * 70}px;
    right: 20px;
    background-color: #fee2e2;
    color: #991b1b;
    padding: 12px 20px;
    border-radius: 4px;
    z-index: 9999;
    animation: slideIn 0.3s ease-out;
    max-width: 300px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

  // Create message span
  const messageSpan = document.createElement("span");
  messageSpan.textContent = message;
  messageSpan.style.marginRight = "10px";

  // Create close button
  const closeButton = document.createElement("button");
  closeButton.innerHTML = "×";
  closeButton.style.cssText = `
    background: none;
    border: none;
    color: #991b1b;
    font-size: 20px;
    cursor: pointer;
    padding: 0 5px;
  `;
  closeButton.onclick = () => removeNotification(notification);

  // Add elements to notification
  notification.appendChild(messageSpan);
  notification.appendChild(closeButton);

  // Add styles to document if they don't exist
  if (!document.getElementById("notification-styles")) {
    const styleSheet = document.createElement("style");
    styleSheet.id = "notification-styles";
    styleSheet.textContent = `
      @keyframes slideIn {
        from {
          transform: translateX(100%);
          opacity: 0;
        }
        to {
          transform: translateX(0);
          opacity: 1;
        }
      }
      
      @keyframes slideOut {
        from {
          transform: translateX(0);
          opacity: 1;
        }
        to {
          transform: translateX(100%);
          opacity: 0;
        }
      }
    `;
    document.head.appendChild(styleSheet);
  }

  document.body.appendChild(notification);
  activeNotifications.push(notification);

  setTimeout(() => {
    if (document.body.contains(notification)) {
      removeNotification(notification);
    }
  }, duration);

  return notification;
};
