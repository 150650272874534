import React from "react";
import { Box } from "@mui/material";
import KijabeContextBox from "components/chatbot/kijabe/KijabeContextBox";
import { KijabeChatProvider } from "context/KijabeChat";
import LanguageSelector from "components/chatbot/LanguageSelector";

const KijabeChatPage = () => {
  return (
    <KijabeChatProvider>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          "& .cs-main-container": {
            maxWidth: "1000px",
            width: "100%",
            padding: "0 15px",
          },
        }}
      >
        <LanguageSelector />
        <KijabeContextBox />
      </Box>
    </KijabeChatProvider>
  );
};

export default KijabeChatPage;
