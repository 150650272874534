import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useAuth } from "hooks/useAuth";
import DashboardLayout from "components/dashboard/DashboardLayout";
import DashboardRoutes from "routes/DashboardRoutes";
import PatientRoutes from "routes/PatientRoutes";
import EmailConfirmation from "components/EmailConfirmation";
import { ChatProvider } from "context/Chat";
import { db } from "services/firebase";
import Loading from "components/Loading";

const DashboardPage = () => {
  const { user, userLoading, userData } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [userDocChecked, setUserDocChecked] = useState(false);

  // Effect to handle authentication and data loading
  useEffect(() => {
    const checkUserStatus = async () => {
      // Wait for authentication to complete
      if (userLoading) return;

      // If no user, we'll handle redirect in the render
      if (!user) {
        setIsLoading(false);
        setUserDocChecked(true);
        return;
      }

      // If userData is already loaded through useAuth hook
      if (userData) {
        setIsLoading(false);
        setUserDocChecked(true);
        return;
      }

      // If we have a user but no userData, check Firestore directly
      try {
        const userDoc = await db.collection("users").doc(user.uid).get();
        const userData = userDoc.data();

        if (!userDoc.exists) {
          // Redirect to onboarding if no user document
          navigate("/onboarding");
        } else if (userData.onboarding) {
          // If user is still in onboarding, redirect back to onboarding
          navigate("/onboarding");
        } else {
          // We've confirmed the document exists and onboarding is complete
          setIsLoading(false);
          setUserDocChecked(true);
        }
      } catch (error) {
        console.error("Error checking user document:", error);
        setIsLoading(false);
        setUserDocChecked(true);
      }
    };

    checkUserStatus();
  }, [user, userLoading, userData]);

  // If still loading or user document hasn't been checked, show loading screen
  if (isLoading || !userDocChecked) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <Loading page />
      </Box>
    );
  }

  // If user is not authenticated, redirect to login
  if (!user) {
    return <Navigate to="/login/" />;
  }

  // Email verification check
  if (!user.emailVerified) {
    return (
      <DashboardLayout>
        <EmailConfirmation />
      </DashboardLayout>
    );
  }

  // If we have userData but no role, redirect to onboarding
  if (userData && !userData.role) {
    navigate("/onboarding");
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <Loading page />
      </Box>
    );
  }

  return (
    <ChatProvider>
      {userData?.role === "patient" || userData?.role === "professional" ? (
        <PatientRoutes />
      ) : (
        <DashboardRoutes />
      )}
    </ChatProvider>
  );
};

export default DashboardPage;
