import { Typography, Alert, CircularProgress } from "@mui/material";
import React from "react";

export default function AccountVerifyEmail({ verified, isEmailRecovery }) {
  return (
    <>
      <Typography variant="h4" sx={{ mt: { xs: 4, sm: 10 }, mb: 2 }}>
        {isEmailRecovery
          ? "Your Email Has Been Restored"
          : "Your Email Has Been Updated"}
      </Typography>

      {verified ? (
        <>
          <Typography sx={{ mb: 2 }}>
            {isEmailRecovery
              ? "Your previous email has been restored successfully."
              : "Your email has been verified successfully!"}
          </Typography>
          <Typography>Please wait, you will be redirected shortly.</Typography>
        </>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}
