import React, { useCallback, useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import { Marker as LeafletMarker } from "react-leaflet";
import L from "leaflet";
import SvgMarker from "../styled/SvgMarker";

const MIN_WAIT_TIME = 15;
const MAX_WAIT_TIME = 480; // 465 + 15
const MAX_WAIT_SCORE = 360; // 3.6 * 100

const Marker = ({ data, filter, setModalOpen, setModalVisible }) => {
  const {
    title,
    averageWaitTime,
    type,
    queueEnabled,
    waitScore,
    lat,
    lng,
    users,
    rating,
    pending,
    customPhone,
    telehealth,
    score_overall,
  } = data;

  // This color funciton was used to color the markers based on the average wait time,
  // it goes from 0 to 100 but is being replaced as of KAN-671
  // const baseHue = useMemo(() => {
  //   if (averageWaitTime !== undefined) {
  //     const percentage =
  //       (averageWaitTime - MIN_WAIT_TIME) / (MAX_WAIT_TIME - MIN_WAIT_TIME);
  //     return Math.abs(percentage * 100 - 100);
  //   }
  //   if (waitScore !== undefined) {
  //     return Math.abs(waitScore / (MAX_WAIT_SCORE / 100) - 100);
  //   }
  //   return -1;
  // }, [averageWaitTime, waitScore]);

  // The new version just returns from 0.0 to 1.0 based off the score_overall value
  const baseHue = useMemo(() => {
    if (score_overall !== undefined) { return score_overall / 100; }
    return 0;
  }, [score_overall]);

  // console.log("baseHue", baseHue);

  // Memoize visibility check with updated filter logic
  const isVisible = useMemo(() => {
    // Handle emergency department case
    if (filter.facility === "emergency") {
      return (
        type === "Emergency Department" &&
        (!filter.rating || rating >= filter.rating)
      );
    }

    // Handle urgent care case
    if (filter.facility === "clinic") {
      return type !== "Emergency Department";
    }

    // Handle facility groups case (from ads)
    if (filter.group) {
      return data.group === filter.group;
    }

    // Handle "all" case
    if (filter.facility === "all") {
      return true;
    }

    return false;
  }, [filter.facility, filter.rating, filter.group, type, rating, data.group]);

  // Memoize icon creation
  const icon = useMemo(
    () =>
      new L.divIcon({
        html: ReactDOMServer.renderToString(
          <SvgMarker
            queueEnabled={queueEnabled}
            baseHue={baseHue}
            owned={!!users?.length}
            visible={isVisible}
            type={type}
          />
        ),
        iconSize: [0, 0],
      }),
    [queueEnabled, baseHue, users, isVisible, type]
  );

  // Memoize click handler
  const handleClick = useCallback(() => {
    setModalVisible(true);
    setModalOpen({
      ...data,
      phone: customPhone,
      videocare: telehealth,
      location: { latitude: lat, longitude: lng },
    });
  }, [data, customPhone, telehealth, lat, lng, setModalOpen, setModalVisible]);

  if (pending) return null;

  return isVisible ? (
    <LeafletMarker
      position={[lat, lng]}
      title={title}
      eventHandlers={{ click: handleClick }}
      icon={icon}
    />
  ) : null;
};

export default React.memo(Marker);
