import React, { useState } from "react";
import styled from "styled-components";
import { db } from "services/firebase";
import { format, differenceInMinutes } from "date-fns";
import PatientIdModal from "../styled/PatientIdModal";
import { Box, Button, Chip, IconButton, Link } from "@mui/material";
import { ClearRounded, DeleteRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "hooks/useAuth";

const QueuePatient = ({ data, setData, patient }) => {
  console.log({ patient });
  const { user } = useAuth();
  const [deleted, setDeleted] = useState(false);
  const [phoneVisible, setPhoneVisible] = useState(false);
  const [calledTime, setCalledTime] = useState();
  const [registerLoading, setRegisterLoading] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getDifference = () => {
    const time = calledTime ? calledTime : patient.called;
    return differenceInMinutes(Date.now(), time);
  };

  const deletePatient = async (patient) => {
    setDeleteLoading(true);

    try {
      // Find any existing registration for this patient
      const registrationQuery = await db
        .collection("registrations")
        .where("location", "==", data.id)
        .where("patient", "==", patient.id)
        .get();

      let newQueue = [...data.queue];
      const index = newQueue.indexOf(patient);
      newQueue.splice(index, 1);

      const batch = db.batch();

      // Update the queue
      batch.update(db.collection("locations").doc(String(data.id)), {
        queue: newQueue,
      });

      // Delete any found registration
      registrationQuery.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
      console.log("Patient and registration deleted successfully");
    } catch (error) {
      console.error("Error deleting patient and registration:", error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const registerPatient = (patient) => {
    setRegisterLoading(patient.id);

    let newQueue = [...data.queue];
    const index = newQueue.indexOf(patient);
    const updatedPatient = { ...patient, registrationSent: true };
    newQueue[index] = updatedPatient;

    db.collection("registrations")
      .add({
        location: data.id,
        patient: patient.id,
        uid: user.uid,
      })
      .then((doc) => {
        const batch = db.batch();

        batch.update(doc, { id: doc.id });

        batch.update(db.collection("locations").doc(String(data.id)), {
          queue: newQueue,
        });

        const message =
          (data.textSequence
            ? data.textSequence[1]
            : `Please go to this link to complete the virtual registration sequence.`) +
          (data?.healthcare_que?.enabled
            ? ` ${window.location.origin}/registration/${doc.id}`
            : "");

        batch.set(db.collection("messages").doc(), {
          to: patient.phone,
          body: message,
        });

        batch.commit().then(() => {
          console.log("sent");
          setData({ ...data, queue: newQueue });
          setRegisterLoading();
        });
      });
  };

  const textPatient = (patient, type) => {
    const batch = db.batch();

    let newQueue = [...data.queue];
    const index = newQueue.indexOf(patient);
    const updatedPatient =
      type === "call"
        ? { ...patient, called: Date.now() }
        : { ...patient, registered: Date.now() };
    newQueue[index] = updatedPatient;

    batch.update(db.collection("locations").doc(String(data.id)), {
      queue: newQueue,
    });

    const message = data.textSequence
      ? data.textSequence[3]
      : `${data.title} is ready for you to come in. The address is ${data.address}. Show this text to the welcome desk.` +
        ` You are patient #${patient.id}.`;
    batch.set(db.collection("messages").doc(), {
      to: patient.phone,
      body: message,
    });

    batch.commit().then(() => {
      setData({ ...data, queue: newQueue });
    });
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "8px", mb: 1 }}>
      <Link
        onClick={() => setPhoneVisible(!phoneVisible)}
        sx={{ cursor: "pointer", flex: 1 }}
      >
        Patient #{patient.id}
      </Link>

      <Chip
        label={format(patient.date, "h:mma")}
        variant="outlined"
        color="primary"
        sx={{ flex: 1 }}
      />

      {data?.healthcare_que?.enabled ? (
        <LoadingButton
          variant="contained"
          disabled={!!patient?.registration || !!patient?.registrationSent}
          loading={registerLoading === patient.id}
          onClick={() => registerPatient(patient)}
          sx={{ flex: 1 }}
        >
          {patient?.registration
            ? "Registered"
            : patient?.registrationSent
            ? "Sent"
            : "Register"}
        </LoadingButton>
      ) : null}

      <Button
        disabled={!!patient?.called}
        variant="outlined"
        onClick={() => {
          if (
            patient?.status?.toUpperCase() !== "ARRIVED" &&
            patient?.status?.toUpperCase() !== "CANCELLED"
          ) {
            textPatient(patient, "call");
            setCalledTime(Date.now());
          }
        }}
        sx={{ flex: 1 }}
      >
        {patient?.status?.toUpperCase() === "ARRIVED" ||
        patient?.status?.toUpperCase() === "CANCELLED"
          ? patient.status
          : calledTime || patient.called
          ? getDifference()
          : "Notify"}
      </Button>

      <LoadingButton
        color="error"
        size="small"
        loading={deleteLoading}
        onClick={() => deletePatient(patient)}
        sx={{ minWidth: 0, p: 1 }}
      >
        <ClearRounded fontSize="inherit" />
      </LoadingButton>

      <PatientIdModal
        patient={patient}
        visible={phoneVisible}
        setVisible={setPhoneVisible}
      ></PatientIdModal>
    </Box>
  );
};

export default QueuePatient;
