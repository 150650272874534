import React from "react";
import DocumentViewer from "./DocumentViewer";

const PrivacyPolicy = () => (
  <DocumentViewer
    pdf={
      "https://img1.wsimg.com/blobby/go/9fc169e5-1029-481b-93db-dc019717f136/hlthdsk-privacy-policy.pdf"
    }
  />
);

export default PrivacyPolicy;
