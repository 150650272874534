import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";

import Button from "../styled/Button";

import logo from "assets/images/logos/logo-icon.png";

const Wrapper = styled.div`
  box-sizing: border-box;
  width: calc(90% - 50px);
  height: auto;
  max-width: 860px;
  font-size: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  margin: 0 auto;
  position: fixed;
  z-index: 1000;

  span {
    font-size: 12px;
    color: #000;
  }

  button {
    border-radius: 4px;
  }

  .slick-track {
    display: flex;
    align-items: flex-end;
  }

  .slick-slide {
    padding: 10px;
  }

  .slick-prev {
    top: initial;
    bottom: 12px;
    left: -30px;
  }

  .slick-next {
    top: initial;
    bottom: 12px;
    right: -30px;
  }

  @media (max-width: 1024px) {
    font-size: 16px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto 0 0;
    position: fixed;

    .slick-prev {
      top: -10px;
      left: 10px;
    }

    .slick-next {
      top: -10px;
      left: 40px;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    color: ${(props) => props.theme.colors.primary};
  }

  .attribution {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;

    img {
      padding-right: 5px;
    }

    * {
      flex: 1;
    }
  }
`;

const Slide = styled.div`
  background-color: #fff;
  padding: 5px;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;

  h2 {
    font-size: 16px;
    margin: 0;
    margin-top: 5px;
  }
`;
const P = styled.div`
  margin-left: 5px;
  font-size: 16px;
  color: #1b4584;
  white-space: pre-line;
  text-align: left;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;
const FloatRight = styled.div`
  display: flex;
  align-items: center;
  float: right;
  margin-left: 10px;

  & > * {
    float: right;
  }
  @media (max-width: 1024px) {
    margin-left: 0;
    margin-top: 2px;
    flex-basis: 100% !important;

    button {
      font-size: 13px !important;
    }
  }
`;

// HealthDesk-branded slides to be interspersed with advertisement slides
const healthdeskSlides = [
  {
    message: "Medical SuperIntelligence: Take A Stroll",
    button: "Try for Free",
    buttonClick: () => (window.location.href = "/register"),
  },
  {
    message: "Symptom Checker, with SuperIntelligence",
    button: "Try for Free",
    buttonClick: () => (window.location.href = "/register"),
  },
  {
    message:
      "<strong>One Does Not Simply</strong> - Check Out SuperIntelligence",
    button: "Try for Free",
    buttonClick: () => (window.location.href = "/register"),
  },
  {
    message: "<strong>Is It Though? - Enter your symptoms</strong>",
    button: "Try for Free",
    buttonClick: () => (window.location.href = "/register"),
  },
  {
    message: "Discuss Health Care on <strong>Your</strong> Schedule",
    button: "Try for Free",
    buttonClick: () => (window.location.href = "/register"),
  },
];

const AdSlide = ({ image, message, buttonClick, buttonText }) => {
  return (
    <Slide className="attribution" onClick={() => buttonClick()}>
      <img
        src={image ? image : logo}
        alt=""
        style={{ maxWidth: image ? "70px" : "35px" }}
      />

      <P dangerouslySetInnerHTML={{ __html: message }}></P>
      <FloatRight>
        <Button
          style={{
            marginTop: "3px",
            marginBottom: "2px",
            padding: "7px",
            height: "28px",
          }}
          $small
          $noMargin
        >
          {buttonText}
        </Button>
      </FloatRight>
    </Slide>
  );
};

const AdCarousel = ({ ads }) => {
  const [slides, setSlides] = useState([]);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    dots: false,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  useEffect(() => {
    let slidesArray = [];
    let adSlides = [];

    if (!!ads.length)
      ads.forEach((ad) => {
        console.log({ ad });
        adSlides.push({
          logo: ad.logo,
          message: ad.tagline,
          button: "Learn More",
          buttonClick: () => window.open(ad.website, "_blank"),
        });
      });

    if (adSlides.length > 0) {
      adSlides.map((ad, i) => {
        slidesArray.push(healthdeskSlides[i]);
        slidesArray.push(ad);
      });
    } else {
      slidesArray = healthdeskSlides;
    }

    setSlides(slidesArray);
  }, [ads]);

  return (
    <>
      <Wrapper>
        <Slider {...settings}>
          {slides.map((slide, i) => (
            <AdSlide
              key={i}
              image={slide.logo}
              buttonText={slide.button}
              buttonClick={slide.buttonClick}
              message={slide.message}
            />
          ))}
        </Slider>
      </Wrapper>
    </>
  );
};

export default AdCarousel;
