import React, { useEffect, useRef } from "react";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { Box, Tooltip } from "@mui/material";
import { MAP_SETTINGS } from "./constants/mapSettings";
import { ZIP_CODES } from "./data/zipCodes";
import "./styles/styles.css";

const MapDrawTools = ({ onZipSelect, selectedZips }) => {
  const toolbarRef = useRef(null);

  // Add tooltip after component mount
  useEffect(() => {
    const addTooltip = () => {
      const drawButton = document.querySelector(".leaflet-draw-draw-circle");
      if (drawButton) {
        // Remove the title attribute
        drawButton.removeAttribute("title");

        // Position the container relatively
        const toolbarContainer = drawButton.closest(".leaflet-draw-toolbar");
        if (toolbarContainer) {
          toolbarContainer.style.position = "relative";
        }
      }
    };

    // Add tooltip after a short delay to ensure the control is mounted
    setTimeout(addTooltip, 100);
  }, []);

  const handleCreated = (e) => {
    if (e.layerType === "circle") {
      const circle = e.layer;
      const center = circle.getLatLng();
      const radius = circle.getRadius();

      // Get zip codes within the circle
      const containedZips = [];
      for (const [zipId, zipData] of Object.entries(ZIP_CODES)) {
        if (zipData && zipData.lat && zipData.lng) {
          const distance = center.distanceTo([zipData.lat, zipData.lng]);
          if (distance <= radius) {
            containedZips.push(zipId);
          }
        }
      }

      if (containedZips.length > 0) {
        onZipSelect(containedZips);
        setTimeout(() => {
          const tableContainer = document.querySelector(
            ".selected-zips-container"
          );
          if (tableContainer) {
            tableContainer.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }, 100);
      }

      // Remove the circle after selection
      circle.remove();
    }
  };

  return (
    <Box className="map-draw-tools-container">
      <FeatureGroup ref={toolbarRef}>
        <Box
          className="edit-control-wrapper"
          sx={{
            "& .leaflet-draw-toolbar": {
              position: "relative",
              display: "inline-block",
            },
          }}
        >
          <Tooltip
            title="Draw a circle to select multiple ZIP codes in an area"
            placement="right"
            arrow
            componentsProps={{
              popper: {
                sx: {
                  zIndex: 1500,
                },
              },
            }}
          >
            <Box>
              <EditControl
                position="topleft"
                onCreated={handleCreated}
                draw={{
                  circle: {
                    shapeOptions: {
                      color: "#841B45",
                      fillOpacity: 0.2,
                    },
                    showRadius: true,
                    metric: false,
                    feet: false,
                  },
                  rectangle: false,
                  polygon: false,
                  circlemarker: false,
                  marker: false,
                  polyline: false,
                }}
                edit={{ edit: false, remove: false }}
              />
            </Box>
          </Tooltip>
        </Box>
      </FeatureGroup>
    </Box>
  );
};

export default MapDrawTools;
