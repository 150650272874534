// src/utils/errorHandling/handlers.js

import { ServiceError } from "./ErrorTypes";
import { showErrorNotification } from "./notifications";

export const handleServiceError = async (operation, maxRetries = 3) => {
  let lastError;
  for (let i = 0; i < maxRetries; i++) {
    try {
      return await operation();
    } catch (error) {
      lastError = error;
      console.error(`Attempt ${i + 1} failed:`, error);

      if (i < maxRetries - 1) {
        await new Promise((resolve) =>
          setTimeout(resolve, Math.pow(2, i) * 1000)
        );
      }
    }
  }

  const finalError = new ServiceError(
    `Operation failed after ${maxRetries} attempts: ${lastError.message}`
  );
  showErrorNotification(finalError.message);
  throw finalError;
};

export const wrapServiceCall = async (operation, errorMessage) => {
  try {
    return await operation();
  } catch (error) {
    showErrorNotification(errorMessage || error.message);
    throw error;
  }
};
