import L from "leaflet";

export const createCustomIcon = (color) => {
  return L.divIcon({
    html: `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="8" fill="${color}"/>
      </svg>
    `,
    className: "",
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  });
};
