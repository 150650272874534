import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import { PaymentRounded } from "@mui/icons-material";
import { ZIP_CODES } from "../data/zipCodes";

const SubscriptionsTable = ({
  totals,
  activePromotions,
  zipSubscriptions,
  selectedZips,
  pendingInvoices,
  isLoading,
}) => {
  // Prepare data for the table
  const combinedSubscriptions = React.useMemo(() => {
    if (!zipSubscriptions || isLoading) return [];

    return Array.from(selectedZips)
      .filter((zipCode) => zipSubscriptions[zipCode])
      .map((zipCode) => {
        const subscription = zipSubscriptions[zipCode];
        const promotion = activePromotions.find((p) => p.zipCode === zipCode);

        return {
          zipCode,
          cityState: `${ZIP_CODES[zipCode]?.city || ""}, ${
            ZIP_CODES[zipCode]?.state || ""
          }`,
          ad: subscription,
          promotion,
        };
      })
      .sort((a, b) => a.zipCode.localeCompare(b.zipCode));
  }, [selectedZips, zipSubscriptions, activePromotions, isLoading]);

  // If there are no subscriptions, don't render the component
  if (combinedSubscriptions.length === 0) {
    return null;
  }

  // Check if there are any pending items that need payment
  const hasPendingItems =
    totals.pendingCount > 0 ||
    activePromotions.filter((p) => p.status === "invoiced").length > 0;

  // Get the pending invoice URL if available
  const pendingInvoiceUrl =
    pendingInvoices.length > 0 ? pendingInvoices[0].url : null;

  // Format date function
  const formatDate = (timestamp) => {
    if (!timestamp) return null;
    return new Date(timestamp.seconds * 1000).toLocaleDateString();
  };

  return (
    <Paper sx={{ p: 0, mb: 3, mt: 3, overflow: "hidden" }}>
      <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Active Subscriptions & Promotions
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {totals.activeCount} active ads ($
              {(totals.activeAmount / 100).toLocaleString()})
              {totals.pendingCount > 0 && (
                <Box component="span" sx={{ color: "warning.main", ml: 1 }}>
                  + {totals.pendingCount} pending ($
                  {(totals.pendingAmount / 100).toLocaleString()})
                </Box>
              )}
              {activePromotions.filter((p) => p.status === "active").length >
                0 && (
                <Box component="span" sx={{ color: "info.main", ml: 1 }}>
                  •{" "}
                  {activePromotions.filter((p) => p.status === "active").length}{" "}
                  active promotions
                </Box>
              )}
              {activePromotions.filter((p) => p.status === "invoiced").length >
                0 && (
                <Box component="span" sx={{ color: "warning.main", ml: 1 }}>
                  •{" "}
                  {
                    activePromotions.filter((p) => p.status === "invoiced")
                      .length
                  }{" "}
                  pending promotions ($
                  {(
                    activePromotions.filter((p) => p.status === "invoiced")
                      .length * 100
                  ).toLocaleString()}
                  )
                </Box>
              )}
            </Typography>
          </Box>
          {hasPendingItems && pendingInvoiceUrl && (
            <Button
              variant="contained"
              color="warning"
              href={pendingInvoiceUrl}
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<PaymentRounded />}
            >
              Pay Invoice
            </Button>
          )}
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "background.paper" }}>
              <TableCell>Location</TableCell>
              <TableCell>Ad Status</TableCell>
              <TableCell>Promotion Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {combinedSubscriptions.map((item) => (
              <TableRow
                key={item.zipCode}
                sx={{
                  bgcolor:
                    item.ad?.status === "active" &&
                    item.promotion?.status === "active"
                      ? "success.lighter"
                      : item.ad?.status === "active" ||
                        item.promotion?.status === "active"
                      ? "info.lighter"
                      : "inherit",
                }}
              >
                <TableCell>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {item.zipCode}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.cityState}
                    </Typography>
                  </Box>
                </TableCell>

                {/* Ad Status Cell */}
                <TableCell>
                  {item.ad ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Chip
                          label={
                            item.ad.status === "active"
                              ? "Active"
                              : "Pending Payment"
                          }
                          color={
                            item.ad.status === "active" ? "success" : "warning"
                          }
                          size="small"
                          sx={{ minWidth: 100 }}
                        />
                        {item.ad.expirationDate && (
                          <Typography variant="body2" color="text.secondary">
                            Expires: {formatDate(item.ad.expirationDate)}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      —
                    </Typography>
                  )}
                </TableCell>

                {/* Promotion Status Cell */}
                <TableCell>
                  {item.promotion ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Chip
                          label={
                            item.promotion.status === "active"
                              ? "Active"
                              : "Pending Payment"
                          }
                          color={
                            item.promotion.status === "active"
                              ? "success"
                              : "warning"
                          }
                          size="small"
                          sx={{ minWidth: 100 }}
                        />
                        {item.promotion.expirationDate && (
                          <Typography variant="body2" color="text.secondary">
                            Expires: {formatDate(item.promotion.expirationDate)}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      —
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default SubscriptionsTable;
