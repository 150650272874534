import React, { useContext, useState, forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Button,
  Link,
  TextField,
  Alert,
  Divider,
  Slide,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import firebase from "firebase/compat/app";
import { AuthContext } from "context/Auth";
import { db } from "services/firebase";

import StarRating from "components/map/Modal/StarRating";
import FacilityFeatures from "components/map/Modal/FacilityFeatures";
import SeatRating from "components/SeatRating";
import Loading from "components/Loading";
import SeatData from "components/map/SeatData";
import QueueForm from "components/queue/QueueForm";

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({
  modalOpen,
  modalVisible,
  setModalVisible,
  userLocation,
  error,
}) => {
  const { user } = useContext(AuthContext);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [dbError, setDbError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const hasTime = modalOpen?.averageWaitTime || modalOpen?.waitScore;
  const locationName = modalOpen?.title;
  const locationRef = modalOpen?.id?.toString();

  const capitalize = (string) => {
    if (!string) return "";
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = String(date.getFullYear()).substring(2);
    const hours = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return `${month}/${day}/${year} ${hours}`;
  };

  const withinRadius = (point, interest, kms) => {
    if (!userLocation) return false;

    const R = 6371;
    const deg2rad = (n) => n * (Math.PI / 180);

    const dLat = deg2rad(interest.latitude - point.latitude);
    const dLon = deg2rad(interest.longitude - point.longitude);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(point.latitude)) *
        Math.cos(deg2rad(interest.latitude)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.asin(Math.sqrt(a));
    const d = R * c;

    return d <= kms;
  };

  const sendMessage = async (number) => {
    setMessageSent("loading");
    try {
      await db.collection("messages").add({
        to: number,
        body: `A user requested to know the current state of the waiting room at your facility. You can update your facility's estimate by signing in at hlthdsk.com.`,
      });
      setMessageSent("sent");
    } catch (error) {
      console.error("Error sending message:", error);
      setMessageSent("error");
    }
  };

  const onSubmit = async ({ time }) => {
    setLoading(true);
    try {
      await db
        .collection("locations")
        .doc(locationRef)
        .update({
          waitTimes: firebase.firestore.FieldValue.arrayUnion({
            date: Date.now(),
            waitTime: time,
          }),
        });
      setSubmitted(true);
    } catch (error) {
      console.error(error);
      setDbError(true);
    } finally {
      setLoading(false);
    }
  };

  const renderTelehealthSection = () => {
    if (!modalOpen?.telehealth) return null;

    return (
      <Box sx={{ textAlign: "center", my: 2 }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          component="a"
          href={`https://app.interlockhealth.dachs.com/?directshare=${modalOpen.telehealth}`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ mb: 1 }}
        >
          Get Pre(ER) Now
        </Button>
        <Typography variant="body2" color="primary" sx={{ mb: 1 }}>
          Connect to Pre(ER) Now for simple healthcare needs and/or same-day
          healthcare advice and prescriptions except controlled substances
        </Typography>
        <Button
          variant="outlined"
          size="small"
          component="a"
          href="https://interlockhealth.com/hdlm"
          target="_blank"
        >
          Learn More
        </Button>
      </Box>
    );
  };

  const renderWaitingRoomForm = () => {
    if (
      !modalOpen?.location ||
      error ||
      !withinRadius(userLocation, modalOpen.location, 0.5)
    ) {
      return null;
    }

    if (dbError) {
      return (
        <Alert severity="error">
          Error submitting the form. Please try again later.
        </Alert>
      );
    }

    if (submitted) {
      return (
        <Alert severity="success">
          Thank you. Your estimate has been submitted.
        </Alert>
      );
    }

    return (
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 2 }}>
        <Divider sx={{ my: 2 }} />
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          If you are in the waiting room, how full is the waiting room now?
        </Typography>
        <SeatRating register={register} />
        {errors.time && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {errors.time.message}
          </Alert>
        )}
        <Button
          fullWidth
          variant="contained"
          disabled={loading}
          type="submit"
          sx={{ mt: 2 }}
        >
          {loading ? <Loading /> : "Submit"}
        </Button>
      </Box>
    );
  };

  console.log({ modalOpen });

  return (
    <Dialog
      open={modalVisible}
      onClose={() => setModalVisible(false)}
      maxWidth="md"
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          position: "relative",
          p: 2,
          m: 2,
          minHeight: "200px",
          width: "100%",
          maxWidth: {
            xs: "420px", // Default/mobile width
            sm: "600px", // Tablet and up
            md: "800px", // Desktop and up
          },
          borderRadius: "12px",
        },
      }}
      sx={{
        "& .MuiDialog-paper": {
          margin: 0,
          position: "fixed",
          bottom: 0,
          mx: "auto",
          left: "50%",
          transform: "translateX(-50%)",
        },
      }}
    >
      <IconButton
        onClick={() => setModalVisible(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          zIndex: 1,
        }}
      >
        <CloseRounded />
      </IconButton>

      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ textAlign: "center", mt: 2, mb: 2 }}>
          {modalOpen?.title && (
            <Typography
              variant="h5"
              color="primary"
              sx={{
                fontWeight: 500,
                mb: 1,
                fontSize: {
                  xs: "1.15rem",
                  sm: "1.3rem",
                  md: "1.5rem",
                },
              }}
            >
              {capitalize(modalOpen.title)}
            </Typography>
          )}

          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{
              mb: 2,
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
              },
            }}
          >
            {modalOpen?.type === "Clinic"
              ? "Clinic / Immediate Care"
              : "Emergency Department"}
          </Typography>

          {modalOpen?.rating ? <StarRating rating={modalOpen.rating} /> : null}

          <Typography
            variant="subtitle1"
            color="primary"
            sx={{
              fontWeight: 500,
              mt: 2,
              mb: 1,
              fontSize: {
                xs: "0.9rem",
                sm: "1rem",
              },
            }}
          >
            {modalOpen?.waitScore && !modalOpen?.averageWaitTime
              ? "Anticipated Waiting Room Experience"
              : "Waiting Time Estimate"}
          </Typography>

          {modalOpen?.waitScore && !modalOpen?.averageWaitTime ? (
            <SeatData waitTime={modalOpen.waitScore} />
          ) : null}

          {modalOpen?.averageWaitTime ? (
            <SeatData waitTime={modalOpen.averageWaitTime} />
          ) : null}

          {!hasTime ? (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                mb: 2,
                fontSize: {
                  xs: "0.75rem",
                  sm: "0.875rem",
                },
              }}
            >
              No current estimated waiting room time.
            </Typography>
          ) : null}

          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              display: "block",
              mb: 1,
              fontSize: {
                xs: "0.7rem",
                sm: "0.75rem",
              },
            }}
          >
            {modalOpen?.lastUpdated
              ? `Last Updated: ${formatTime(Date(modalOpen.lastUpdated))}`
              : hasTime && (
                  <>
                    <span>Last Updated: 03/08/2024*</span>
                    {!user && (
                      <Link
                        href="/register"
                        sx={{
                          display: "block",
                          mt: 1,
                          color: "primary.main",
                          fontSize: {
                            xs: "0.75rem",
                            sm: "0.875rem",
                          },
                        }}
                      >
                        Claim this Facility
                      </Link>
                    )}
                  </>
                )}
          </Typography>
        </Box>

        {renderTelehealthSection()}

        <FacilityFeatures
          features={{
            lab: modalOpen?.lab,
            xray: modalOpen?.xray,
            ultrasound: modalOpen?.ultrasound,
            ct: modalOpen?.ct,
            mri: modalOpen?.mri,
          }}
        />

        <QueueForm
          queue={modalOpen?.queue}
          queueEnabled={modalOpen?.queueEnabled}
          queueNumber={modalOpen?.queueNumber}
          queueCap={modalOpen?.queueCap}
          queueLength={modalOpen?.queueLength}
          locationName={locationName}
          locationRef={locationRef}
          firebase={firebase}
          db={db}
          textSequence={modalOpen?.textSequence}
        />

        {renderWaitingRoomForm()}

        {modalOpen?.phone && !modalOpen?.queueEnabled && (
          <Box sx={{ mt: 2, textAlign: "center" }}>
            {messageSent === "sent" ? (
              <Alert severity="success">
                Thank you. A message has been sent.
              </Alert>
            ) : (
              <Button
                variant="outlined"
                onClick={() => {
                  setEmailSent(true);
                  sendMessage(modalOpen.phone);
                }}
                disabled={messageSent === "loading"}
              >
                {messageSent === "loading" ? (
                  <Loading />
                ) : (
                  "Request Current WR Update"
                )}
              </Button>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
