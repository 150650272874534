import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import auctionService from "./services/auctionService";
import promotionService from "./services/promotionService";
import BidPopup from "./BidPopup";
import PromotionRequirementPopup from "./PromotionRequirementPopup";
import {
  handleServiceError,
  showErrorNotification,
} from "./utils/errorHandling";
import { useAuth } from "hooks/useAuth";

const AuctionStatus = ({ zipCode, onBidPlaced, hideTimer }) => {
  const theme = useTheme();
  const { userData, zipPromotions } = useAuth();
  const currentUserId = userData?.uid;
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [currentBid, setCurrentBid] = useState(null);
  const [showPromotionRequirement, setShowPromotionRequirement] =
    useState(false);
  const [isWinning, setIsWinning] = useState(false);
  const [hasBid, setHasBid] = useState(false);
  const [showBidPopup, setShowBidPopup] = useState(false);
  const [minBid, setMinBid] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const buttonRef = useRef(null);

  useEffect(() => {
    let isMounted = true;
    let interval;

    const updateStatus = async () => {
      if (!isMounted || showBidPopup) return;

      try {
        const auction = await auctionService.initializeAuction(zipCode);

        // If no auction exists, set initial state and stop polling
        if (!auction) {
          if (isMounted) {
            setTimeRemaining(await auctionService.getTimeRemaining(zipCode));
            setCurrentBid(0);
            setMinBid(auctionService.STARTING_PRICE);
            setHasBid(false);
            setIsWinning(false);
            setIsLoading(false);
          }
          return;
        }

        if (!isMounted) return;

        const remaining = await auctionService.getTimeRemaining(zipCode);
        if (!isMounted) return;

        setTimeRemaining(remaining);
        setCurrentBid(auction?.currentBid || auction?.startingPrice || 0);

        const minBidAmount = await auctionService.getMinimumBid(zipCode);
        if (!isMounted) return;

        setMinBid(minBidAmount || 0);

        const hasPlacedBid =
          auction?.bidHistory?.some((bid) => bid.bidderId === currentUserId) ||
          false;

        setHasBid(hasPlacedBid);
        setIsWinning(auction?.lastBidder === currentUserId);
      } catch (error) {
        if (isMounted) {
          showErrorNotification(
            `Error updating auction status: ${error.message}`
          );
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    updateStatus();
    interval = setInterval(updateStatus, 1000);

    return () => {
      isMounted = false;
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [zipCode, currentUserId, showBidPopup]);

  const handleBidClick = () => {
    // Get promotion data directly from context
    const promotionData = zipPromotions[zipCode];
    const isUserPromoter =
      promotionData?.status === "active" ||
      promotionData?.status === "pending" ||
      promotionData?.status === "invoiced";

    if (!isUserPromoter) {
      setShowPromotionRequirement(true);
      return;
    }

    setShowBidPopup(true);
  };

  const handleBidSubmit = async (amount) => {
    try {
      // Check promotion status from context again to ensure it's still valid
      const promotionData = zipPromotions[zipCode];
      const isUserPromoter =
        promotionData?.status === "active" ||
        promotionData?.status === "pending" ||
        promotionData?.status === "invoiced";

      if (!isUserPromoter) {
        // If not a promoter, show the requirement popup instead of trying to set it automatically
        setShowPromotionRequirement(true);
        return;
      }

      // Now place the bid
      await auctionService.placeBid(zipCode, amount, currentUserId);
      setShowBidPopup(false);

      if (onBidPlaced) {
        await onBidPlaced();
      }
    } catch (error) {
      showErrorNotification(`Error placing bid: ${error.message}`);
    }
  };

  const hasEnded = timeRemaining !== null && timeRemaining <= 0;

  const getButtonStyle = () => {
    if (!hasBid) {
      return {
        color: "primary",
        text: "Place Bid",
      };
    }
    if (isWinning) {
      return {
        color: "success",
        text: "Currently Winning",
      };
    }
    return {
      color: "error",
      text: "Not Winning / Place Bid",
    };
  };

  const formatTime = (ms) => {
    if (ms <= 0) return "Ended";
    const seconds = Math.ceil(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const buttonStyle = getButtonStyle();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {isLoading || timeRemaining === null || minBid === null ? (
        <LoadingButton
          loading
          variant="contained"
          sx={{
            minWidth: "140px",
          }}
        >
          Place Bid
        </LoadingButton>
      ) : (
        <Button
          ref={buttonRef}
          onClick={handleBidClick}
          variant="contained"
          color={buttonStyle.color}
          disabled={hasEnded}
          sx={{
            minWidth: "140px",
            opacity: hasEnded ? 0.7 : 1,
            transition: "background-color 0.3s ease",
          }}
        >
          {buttonStyle.text}
        </Button>
      )}

      {showBidPopup && (
        <BidPopup
          minBid={minBid}
          onSubmit={handleBidSubmit}
          onCancel={() => setShowBidPopup(false)}
          buttonRef={buttonRef}
        />
      )}

      {showPromotionRequirement && (
        <PromotionRequirementPopup
          onClose={() => setShowPromotionRequirement(false)}
          buttonRef={buttonRef}
          onEnablePromotion={(enabled) => {
            if (enabled) {
              // Only proceed to bid popup if promotion was successfully enabled
              setShowBidPopup(true);
            }
            setShowPromotionRequirement(false);
          }}
          zipCode={zipCode}
        />
      )}
    </Box>
  );
};

export default AuctionStatus;
