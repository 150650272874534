import React, { createContext, useState } from "react";

export const KijabeChatContext = createContext();

export const KijabeChatProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [currentTab, setCurrentTab] = useState("home");
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [messageCount, setMessageCount] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [descOpen, setDescOpen] = useState(null);
  const [threadReset, setThreadReset] = useState(null);
  const [language, setLanguage] = useState("english");

  const RATE_LIMIT = 18;

  const newThread = () => {
    setMessages([]);
    setTabs([]);
    setCurrentTab("home");
    setKeyword("");
    setDescOpen(null);
    setMessageCount(0);
    setThreadReset(Date.now());
  };

  const getRemainingMessages = () => {
    return Math.max(0, RATE_LIMIT - messageCount);
  };

  return (
    <KijabeChatContext.Provider
      value={{
        messages,
        setMessages,
        tabs,
        setTabs,
        expanded,
        setExpanded,
        currentTab,
        setCurrentTab,
        newThread,
        isMessagesLoading,
        setIsMessagesLoading,
        messageCount,
        setMessageCount,
        getRemainingMessages,
        limit: RATE_LIMIT,
        rateLimited: messageCount >= RATE_LIMIT,
        keyword,
        setKeyword,
        descOpen,
        setDescOpen,
        threadReset,
        language,
        setLanguage,
      }}
    >
      {children}
    </KijabeChatContext.Provider>
  );
};
