import { useContext, useState, useEffect } from "react";
import { LocationContext } from "context/Location";

/**
 * useFacility is a custom hook that manages facility location data and wait times.
 * It handles the processing and transformation of raw location data from the
 * LocationContext into a format that's ready for use in the dashboard.
 *
 * Key responsibilities:
 * - Processes wait times from the location data
 * - Extracts and normalizes facility service information (lab, xray, etc.)
 * - Manages loading states for location data
 * - Provides methods to update location data
 *
 * This hook is primarily used in facility dashboards to display and manage
 * wait times and service availability.
 *
 * @returns {Object} An object containing:
 * - Processed location data
 * - Methods to update location data
 * - Loading state for location data
 *
 * @example
 * const { data, setData } = useFacility();
 * console.log(data.lab); // Access lab wait times
 */
export const useFacility = () => {
  const { location, locationLoaded } = useContext(LocationContext);
  const [data, setData] = useState({});

  useEffect(() => {
    if (locationLoaded && location) {
      const initialData = location;

      if (initialData.waitTimes) {
        const adminTime = initialData.waitTimes
          .reverse()
          .find((time) => time.dashboard);

        const newData = {
          ...initialData,
          lab: adminTime?.lab || null,
          xray: adminTime?.xray || null,
          ultrasound: adminTime?.ultrasound || null,
          ct: adminTime?.ct || null,
          mri: adminTime?.mri || null,
          telehealth: adminTime?.telehealth || null,
          customPhone: adminTime?.customPhone || null,
          dashboard: adminTime?.dashboard || null,
        };
        setData(newData);
      } else {
        setData(initialData);
      }
    }
  }, [locationLoaded, location]);

  return { data, setData };
};
