import React from "react";

const ExpansionPromptPopup = ({ onClose }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "24px",
        borderRadius: "8px",
        boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
        zIndex: 1000,
        width: "500px",
        maxWidth: "90vw",
      }}
    >
      <div
        style={{
          fontSize: "1.1rem",
          lineHeight: "1.6",
          marginBottom: "20px",
          color: "#374151",
        }}
      >
        All auctions in these zip codes have concluded. Auctions start to close at 2 PM on the second Wednesday of each month.  See you next month!
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          onClick={onClose}
          style={{
            backgroundColor: "#1b4584",
            color: "white",
            border: "none",
            borderRadius: "4px",
            padding: "8px 16px",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#153461")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#1b4584")}
        >
          Got it
        </button>
      </div>
    </div>
  );
};

export default ExpansionPromptPopup;